<div class="row" style="margin-top: 18px;">
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-fostplus" fpBlockUiContainer>
                    <div class="panel-heading">
                        <div class="panel-title">
                            <span>Links</span>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="row" *ngIf="hasPartner">
                            <div class="col-md-12">
                                <a href="{{linkToPartner}}" fp-translate class="anchor-label">
                                    Core.Label_PartnerPortal
                                </a>
                            </div>
                        </div>
                        <div class="row" *ngIf="hasMember">
                            <div class="col-md-12">
                                <a href="{{linkToMember}}" fp-translate class="anchor-label">
                                    Core.Label_MemberPortal
                                </a>
                            </div>
                        </div>
                        <div class="row" *ngIf="hasLabo">
                            <div class="col-md-12">
                                <a href="{{linkToLabo}}" fp-translate class="anchor-label">
                                    Core.Label_LaboPortal
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
