import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, Optional, Self } from "@angular/core";
import { ControlValueAccessor, NgModel } from "@angular/forms";

/*
 * !!! Opgelet bij ge bruik van de FpMultiSelect component.
 * * Angular gebruikt "===" voor zijn changeTracking, dus wijzigingen aan een array of een property van een object worden niet opgemerkt.
 *
 * Als we de geselecteerde waarden in de code willen aanpassen, moeten we dus steeds een nieuwe array maken.
 * Anders zal de ngForm niet de laatste waarden hebben van de FpMultiSelect om de validatie op te doen.
 */
@Component({
    //moduleId: module.id,
    selector: "fp-multiselect",
    templateUrl: "./fpMultiSelect.component.html",
    host: { 'class': 'fp-control' }
})
export class FpMultiSelectComponent implements ControlValueAccessor, OnInit {
    public model: NgModel;

    @Input() data: Array<any> = [];
    @Input() name: string;
    @Input() textField: string = 'displayName';
    @Input() valueField: string = 'id';

    @Input() required: boolean | string = false;
    @Input() disabled: boolean = false;
    @Input() readMode: boolean = false;

    @Output() valueChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() close: EventEmitter<Event> = new EventEmitter<Event>();

    private innerValue: number[];
    private mustUpdateReadModeText: boolean = true;
    private readModeText: string = "";

    constructor(
        @Optional() @Self() ngModel: NgModel | null,
        private changeDetectorRef: ChangeDetectorRef    ) {
        this.model = ngModel;

        // Set the valueAccessor manually.
        // Before this was done by providing the component as NG_VALUE_ACCESSOR,
        //    but this causes a circular import when injecting NgModel in the constructor.
        if (this.model) {
            this.model.valueAccessor = this;
        }
    }

    onChange(_): void { }

    onTouched(): void { }

    ngOnInit(): void {
        //this.controlId = this.name.replace('.', '_');
    }

    //get accessor
    get value(): number[] {
        if (!this.innerValue) {
            return null;
        }

        return this.innerValue;
    };

    //set accessor including call the onchange callback
    set value(v: number[]) {
        this.innerValue = v;
        this.mustUpdateReadModeText = true;

        if (this.onChange) {
            this.onChange(v);
        }

        if (this.valueChange) {
            this.valueChange.emit(v);
        }

    }

    writeValue(value: number[]): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
            this.mustUpdateReadModeText = true;
            this.changeDetectorRef.detectChanges();
        }
    }

    onBlur() {
        if (this.onTouched) {
            this.onTouched();
        }
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    getSelectedItemsText(): string {
        if (this.mustUpdateReadModeText) {
            this.mustUpdateReadModeText = false;
            this.readModeText = "";
            if (this.innerValue && this.innerValue.length > 0 && this.data && this.data.length > 0) {
                var items = this.data.filter((item) => {
                    return this.innerValue.indexOf(item[this.valueField]) >= 0;
                });

                var itemTexts = items.map(item => {
                    var t = <string>item[this.textField];
                    return t;
                });

                itemTexts = itemTexts.sort();
                this.readModeText = itemTexts.join(",\xa0");
            }
        }

        return this.readModeText;
    }

    updateValue(v: number[]) {
        this.value = v;
    }

    closeEvent(event: Event) {
        this.close.emit(event);
    }
}