import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { ConfigurationService } from "../services/configuration.service";

import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

const jsonDateRegex = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;

@Injectable()
export class ResponseToPoJoInterceptor implements HttpInterceptor {

    constructor(private config: ConfigurationService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.config.mustUseInterceptor(req.url)) {
            return next.handle(req).pipe(map(event => {
                if (event instanceof HttpResponse) {
                    // We only parse to json when body is string and not empty
                    // => because now it is possible body is object like Blob (see injectHeaders.interceptor)
                    if (typeof event.body === 'string' && event.body.length > 0) {
                        var body = JSON.parse(event.body, this.reviver);

                        event = event.clone({ body: body });
                    }
                }
                return event;
            }));
        }

        return next.handle(req);
    }

    private reviver(name, value) {
        if (typeof (value) === "string" && jsonDateRegex.test(value)) {
            try {
                var date = new Date(value);
                return date;
            } catch (e) {
            }
        }

        return value;
    }
}