import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';
import { DateUtils } from "../../../../utilities/dateUtils";

@Component({
    selector: "fp-validation-error",
    templateUrl: "./fpValidationError.component.html",
    host: { 'class': 'fp-control' }
})
export class FpValidationErrorComponent implements OnInit, OnDestroy {
    constructor(private translateService: TranslateService) {
    }

    private onLangChangeSub: Subscription;

    private initialized: boolean = false

    private forKey: string;
    private fieldName: string;
    private validationErrors: any;
    public messages: Array<string>;

    @Input() min: Date = new Date(1990, 0, 1);
    @Input() max: Date = new Date(2099, 11, 31);

    @Input()
    set for(v: string) {
        this.forKey = v;

        if (this.initialized) {
            this.translateName();
        }
    }

    @Input()
    set errors(e: any) {
        this.validationErrors = e;

        if (this.initialized) {
            this.translateErrors();
        }
    }

    ngOnInit(): void {
        this.onLangChangeSub = this.translateService.onLangChange.subscribe(() => {
            this.translateName();
        });
        
        this.translateName().then(() => {
            this.initialized = true;
        });
    }

    private translateName(): Promise<void> {
        var promise = new Promise<void>((resolve, reject) => {
            this.translateService.get(this.forKey).subscribe((result: string) => {
                this.fieldName = result;

                this.translateErrors();

                resolve();
            });
        })

        return promise;
    }

    private translateErrors() {

        this.messages = [];
        let validationErrors = this.validationErrors;
        if (validationErrors) {
            for (var errorName in validationErrors) {
                if (validationErrors.hasOwnProperty(errorName)) {

                    var params = this.getTranslationParams(errorName);

                    this.translateService.get("Validation." + errorName, params).subscribe((message: string) => {
                        this.messages.push(message);
                    });
                }
            }
        }
    }

    private getTranslationParams(error: string): any {
        var x = this.validationErrors[error];
        var p: any;

        if (typeof (x) === "object") {
            p = x
        } else {
            p = {}
        }

        p["fieldName"] = this.fieldName;
        p["min"] = DateUtils.DateToString(this.min);
        p["max"] = DateUtils.DateToString(this.max);

        return p;
    }

    ngOnDestroy(): void {
        this.onLangChangeSub.unsubscribe();
    }
}