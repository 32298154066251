import { Component, OnInit } from "@angular/core";
import { BaseRouteComponent } from "../base/baseRoute.component";
import { baseRouteDeps, BaseRouteDepenciesFactory, BaseRouteDependencies } from "../base/baseRouteDependencies.provider";
import { Subscription } from "rxjs";

@Component({
    templateUrl: "./logon.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class LogonComponent extends BaseRouteComponent<any> implements OnInit {
    public isInError: boolean = false;

    private $subscr: Subscription;

    private callBackUrl?: string;

    constructor(baseRouteDeps: BaseRouteDependencies) {
        super("LogonComponent", baseRouteDeps);
                
        if (this.baseRouteDeps.authenticationService.isAuthenticated) {
            this.baseRouteDeps.router.navigate(['init']);
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected configure() {
        this.baseRouteDeps.mainContextService.title = "";

        this.dataLoaded = () => {
        };
    }

    protected isDirty(): boolean {
        return false;
    }
}