export class GridSorting {
    public static createOrderBy(sort: Array<Object>): Array<FostPlus.Olympus.Core.Api.Client.IOrderByClause> {
        var orderBy: Array<FostPlus.Olympus.Core.Api.Client.IOrderByClause> = [];


        if (sort) {
            sort.forEach((s) => {
                var dir = s["dir"];
                var field = s["field"];

                if (field && dir) {
                    var o: FostPlus.Olympus.Core.Api.Client.IOrderByClause = {
                        fieldName: field,
                        direction: dir === "desc" ? FostPlus.Olympus.Core.Contract.OrderByDirection.Desc : FostPlus.Olympus.Core.Contract.OrderByDirection.Asc
                    };

                    orderBy.push(o);
                }
            })
        }

        return orderBy;
    } 
}