import { Injectable } from "@angular/core";

interface IFilterValuePair {
    [key: string]: any;
}

interface IGridFiltersPair {
    [key: string]: Array<IFilterValuePair>;
}

@Injectable()
export class FilterCacheService {
    private _gridFilters: Array<IGridFiltersPair> = [];

    constructor() {
    }

    public set(gridName: string, name: string, value: any): void {
        // Get Filters of the specified grid
        var gridFilters = this._gridFilters[gridName];
        if (gridFilters) {
            gridFilters[name] = value;
        } else {
            // Create filterValues array if not present
            var filterValues: Array<IFilterValuePair> = [];
            filterValues[name] = value;
            this._gridFilters[gridName] = filterValues;
        }
    }

    public get(gridName: string, name: string): any {
        var value = null;

        // Get Filters of the specified grid
        var gridFilters = this._gridFilters[gridName];
        if (gridFilters) {
            // Get value of the specified filter
            value = gridFilters[name];
        }
        return value;
    }

    public clear(gridName: string): void {
        var gridFilters = this._gridFilters[gridName];
        gridFilters = [];
    }

    public clearAll(): void {
        this._gridFilters = [];
    }

}
