import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from "../services/configuration.service";
import { LanguageService } from '../services/language.service'
import { MainContextService } from "../services/mainContext.service";
import { MainContext } from "../../../ScriptsModels/MainContext";
import { Router } from "@angular/router";
import { SecurityService } from '../services/security.service';
import { AuthenticationService } from "../services/authentication.service";
import { Observable } from 'rxjs';

@Component({
    //moduleId: module.id,
    selector: "fp-context",
    templateUrl: "./context.component.html"
})

export class ContextComponent implements OnInit {

    constructor(public config: ConfigurationService,
        private language: LanguageService,
        private authenticationService: AuthenticationService,
        public mainContextService: MainContextService,
        public securityService: SecurityService,
        public router: Router) {
        this.mainContext = mainContextService.currentContext;
    }

    public mainContext: MainContext;

    ngOnInit(): void {
    }

    public isActive(lang: string): string {
        if (lang === this.language.getLanguage()) {
            return 'active';
        }

        return '';
    }

    public get isOrganisationGroupDataLoaded(): boolean {
        return this.mainContextService.isOrganisationGroupDataLoaded;
    }

    public get isContractSelectorAvailable(): boolean {
        return this.authenticationService.isInternalUser || this.authenticationService.hasAccessToMultipleOrganisationGroups;
    }

    public getUserName(): string {
        let user = this.authenticationService.userIdentity;

        if (user) {
            return user.name
        }

        return '';
    }

    public get isAuthenticatedObservable(): Observable<boolean> {
        return this.authenticationService.isAuthenticatedObservable;
    }

    public get canTranslate(): boolean {
        return this.authenticationService.canTranslate;
    }    

    public changeLanguage(lang: string): void {
        this.language.updateLanguage(lang);
    }

    public translationKeysActive(): string {
        return this.language.getShowTranslationKeys() ? 'active' : '';
    }

    public toggleTranslationKeys(): void {
        this.language.toggleTranslationKeys();
    }

    public reloadUserAccess() {
        this.securityService.requestReload();
    }

    public navigateUserprofile() {
        this.router.navigate(['/userprofile'])
    }

    public logout() {
        this.router.navigate(['/logoff']);
    }

    public login() {
        this.authenticationService.checkAuth();
    }
}