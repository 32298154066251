import { CacheService } from "ng2-cache-service";
import { CacheStoragesEnum } from "ng2-cache-service/dist/src/enums/cache-storages.enum";

export declare const enum FpCacheStoragesEnum {
    LOCAL_STORAGE = 0,
    SESSION_STORAGE = 1,
    MEMORY = 2,
}

/**
 * Factory used to create FpCacheService instances
 */
// now we internally use the ng2-cache-service
export class FpCacheServiceFactory {
    private PREFIX: string = "fp-"
    private cacheService: CacheService;
    private cacheServices: any;

    constructor() {
        // the ng2-cache-service is also the factory for the different CacheService types...
        this.cacheService = new CacheService(null);
        // here we keep track of the different FpCacheServices by a key which consists of the FpCacheStoragesEnum and prefix.
        //  needed for the 'in memory' caches
        this.cacheServices = {};
    }

    public getOrCreate(type: FpCacheStoragesEnum, prefix: string): FpCacheService {
        var csKey: string;
        var cs: CacheService;
        var fpCs: FpCacheService;
        var csType: CacheStoragesEnum;
        var globalPrefix: string = this.PREFIX;

        switch (type) {
            case FpCacheStoragesEnum.LOCAL_STORAGE:
                csType = CacheStoragesEnum.LOCAL_STORAGE;
                break;
            case FpCacheStoragesEnum.SESSION_STORAGE:
                csType = CacheStoragesEnum.SESSION_STORAGE;
                break;
            case FpCacheStoragesEnum.MEMORY:
                csType = CacheStoragesEnum.MEMORY;
                break;
        }
        // when necessary, append the prefix to the globalPrefix
        if (prefix) {
            globalPrefix = globalPrefix + prefix + "-";
        }

        // get or create the FpCacheService
        csKey = csType.toString() + "|" + globalPrefix;
        fpCs = this.cacheServices[csKey];
        if (!fpCs) {
            cs = this.cacheService.useStorage(csType);
            cs.setGlobalPrefix(globalPrefix);

            fpCs = new FpCacheService(cs);
            this.cacheServices[csKey] = fpCs;
        }

        return fpCs;
    }
}

// this is a wrapper around the ng2-cacheService
//  it provides only the functionality we need (so it should be easier to replace the internals)
export class FpCacheService {
    private cs: CacheService;

    constructor(cs: CacheService) {
        this.cs = cs;
    }

    public set(key: string, value: any, maxAge?: number) {
        var co = null;
        if (maxAge) {
            co = {
                maxAge: maxAge
            };
        }

        if (co) {
            this.cs.set(key, value, co);
        } else {
            this.cs.set(key, value);
        }
    }

    public get(key: string): any {
        return this.cs.get(key);
    }

}