import { Injectable, Inject } from "@angular/core";
import { Utils } from "../utilities/utils";

//export interface ILuResponsibleTypeTrInfoExt extends AmontDeclarationDomain.Dtos.ILuResponsibleTypeTrInfoDto {
//    displayName: string;
//}

/**
 * Used to pass options needed for filtering reference-data lists
 */
export interface IDataSourceFilterOptions {
    propertyNamesToFilterOn: Array<string>;
}

/**
 * options for translating code/id/... to a display text
 */
interface ITranslateGenericOptions {
    dataIdPropertyName: string;
    referenceDataIdPropertyName: string;
    referenceDataDisplayPropertyName: string;
}

@Injectable()
export class LookupDataUtilitiesService {
    constructor() {

    }

    /**
         * generic function to translate code/id/... to a text to display
         * @data The data to translate
         * @referenceList The list holding the reference data
         * @options The options
         */
    private translateGeneric = (data, referenceList, options: ITranslateGenericOptions) => {
        var dataIdPropertyName = options.dataIdPropertyName;
        var listItemIdPropertyName = options.referenceDataIdPropertyName;
        var listItemDisplayPropertyName = options.referenceDataDisplayPropertyName;
        var displayText;

        data.forEach((value, key) => {
            var dataPropertyIdValue = value[dataIdPropertyName];
            if (dataPropertyIdValue !== undefined) {

                referenceList.forEach((listEntry) => {
                    if (listEntry[listItemIdPropertyName] === dataPropertyIdValue) {
                        displayText = listEntry[listItemDisplayPropertyName] || listEntry[listItemIdPropertyName];
                        value['$$' + dataIdPropertyName + 'Display'] = displayText;
                    }
                });
            }
        });
    };

    /**
     * 
     */
    translateCountry = (data: Array<any>,
        countries: Array<FostPlus.Olympus.CoreDomain.Clients.Api.ICountryTrInfoDto>,
        countryCodePropertyName?: string) => {
        var options = {
            dataIdPropertyName: countryCodePropertyName || 'countryId',
            referenceDataIdPropertyName: 'id',
            referenceDataDisplayPropertyName: 'name'
        };
        this.translateGeneric(data, countries, options);
    };

    translateGender = (data, luGenders, luGenderIdPropertyName) => {
        var options = {
            dataIdPropertyName: luGenderIdPropertyName || 'luGenderId',
            referenceDataIdPropertyName: 'id',
            referenceDataDisplayPropertyName: 'name'
        };
        this.translateGeneric(data, luGenders, options);
    };

    translateLuLanguage = (data, luLanguages, luLanguageIdPropertyName) => {
        var options = {
            dataIdPropertyName: luLanguageIdPropertyName || 'luLanguageId',
            referenceDataIdPropertyName: 'id',
            referenceDataDisplayPropertyName: 'name'
        };
        this.translateGeneric(data, luLanguages, options);
    };

    /**
         * based on an array of ids, return an array of matching objects
         * ( this was needed to work with the kendo MultiSelect )
         * @ids the array of ids
         * @objects the array of objects to search for matches
         * @objectIdProperty the name of the 'id' property
         */
    mapIdsToObjects = (ids: Array<number>, objects: Array<any>, objectIdProperty: string): Array<any> => {
        var currentSelectedIds = {};
        var results = [];
        // build dictionary of 'selected' ids
        ids.forEach(function (value, index) {
            currentSelectedIds[value] = value;
        });

        // loop the objects array to search for matches
        objects.forEach(function (value, index) {
            var objectId = value[objectIdProperty];
            if (currentSelectedIds[objectId] !== undefined) {
                results.push(value);
            }
        });

        return results;
    };

    /**
     * based on an array of 'selected' objects, return an array of matching objects
     * ( this was needed to work with the kendo MultiSelect )
     * @selectedObjects the list of currently 'selected' objects
     * @objects the list of all objects
     * @objectIdProperty the name of the 'id' property
     */
    remapObjects = (selectedObjects: Array<any>, objects: Array<any>, objectIdProperty: string) => {
        var currentSelectedIds = {};
        var results = [];

        selectedObjects.forEach(function (value, index) {
            var objectId = value[objectIdProperty];
            currentSelectedIds[objectId] = value;
        });

        objects.forEach(function (value, index) {
            var objectId = value[objectIdProperty];
            if (currentSelectedIds[objectId] !== undefined) {
                results.push(value);
            }
        });

        return results;
    };

    /**
     * based on an array of objects, return an array with the value of the key property
     */
    objectsToKeys = <TKey>(objects: Array<any>, objectKeyProperty: string): Array<TKey> => {
        var results: Array<TKey> = [];
        objects.forEach(function (value, index) {
            results.push(value[objectKeyProperty]);
        });

        return results;
    };

    /**
     * from a list of employees, filter out the account managers
     */
    filterAccountManagers = function (employees, includeUserId?: number) {
        var accountManagers = [];
        employees.forEach(function (employee, index) {
            if (employee.id === includeUserId || employee.isAccountManager) {
                accountManagers.push(employee);
            }
        });

        accountManagers.splice(0, 0, { id: null, shortName: ' ', displayName: ' ' });

        return accountManagers;
    };

    filterAmontUsers = function (employees, includeUserId) {
        var amontUsers = [];
        if (!includeUserId) {
            includeUserId = -1;
        }

        employees.forEach(function (employee, index) {
            // don't use ===, kendo uses the string representation for dataValueField
            if (employee.isAmontUser || (employee.id == includeUserId)) {
                amontUsers.push(employee);
            }
        });

        amontUsers.splice(0, 0, { id: null, displayName: ' ' });

        return amontUsers;
    };

    filterTaskTypes = function (taskTypes: Array<FostPlus.Olympus.UsersDomain.Clients.Api.ILuTaskTypeTrInfoDto>, includeTaskTypeId: number = null, filter: (taskType: FostPlus.Olympus.UsersDomain.Clients.Api.ILuTaskTypeTrInfoDto) => boolean = null): Array<FostPlus.Olympus.UsersDomain.Clients.Api.ILuTaskTypeTrInfoDto> {
        if (!includeTaskTypeId) {
            includeTaskTypeId = -1;
        }

        var activeTaskTypes = taskTypes.filter((x) => {
            return x.luTaskTypeId === includeTaskTypeId ||
                ((x.isActive) && (!filter || filter(x) || x.luTaskTypeId.toString() === ''));
        });

        return activeTaskTypes;
    };

    filterCommunicationLanguages = function (languages: Array<FostPlus.Olympus.CoreDomain.Clients.Api.ILuLanguageTrInfoDto>) {
        var validCommunicationLanguages = [
            FostPlus.Olympus.CoreDomain.Clients.Api.LuLanguageId.Nl,
            FostPlus.Olympus.CoreDomain.Clients.Api.LuLanguageId.Fr,
            FostPlus.Olympus.CoreDomain.Clients.Api.LuLanguageId.En,
        ];

        var communicationLanguages = [];
        languages.forEach((language) => {
            if (validCommunicationLanguages.indexOf(language.id) > -1) {
                communicationLanguages.push(language);
            }
        });

        return communicationLanguages;
    };

    filterIAMRegistrationReasons = function (reasons: Array<FostPlus.Olympus.UsersDomain.Clients.Api.ILuIAMRegistrationReasonTrInfoDto>) { 
        var iamRegistrationReasons = reasons.filter(r => true == r.showWhenUserLoggedIn)

        return iamRegistrationReasons;
    }

    filterActiveAndCurrentOnes = function (list: Array<any>, currentIds: Array<number>) {
        var activeOnes = new Array<any>();

        list.forEach(function (respType: any) {
            // don't use ===, kendo uses the string representation for dataValueField
            if (respType.isActive || currentIds.indexOf(respType.id) !== -1) {
                activeOnes.push(respType);
            }
        });

        return activeOnes;
    };

    filter = function (items: Array<any>
        , includeId?: number
        , filter: (item: any) => boolean = null) {
        var activeContactMomentTypes = [];
        if (!includeId) {
            includeId = -1;
        }

        activeContactMomentTypes = items.filter((item) => {
            var match = (item.id == includeId)
                || ((item.isActive) && (!filter || filter(item) || item.id.toString() === ''));
            return match;
        });

        return activeContactMomentTypes;
    };

    filterNonBlanks = function (list: Array<any>): Array<any> {
        var filtered = [];
        list.forEach((item) => {
            if (item.id !== '') {
                filtered.push(item);
            }
        });
        return filtered;
    }

    convertToValueList = (data: any, valueProperty: string, textProperty: string) => {
        var valueList: Array<any> = [];
        data.forEach(function (item, index) {
            valueList.push({
                value: item[valueProperty],
                text: item[textProperty]
            });
        });

        return valueList;
    };

    convertToDictionary = (data: any, valueProperty: string, textProperty?: string) => {
        var dictionary: Array<any> = [];
        data.forEach(function (item, index) {
            if (Utils.isNumeric(item)) {
                dictionary[item] = item;
            } else if (textProperty) {
                dictionary[item[valueProperty]] = item[textProperty];
            } else {
                dictionary[item[valueProperty]] = item;
            }
        });

        return dictionary;
    };

    convertToFilterValueDtos = (values: Array<any>, data: Array<any>, valueField: string, displayNameField: string): Array<FostPlus.Olympus.Core.Contract.IFilterValueDto> => {
        return data.filter(d => {
            return values.find(v => v === d[valueField])
        }).map(d => {
            return {
                id: d[valueField],
                displayName: d[displayNameField]
            };
        });        
    }

    addBlankItem = (data: Array<any>, idName?: string, displayPropertyName?: string) => {
        displayPropertyName = displayPropertyName || 'displayName';
        idName = idName || 'id';

        var emptyitem = JSON.parse('{ "' + idName + '": "", "' + displayPropertyName + '": " " }');
        emptyitem[idName] = null;
        data.splice(0, 0, emptyitem);

        return emptyitem;
    };

    addActiveBlankItem = (data: Array<any>, idName?: string, displayPropertyName?: string) => {
        displayPropertyName = displayPropertyName || 'displayName';
        idName = idName || 'id';

        var emptyitem = JSON.parse('{ "' + idName + '": "", "' + displayPropertyName + '": " ", "isActive": true }');
        emptyitem.id = '';
        data.splice(0, 0, emptyitem);

        return emptyitem;
    };

    sortDataArray = (data: Array<any>, field: string, dir: FostPlus.Olympus.Core.Contract.OrderByDirection = FostPlus.Olympus.Core.Contract.OrderByDirection.Asc): Array<any> => {
        return data.sort((a: any, b: any) => {
            let desc = dir === FostPlus.Olympus.Core.Contract.OrderByDirection.Desc;

            if (a[field] < b[field]) {
                return desc ? 1 : -1;
            } else if (a[field] > b[field]) {
                return desc ? -1 : 1;
            } else {
                return 0;
            }
        });
    }

    removeBlankItem = (data: Array<any>): Array<any> => {
        data.forEach((item, i) => {
            if (!item.id) {
                data.splice(i, 1);
            }
        });

        return data;
    }

    getUniqueObjects = (data: Array<any>, uniqueKey: string): Array<any> => {
        var result = [];

        for (var i = 0; i < data.length; i++) {
            if (result.filter(e => e[uniqueKey] == data[i][uniqueKey]).length == 0) {
                result.push(data[i]);
            }
        }

        return result;
    }
}
