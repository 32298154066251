import { Component, OnInit } from "@angular/core";
import { BaseRouteComponent } from "./base/baseRoute.component";
import { baseRouteDeps, BaseRouteDepenciesFactory, BaseRouteDependencies } from "./base/baseRouteDependencies.provider";

interface INotAuthorizedData {
}

@Component({
    templateUrl: "./notAuthorized.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class NotAuthorizedComponent extends BaseRouteComponent<INotAuthorizedData> implements OnInit {
    constructor(baseRouteDeps: BaseRouteDependencies) {
        super("NotAuthorizedComponent", baseRouteDeps);
    }

    public authenticationError: string = null;

    ngOnInit() {
        this.authenticationError = this.baseRouteDeps.authenticationService.authorizationError;
    }
}