<div class="row">
    <div class="col-md-12">
        <h2 class="text-center" fp-translate>MyApp.LoggedOut_Title</h2>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <p class="text-center">
            <span fp-translate>MyApp.LoggedOut_InfoText</span>
        </p>
    </div>
</div>