import { Injectable, Inject } from '@angular/core';
import { HttpProvider } from '../services/http.provider';

import { LogLevel, StsConfigHttpLoader, OpenIdConfiguration } from 'angular-auth-oidc-client';

import MyFost = FostPlus.MyFost.Web.Gui
import { map } from 'rxjs';

@Injectable()
export class OidcConfigLoader {

    constructor(private http: HttpProvider,
        @Inject('rootUrl') private rootUrl: string) {
    }


    public loadOidcConfig(): StsConfigHttpLoader {
        var configObs = this.http.getAsObservable<MyFost.IOidcConfig>(this.rootUrl + 'api/app/oidcConfig')
            .pipe(
                map((data: MyFost.IOidcConfig) => {
                    var config: OpenIdConfiguration = {
                        authority: data.stsServer,
                        redirectUrl: location.protocol + "//" + location.host + "/" + data.redirectUrl,
                        postLogoutRedirectUri: location.protocol + "//" + location.host + "/" + data.postLogoutRedirectUri,
                        clientId: data.clientId,
                        scope: data.scope,
                        responseType: data.responseType,
                        silentRenew: data.silentRenew,
                        silentRenewUrl: location.protocol + "//" + location.host + "/" + data.silentRenewUrl,
                        renewTimeBeforeTokenExpiresInSeconds: data.renewTimeBeforeTokenExpiresInSeconds,
                        logLevel: LogLevel[data.logLevel],
                        maxIdTokenIatOffsetAllowedInSeconds: data.maxIdTokenIatOffsetAllowedInSeconds,
                        disableIatOffsetValidation: true,
                        startCheckSession: data.startCheckSession,
                        postLoginRoute: data.postLoginRoute,
                        forbiddenRoute: data.forbiddenRoute,
                        unauthorizedRoute: data.unauthorizedRoute,
                        triggerAuthorizationResultEvent: data.triggerAuthorizationResultEvent
                    };

                    return config;
                }));

        return new StsConfigHttpLoader(configObs);
    }
}