import { Injectable, Inject } from '@angular/core';
import { HttpProvider } from "../services/http.provider";

import UsersContract = FostPlus.Olympus.UsersDomain.Clients.Api;

export interface IOrganisationGroupRepository {
    searchOrganisationGroups(request: UsersContract.IOrganisationGroupSearchRequest): Promise<UsersContract.IOrganisationGroupSearchResponse>;
    getOrganisationGroupsPartyInfo(id: number): Promise<FostPlus.MyFost.Web.Gui.Models.IOrganisationGroupResponse>;
    getOrganisationGroupsApplicationRolesInfo(organisationGroupId: number): Promise<UsersContract.IOrganisationGroupApplicationRolesInfoGetResponse>;
}

@Injectable()
export class OrganisationGroupRepository implements IOrganisationGroupRepository {
    static $inject = [
        '$resource',
        'rootUrl',
        '$window',
        '$q'
    ];

    constructor(private http: HttpProvider,
        @Inject('rootUrl') private rootUrl: string) {
    }

    searchOrganisationGroups = (request: UsersContract.IOrganisationGroupSearchRequest): Promise<UsersContract.IOrganisationGroupSearchResponse> => {
        var a = this.http.post<UsersContract.IOrganisationGroupSearchResponse>(this.rootUrl + "api/organisationGroups/search", request);
        return a;
    }

    getOrganisationGroupsPartyInfo = (id: number): Promise<FostPlus.MyFost.Web.Gui.Models.IOrganisationGroupResponse> => {
        var url = "api/organisationGroups/partyInfo";
        if (id !== null) {
            url = url + "/" + id;
        }

        return this.http.get<FostPlus.MyFost.Web.Gui.Models.IOrganisationGroupResponse>(this.rootUrl + url);
    }

    getOrganisationGroupsApplicationRolesInfo = (organisationGroupId: number): Promise<UsersContract.IOrganisationGroupApplicationRolesInfoGetResponse> => {
        var url = `api/organisationGroups/${organisationGroupId}/applicationRoles`;

        return this.http.get<UsersContract.IOrganisationGroupApplicationRolesInfoGetResponse>(this.rootUrl + url);
    }
}

