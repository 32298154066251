import { Injectable, Inject } from '@angular/core';
import { HttpProvider } from "../services/http.provider";

export interface IItsMeRepository {
    prepareItsMeChallenge(): Promise<void>;
}

@Injectable()
export class ItsMeRepository implements IItsMeRepository {

    static $inject = [
        '$resource',
        'rootUrl',
        '$window',
        '$q'
    ];

    constructor(private http: HttpProvider,
        @Inject('rootUrl') private rootUrl: string) {
    }

    public prepareItsMeChallenge(): Promise<void> {
        const url = "api/itsme/prepareChallenge";
        return this.http.get(this.rootUrl + url);
    }
}