import { Injectable } from "@angular/core";
import { CldrIntlService } from '@progress/kendo-angular-intl';
import { LanguageService, ICultureChangedEvent } from "./language.service";
import { Subscription } from 'rxjs';

@Injectable()
export class FpIntlService extends CldrIntlService {
    private onCultureChangeSub: Subscription;

    constructor(langService: LanguageService) {
        super(langService.getLocale());

        langService.onCultureChange.subscribe(this.cultureChanged);
    }

    private cultureChanged = (event: ICultureChangedEvent) => {
        this.localeId = event.locale;
    }
}