import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigurationService } from "./configuration.service";

@Injectable()
export class HttpProvider {

    public token: string;

    constructor(private httpClient: HttpClient,
        private config: ConfigurationService) { }

    get<T>(url: string): Promise<T> {
        return this.httpClient.get<T>(url).toPromise();
    }

    getFile(url: string): Promise<HttpResponse<ArrayBuffer>> {
        let headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json; charset=utf-8');

        const options: {
            headers?: HttpHeaders,
            observe: 'response',
            params?: HttpParams,
            reportProgress?: boolean,
            responseType: 'arraybuffer',
            withCredentials?: boolean
        } = {
            headers: headers,
            observe: 'response',
            responseType: 'arraybuffer'
        };

        return this.httpClient.get(url, options).toPromise();
    }

    getAsObservable<T>(url: string): Observable<T> {
        return this.httpClient.get<T>(url);
    }

    post<T>(url: string, body: any): Promise<T> {
        return this.httpClient.post<T>(url, body).toPromise();
    }

    put<T>(url: string, body: any): Promise<T> {
        return this.httpClient.put<T>(url, body).toPromise();
    }

    delete<T>(url: string): Promise<T> {
        return this.httpClient.delete<T>(url).toPromise();
    }

    patch<T>(url: string, body: any): Promise<T> {
        return this.httpClient.patch<T>(url, body).toPromise();
    }

    head<T>(url: string): Promise<T> {
        return this.httpClient.head<T>(url).toPromise();
    }

    options<T>(url: string): Promise<T> {
        return this.httpClient.options<T>(url).toPromise();
    }
}