import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { FeedbackMessage } from "../../../classes/errorHandling";

@Component({
    selector: "fp-error-display",
    templateUrl: "./errorDisplay.component.html",
})

export class ErrorDisplayComponent {
    @Input() errors: Array<FeedbackMessage>;
    @Input() warnings: Array<FeedbackMessage>;
}