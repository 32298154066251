import { OnInit, Component, ViewChild } from "@angular/core";
import { Params } from "@angular/router";
import { BaseRouteDependencies, BaseRouteDepenciesFactory, baseRouteDeps } from "../base/baseRouteDependencies.provider";
import { BaseRouteComponent } from "../base/baseRoute.component";
import { IOrganisationGroupData } from "../../services/mainContext.service";

import { GridDataResult, SelectionEvent } from "@progress/kendo-angular-grid";
import { State } from "@progress/kendo-data-query";

import { FpGridBindingDirective } from "../../modules/shared/directives/fpGridBinding.directive";
import { GridSorting } from "../../utilities/gridSorting";
import { FilterCacheService } from "../../services/filterCache.service";
import { UserRepository } from "../../repositories/user.repository";

import UserContract = FostPlus.Olympus.UsersDomain.Clients.Api;
import { ActivateMenuOptions } from "../../../../ScriptsModels/MenuItem";
import { mnuItemsNames } from "../../services/menu.service";

interface IUserSearchData {
    userFirstName: string;
    userLastName: string;
    userWindowsLogon: string;
    userIsActive: boolean;
    userEmail: string;
    userInActive: boolean;
    userIsArchived: boolean;
    userIsNotArchived: boolean;
    partyFilterValues: number[];
}

@Component({
    templateUrl: "./userSearch.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})

export class UserSearchComponent extends BaseRouteComponent<IUserSearchData> implements OnInit {
    @ViewChild("gridBinding", { static: true }) private gridBinding: FpGridBindingDirective;
    public filtersCollapsed: boolean = true;
    private filtersCollapsedName: string = "filtersCollapsed";

    constructor(
        baseRouteDeps: BaseRouteDependencies,
        private userRepository: UserRepository,
        private filterCacheService: FilterCacheService) {
        super("UserSearchComponent", baseRouteDeps);

        this.activateMenuOptions = new ActivateMenuOptions(mnuItemsNames.users);
    }

    private _gridName: string = "userSearch";
    private _searchRequestName: string = "userSearchRequest";

    public hide: boolean = false;

    public orgGroupParties: UserContract.IOrganisationGroupPartyInfoDtoPartyDto[] = [];

    protected configure() {
        this.dataLoaded = () => {
            this.hide = false;

            this.setOrganisationGroupParties();
            this.getCachedRequest();

            this.gridBinding.rebind();
        };

        this.dataLoadFailed = (errors) => {
            this.hide = true;
        }

        this.data = this.getEmptyData();
    }

    private getEmptyData(): IUserSearchData {
        return {
            userFirstName: '',
            userLastName: '',
            userWindowsLogon: '',
            userEmail: '',
            userIsActive: true,
            userInActive: true,
            userIsArchived: false,
            userIsNotArchived: true,
            partyFilterValues: []
        };
    }
    protected isDirty(): boolean {
        return false;
    }

    protected handleOrganisationGroupContextChanged(data: IOrganisationGroupData) {
        this.clearFeedback();
        this.setOrganisationGroupParties();
        this.gridBinding.rebind();
    }

    protected routeParamUpdated(params: Params): void {
        // called when route params are updated.
        //this.processParameters();
        //this.reset();
    }

    private setOrganisationGroupParties(): void {
        var orgGroup = this.baseRouteDeps.mainContextService.orgGroup;
        if (orgGroup) {
            // Filter out the 'All' item (with id 0)
            this.orgGroupParties = orgGroup.parties.filter(p => p.id > 0);
        }
    }

    public search(): void {
        this.gridBinding.resetPaging();
        this.gridBinding.rebind();
    }

    public reset(): void {
        this.data = this.getEmptyData();
        this.search();
    }

    public gridSelectionChange(e: SelectionEvent): void {
        if (e.selectedRows && e.selectedRows.length == 1) {
            var row = e.selectedRows[0];
            var dataItem: UserContract.IUserDataDto = row.dataItem;
            var url: string = "user/" + dataItem.id;
            this.baseRouteDeps.router.navigateByUrl(url, { replaceUrl: false });
        }
    }

    public loadGridData = (state: State, resolve: (value: GridDataResult) => void): void => {
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();
        
        this.clearFeedback();

        if (ogData.organisationGroupId === null) {
            resolve({
                data: [],
                total: 0
            });
        } else {
            var request: UserContract.IUserSearchRequest = {
                skip: state.skip,
                take: state.take,
                organisationGroupId: ogData.organisationGroupId,
                partyId: ogData.selectedPartyId,
                firstName: this.data.userFirstName,
                includeIsActive: this.data.userIsActive,
                includeIsInActive: this.data.userInActive,
                includeIsArchived: this.data.userIsArchived,
                includeIsNotArchived: this.data.userIsNotArchived,
                lastName: this.data.userLastName,
                email: this.data.userEmail,
                windowsLogon: this.data.userWindowsLogon,
                partyFilterValues: this.data.partyFilterValues,
                orderByClauses: state.sort ? GridSorting.createOrderBy(state.sort) as any : []
            };

            this.cacheRequest(request);

            this.userRepository.search(request).then(response => {
                resolve({
                    data: response.users,
                    total: response.totalRecordCount
                });
            }).catch((error) => {
                this.baseRouteDeps.logService.log(error);

                this.hide = true;
                this.handleFeedback(error);

                resolve({
                    data: [],
                    total: 0
                });
            });
        }
    }

    private getCachedRequest(): void {
        var request: UserContract.IUserSearchRequest = this.filterCacheService.get(this._gridName, this._searchRequestName);
        if (request) {
            this.data.userFirstName = request.firstName;
            this.data.userLastName = request.lastName;
            this.data.userEmail = request.email;
            this.data.userWindowsLogon = request.windowsLogon;
            this.data.userIsActive = request.includeIsActive;
            this.data.userIsArchived = request.includeIsArchived;
            this.data.userIsNotArchived = request.includeIsNotArchived;
            this.data.userInActive = request.includeIsInActive;
            this.data.partyFilterValues = request.partyFilterValues;
        }
        var filtersCollapsed = this.filterCacheService.get(this._gridName, this.filtersCollapsedName);
        if (filtersCollapsed !== undefined && filtersCollapsed !== null) {
            this.filtersCollapsed = filtersCollapsed;
        }
    }

    public toggleFilters(): void {
        this.filtersCollapsed = !this.filtersCollapsed;
        this.filterCacheService.set(this._gridName, this.filtersCollapsedName, this.filtersCollapsed);
    }
    private cacheRequest(request: UserContract.IUserSearchRequest): void {
        this.filterCacheService.set(this._gridName, this._searchRequestName, request);
    }

    public newUser(): void {
        this.baseRouteDeps.router.navigateByUrl('/userCreate');
    }
}
