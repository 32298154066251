import { OnInit, Component, ViewChild } from "@angular/core";
import { Params } from "@angular/router";
import { BaseRouteDependencies, BaseRouteDepenciesFactory, baseRouteDeps } from "../base/baseRouteDependencies.provider";
import { BaseRouteComponent } from "../base/baseRoute.component";
import { IOrganisationGroupData } from "../../services/mainContext.service";
import { DataTask } from "../../classes/dataTask"
import { DateUtils } from "../../utilities/dateUtils"

import { FpGridBindingDirective } from "../../modules/shared/directives/fpGridBinding.directive";
import { ActivateMenuOptions } from "../../../../ScriptsModels/MenuItem";
import { Utils } from "../../utilities/utils";
import { UserRepository } from "../../repositories/user.repository";

import { ClipboardService } from "ngx-clipboard";
import { AuthenticationService } from "../../services/authentication.service";
import { mnuItemsNames } from "../../services/menu.service";

import PartyContract = FostPlus.Olympus.PartyDomain.Clients.Api;
import FpCore = FostPlus.Olympus.CoreDomain.Clients.Api;
import UserContract = FostPlus.Olympus.UsersDomain.Clients.Api;
import CoreContract = FostPlus.Olympus.Core.Contract;
import { OrganisationGroupRepository } from "../../repositories/organisationGroup.repository";

interface IExtUserSelfServiceDto extends UserContract.IUserSelfServiceDto {
    $$displayApiClientId: string;

    $$activationLink: string;
    $$activationKeyValidToDate: Date;
}

export interface IExtPartyInfoDto extends UserContract.IUserAccessSelfServiceDtoPartyInfoDto {
    $$luPartyTypeName: string;
}

export interface IExtUserAccessSelfServiceDto extends UserContract.IUserAccessSelfServiceDto {
    $$party: IExtPartyInfoDto;
}

export interface IExtUserApplicationRoleSelfServiceDto extends UserContract.IUserApplicationRoleSelfServiceDto {
    $$luApplicationRoleName: string;
    $$luApplicationRoleDescription: string;
}

interface IPartyDropdownItem extends UserContract.IUserAccessCreateDataDtoPartyInfoDto {
    $$displayName: string;
}

interface ILuApplicationRoleDropdownItem extends UserContract.ILuApplicationRoleTrInfoDto {
    $$displayName: string;
}

interface IUserDetailData {
    user: IExtUserSelfServiceDto;
    userAccesses: Array<IExtUserAccessSelfServiceDto>;
    userApplicationRoles: Array<IExtUserApplicationRoleSelfServiceDto>;
}

@Component({
    templateUrl: "./userDetail.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})

export class UserDetailComponent extends BaseRouteComponent<IUserDetailData> implements OnInit {
    @ViewChild("gridBinding", { static: true }) private gridBinding: FpGridBindingDirective;
    constructor(
        baseRouteDeps: BaseRouteDependencies,
        private authenticationService: AuthenticationService,
        private userRepository: UserRepository,
        private clipboardService: ClipboardService,
        private organisationGroupRepository: OrganisationGroupRepository) {
        super("UserDetailComponent", baseRouteDeps);

        this.activateMenuOptions = new ActivateMenuOptions(mnuItemsNames.users);
    }

    private _id: number;

    public luLanguages: Array<FpCore.ILuLanguageTrInfoDto>;
    private _luLanguagesDictionary: { [id: number]: FpCore.ILuLanguageTrInfoDto };

    public luGenders: Array<FpCore.ILuGenderTrInfoDto>;


    private _luGendersDictionary: { [id: number]: FpCore.ILuGenderTrInfoDto };

    public luApplicationRoles: Array<UserContract.ILuApplicationRoleTrInfoDto>;
    private _luApplicationRolesDictionary: { [id: number]: UserContract.ILuApplicationRoleTrInfoDto };

    private _luPartyTypeDictionary: { [id: number]: PartyContract.ILuPartyTypeTrInfoDto };

    public showUserAccesses: boolean = false;
    public hide: boolean = false;
    public canEdit: boolean = false;
    public canDisable: boolean = false;
    public canEnable: boolean = false;
    public canArchive: boolean = false;
    public hasUserRights: boolean = false;
    public hasPartnerAccess: boolean;

    public dialogIsVisible: boolean = false;
    public dialogAddUserApplicationRoleIsVisible: boolean = false;
    public dialogDeactivateIsVisible: boolean = false;
    public dialogArchiveIsVisible: boolean = false;
    public partyDropdownData: Array<IPartyDropdownItem> = [];
    public luApplicationRoleDropdownData: Array<ILuApplicationRoleDropdownItem> = [];
    public partyIdToAdd: number;
    public luApplicationRoleIdToAdd: number;
    public isInternalUser: boolean;
    public itsmeUsers: Array<UserContract.IItsmeUserDto>;
    private _userAccessCreateData: UserContract.IUserAccessCreateDataDto;
    private _organisationGroupApplicationRoles: Array<UserContract.IOrganisationGroupLuApplicationRoleInfoDto> = [];

    public secret: string = '';

    protected configure() {
        this.dataLoaded = () => {
            this.clearFeedback();
            this.extendUserData();
            this.extendUserAccessData();
            this.extendUserApplicationRolesData();

            this.extendPartyDropdownData();
            this.extendLuApplicationRoleDropdownData();

            this.hide = false;
            this.showUserAccesses = this.baseRouteDeps.authenticationService.hasAccessToMultipleOrganisationGroups == false;
        };

        this.dataLoadFailed = (errors) => {
            this.hide = true;
            this.showUserAccesses = false;
        }

        this.processParameters();

        this.data = this.getEmptyDetailData();

        // dataTasks
        this.addDataTask(new DataTask(this.loadUserData, this.processUserData, { reloadOnCultureChange: false, reload: true }));
        this.addDataTask(new DataTask(this.loadUserAccessCreateData, this.processUserAccessCreateData, { reloadOnCultureChange: false, reload: true }));
        this.addDataTask(new DataTask(this.loadLuPartyTypeData, this.processLuPartyTypeData, { reloadOnCultureChange: true, reload: false }));
        this.addDataTask(new DataTask(this.loadItsmeUserData, this.processItsmeUserData, { reloadOnCultureChange: true, reload: true }));
        this.addDataTask(new DataTask(this.loadOrganisationGroupApplicationRolesData, this.processOrganisationGroupApplicationRolesData, { reloadOnCultureChange: true, reload: true }));
        this.addDataTask(new DataTask(this.loadLuLangugeData, this.processLuLangugeData, { reloadOnCultureChange: true, reload: false }));
        this.addDataTask(new DataTask(this.loadGenderData, this.processGenderData, { reloadOnCultureChange: true, reload: false }));
        this.addDataTask(new DataTask(this.loadLuApplicationRoleData, this.processLuApplicationRoleData, { reloadOnCultureChange: true, reload: false }));
    }

    protected handleOrganisationGroupContextChanged(data: IOrganisationGroupData) {
        this.reload();
    }

    protected routeParamUpdated(params: Params): void {
        // called when route params are updated.
        this.clearFeedback();
        this.processParameters();
        this.reload();
    }

    protected updateUserRights() {
        this.canEdit = Utils.hasLink(this.data.user, "Write");
        this.canDisable = Utils.hasLink(this.data.user, "Disable");
        this.canEnable = Utils.hasLink(this.data.user, "Enable");
        this.canArchive = Utils.hasLink(this.data.user, "Archive");
    }

    private extendUserData(): void {
        if (this.data.user.activationKey) {
            var baseUri = this.baseRouteDeps.configuration.appSettings.linkToUser;
            var langCode = this._luLanguagesDictionary[this.data.user.luLanguageId].parentLuLanguageCode.toLowerCase();
            this.data.user.$$activationLink = baseUri + '/#/activate?locale=' + langCode + '&key=' + this.data.user.activationKey;
            this.data.user.$$activationKeyValidToDate =
                DateUtils.NotValidOnOrAfterToValidUntil(this.data.user.activationKeyNotValidOnOrAfterDateTime);
        }

        if (this.baseRouteDeps.configuration.appSettings.identityProvider == CoreContract.IdentityProviderEnum.Okta
            && this.data.user.oktaApiClientId) {
            this.data.user.$$displayApiClientId = this.data.user.oktaApiClientId;
        } else if (this.baseRouteDeps.configuration.appSettings.identityProvider != CoreContract.IdentityProviderEnum.Okta
            && this.data.user.apiClientId) {
            this.data.user.$$displayApiClientId = this.data.user.apiClientId;
        } else {
            this.data.user.$$displayApiClientId = null;
        }

        if (this.data.user.apiClientId || this.data.user.oktaApiClientId) {

        } else {
            this.data.user.$$displayApiClientId = null;
        }

        this.updateUserRights();
    }

    private extendUserAccessData(): void {
        if (this.baseRouteDeps.authenticationService.hasAccessToMultipleOrganisationGroups == false 
            && this.data.userAccesses) {
            this.data.userAccesses.forEach(ua => {
                ua.$$party = <IExtPartyInfoDto>ua.party;

                ua.$$party.$$luPartyTypeName = this._luPartyTypeDictionary[ua.party.luPartyTypeId].name;
            });
        }
    }

    private extendUserApplicationRolesData(): void {
        if (this.data.userApplicationRoles) {
            this.data.userApplicationRoles.forEach(uar => {
                uar.$$luApplicationRoleName = this._luApplicationRolesDictionary[uar.luApplicationRoleId].name;
                uar.$$luApplicationRoleDescription = this._luApplicationRolesDictionary[uar.luApplicationRoleId].description;
            });
        }
    }

    private processParameters() {
        var params = <Params>this.params;
        this._id = params['id'];
    }

    public save() {
        this.clearFeedback();

        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (this.isFormValid()
            && ogData.organisationGroupId != null) {
            this.startBlocking();

            this.data.user.organisationGroupId = ogData.organisationGroupId;

            var request: UserContract.IUserSelfServiceUpdateRequest = {
                organisationGroupId: ogData.organisationGroupId,
                user: this.data.user
            };

            this.userRepository.update(request).then((result) => {
                this.refreshData(result.user, this.processUser, true);
                this.showSaveConfirmation();
                this.stopBlocking();
                this.scrollToTop();
            }).catch((error) => {
                this.handleFeedback(error, request);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    public saveUserAccessCreate() {
        this.clearFeedback();

        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (this.isFormValid() && ogData.organisationGroupId != null) {
            this.startBlocking();

            this.data.user.organisationGroupId = ogData.organisationGroupId;

            var request: UserContract.IUserAccessSelfServiceCreateRequest = {
                userAccess: {
                    partyId: this.partyIdToAdd,
                    userId: this.data.user.id,
                    userRowVersion: this.data.user.rowVersion,
                    organisationGroupId: this.data.user.organisationGroupId
                }
            };

            this.userRepository.createUserAccess(request).then((result) => {
                this.closeDialog();
                this.data.user.rowVersion = result.userRowVersion;
                this.refreshData(result.userAccesses, this.processUserAccesses, true);
                this.filterPartyDropdownData();
                this.extendPartyDropdownData();
                this.showSaveConfirmation();
                this.stopBlocking();
                this.scrollToTop();
            }).catch((error) => {
                this.closeDialog();
                this.handleFeedback(error, request);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    public saveUserApplicationRoleCreate() {
        this.clearFeedback();

        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (this.isFormValid() && ogData.organisationGroupId != null) {
            this.startBlocking();

            this.data.user.organisationGroupId = ogData.organisationGroupId;

            var request: UserContract.IUserApplicationRoleSelfServiceCreateRequest = {
                userApplicationRole: {
                    luApplicationRoleId: this.luApplicationRoleIdToAdd,
                    userId: this.data.user.id,
                    userRowVersion: this.data.user.rowVersion,
                    organisationGroupId: this.data.user.organisationGroupId
                }
            };

            this.userRepository.createUserApplicationRoleSelfService(request).then((result) => {
                this.closeDialog();
                this.data.user.rowVersion = result.userRowVersion;
                this.refreshData(result.userApplicationRoles, this.processUserApplicationRoles, true);
                this.extendUserApplicationRolesData();
                this.extendLuApplicationRoleDropdownData();
                this.showSaveConfirmation();
                this.stopBlocking();
                this.scrollToTop();
            }).catch((error) => {
                this.closeDialog();
                this.handleFeedback(error, request);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    public deactivateUser(): void {
        if (this.isFormValid() && this.data.user.id != null) {
            this.startBlocking();

            var request: UserContract.IUserDisableRequest = {
                user: {
                    id: this.data.user.id,
                    rowVersion: this.data.user.rowVersion
                },
                activate: false,
                organisationGroupId: this.data.user.organisationGroupId
            };

            this.userRepository.disableUser(request).then((result) => {
                this.closeDialog();
                this.refreshData(result.user, this.processUser, true);
                this.filterPartyDropdownData();
                this.extendPartyDropdownData();
                this.extendLuApplicationRoleDropdownData();
                this.extendUserData()
                this.showSaveConfirmation();
                this.stopBlocking();
                this.scrollToTop();
            }).catch((error) => {
                this.closeDialog();
                this.handleFeedback(error, request);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    public archiveUser(): void {
        if (this.isFormValid() && this.data.user.id != null) {
            this.startBlocking();

            var request: UserContract.IUserArchiveRequest = {
                user: {
                    id: this.data.user.id,
                    rowVersion: this.data.user.rowVersion
                },
                organisationGroupId: this.data.user.organisationGroupId
            };

            this.userRepository.archiveUser(request).then((result) => {
                this.closeDialog();
                this.refreshData(result.user, this.processUser, true);
                this.filterPartyDropdownData();
                this.extendPartyDropdownData();
                this.extendLuApplicationRoleDropdownData();
                this.extendUserData()
                this.showSaveConfirmation();
                this.stopBlocking();
                this.scrollToTop();
            }).catch((error) => {
                this.closeDialog();
                this.handleFeedback(error, request);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    public createApiClient(): void {
        if (this.data.user.id != null) {
            this.startBlocking();

            var request: UserContract.IUserSelfServiceCreateClientApiRequest = {
                user: {
                    id: this.data.user.id,
                    rowVersion: this.data.user.rowVersion
                },
                organisationGroupId: this.data.user.organisationGroupId

            };

            this.userRepository.createClientApi(request).then((result) => {
                this.refreshData(result.user, this.processUser, true);

                this.secret = result.secret;

                this.filterPartyDropdownData();
                this.extendPartyDropdownData();
                this.extendLuApplicationRoleDropdownData();
                this.showSaveConfirmation();
                this.stopBlocking();
                this.scrollToTop();
            }).catch((error) => {
                this.handleFeedback(error, request);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    public updateClientSecret(): void {
        if (this.data.user.id != null) {
            this.startBlocking();

            var request: UserContract.IUserSelfServiceUpdateClientSecretRequest = {
                user: {
                    id: this.data.user.id,
                    rowVersion: this.data.user.rowVersion
                },
                organisationGroupId: this.data.user.organisationGroupId

            };

            this.userRepository.updateClientSecret(request).then((result) => {
                this.refreshData(result.user, this.processUser, true);

                this.secret = result.secret;

                this.filterPartyDropdownData();
                this.extendPartyDropdownData();
                this.extendLuApplicationRoleDropdownData();
                this.showSaveConfirmation();
                this.stopBlocking();
                this.scrollToTop();
            }).catch((error) => {
                this.handleFeedback(error, request);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    public copySecretToClipboard() {
        this.clipboardService.copy(this.secret);
        this.showSaveConfirmation('MyApp.CopiedToClipBoard', true);
    }

    public copyApiClientIdToClipboard() {
        this.clipboardService.copy(this.data.user.$$displayApiClientId);
        this.showSaveConfirmation('MyApp.CopiedToClipBoard', true);
    }

    public deleteItsmeUser(itsmeUserId: string) {
        this.clearFeedback();

        var user = this.authenticationService.userIdentity;
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (ogData.organisationGroupId != null) {
            this.startBlocking();

            var request: UserContract.IUserItsmeUserSelfServiceDeleteRequest = {
                itsmeUserId: itsmeUserId,
                organisationGroupId: ogData.organisationGroupId,
                userId: user.id
            };

            this.userRepository.userItsmeUserSelfServiceDelete(request).then((result) => {
                this.reload();
                this.stopBlocking();
                this.scrollToTop();
            }).catch((error) => {
                this.handleFeedback(error, request);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    public deleteUserApplicationRole(userApplicationRole: IExtUserApplicationRoleSelfServiceDto) {
        this.clearFeedback();

        var user = this.authenticationService.userIdentity;
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (ogData.organisationGroupId != null) {
            this.startBlocking();

            var request: UserContract.IUserApplicationRoleSelfServiceDeleteRequest = {
                userApplicationRole: userApplicationRole,
                organisationGroupId: ogData.organisationGroupId
            };

            this.userRepository.deleteUserApplicationRoleSelfService(request).then((result) => {
                this.reload();
                this.stopBlocking();
                this.scrollToTop();
            }).catch((error) => {
                this.handleFeedback(error, request);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    private getEmptyDetailData(): IUserDetailData {
        return {
            user: {
                id: null,
                windowsLogon: '',
                firstName: '',
                lastName: '',
                email: '',
                luGenderId: null,
                luLanguageId: null,
                organisationGroupId: null,
                isActive: false,
                isArchived: false,
                activationKey: '',
                activationKeyNotValidOnOrAfterDateTime: null,
                activatedOnDateTime: null,
                apiClientId: null,
                oktaApiClientId: null,
                links: [],
                rowVersion: [],
                $$activationLink: '',
                $$activationKeyValidToDate: null,
                $$displayApiClientId: null
            },
            userAccesses: [],
            userApplicationRoles: []
        }
    }

    public addPartner(): void {
        this.dialogIsVisible = true;
    }

    public addUserApplicationRole(): void {
        this.dialogAddUserApplicationRoleIsVisible = true;
    }

    public deactivate() {
        this.dialogDeactivateIsVisible = true;
    }
    
    public archive() {
        this.dialogArchiveIsVisible = true;
    }

    public sendPasswordEmail(): void {
        this.clearFeedback();

        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (this.data && !this.data.user.isActive) {
            this.startBlocking();
            this.userRepository.createUserActivationEmail(this.data.user.id, ogData.organisationGroupId).then(
                result => {
                    this.showSaveConfirmation('MyApp.PasswordEmailSent', true);
                    this.stopBlocking();

                    this.reload();
                }).catch(error => {
                    this.handleFeedback(error);
                    this.stopBlocking();
                });
        }
    }

    public closeDialog(): void {
        this.partyIdToAdd = null;
        this.luApplicationRoleIdToAdd = null
        this.dialogIsVisible = false;
        this.dialogDeactivateIsVisible = false;
        this.dialogArchiveIsVisible  = false;
        this.dialogAddUserApplicationRoleIsVisible = false;
    }

    public userHasAccess(): void {
        this.hasUserRights = false;

        if (this.data.userAccesses) {
            this.data.userAccesses.forEach(ua => {
                if (ua.userAccessResourceActions.some(uara => uara.hasAccess)) {
                    this.hasUserRights = true;
                }
            });
        }
    }

    public userHasPartnerAccess(): void {
        this.hasPartnerAccess = false;

        if (this.data.userAccesses) {
            this.hasPartnerAccess = this.data.userAccesses.some(ua => {
                return ua.party && ua.party.luPartyTypeId === PartyContract.LuPartyTypeId.Partner;
            })
        }
    }

    public checkUsernameAlreadyExists(): void {
        this.clearFeedback();
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (this.data.user.windowsLogon && this.data.user.windowsLogon.length > 0 && ogData.organisationGroupId != null) {
            this.userRepository.checkUsernameAvailability(this.data.user.windowsLogon, ogData.organisationGroupId).then(result => {
                if (result.isAvailable) {
                    this.showWindowsLogonNotInUseSuccessToast();
                } else {
                    this.showWindowsLogonAlreadyExistsErrorToast();
                }
            }).catch(error => {
                this.handleFeedback(error);
            });
        }
    }

    private showWindowsLogonAlreadyExistsErrorToast = () => {
        this.baseRouteDeps.translateService.get("Users.User_Validation_WindowsLogonNotUnique").subscribe((translation) => {
            this.baseRouteDeps.toastr.error(translation);
        });
    };

    private showWindowsLogonNotInUseSuccessToast = () => {
        this.baseRouteDeps.translateService.get("MyApp.WindowsLogonNotInUse").subscribe((translation) => {
            this.baseRouteDeps.toastr.success(translation);
        });
    };

    private filterPartyDropdownData(): void {
        var uaPartyIds = this.data.userAccesses.map(ua => ua.partyId);
        this.partyDropdownData = <IPartyDropdownItem[]>this._userAccessCreateData.parties.filter(p => !uaPartyIds.find(x => x == p.id));
    }

    private extendPartyDropdownData(): void {
        this.partyDropdownData.forEach(p => {
            var luPartyTypeName = this._luPartyTypeDictionary[p.luPartyTypeId].name;
            p.$$displayName = p.name + ' (' + luPartyTypeName + ')';
        })

        this.partyDropdownData = this.baseRouteDeps.lookupDataUtilities.sortDataArray(this.partyDropdownData, '$$displayName');
    }

    private extendLuApplicationRoleDropdownData(): void {
        var alreadyAssignedRoles = this.data.userApplicationRoles;

        let availableRoles = <Array<ILuApplicationRoleDropdownItem>>this.luApplicationRoles.filter((role: ILuApplicationRoleDropdownItem) => role.isExternalRole == true && !alreadyAssignedRoles.some(uar => uar.luApplicationRoleId == role.id));

        // Limit to applicationRoles available in organisation
        availableRoles = availableRoles.filter(e => this._organisationGroupApplicationRoles.some(r => r.luApplicationRoleId == e.id));

        availableRoles.forEach(p => {
            var luApplicationRoleName = this._luApplicationRolesDictionary[p.id].name;
            p.$$displayName = luApplicationRoleName;
        })

        this.luApplicationRoleDropdownData = this.baseRouteDeps.lookupDataUtilities.sortDataArray(availableRoles, '$$displayName');
    }

    /* DataTask Funcs */
    private loadUserData(resolve: (data: UserContract.IUserSelfServiceGetResponse) => void, reject: (reason: any) => void) {
        var user = this.authenticationService.userIdentity;
        this.isInternalUser = user.isInternalUser;
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();
        this.processParameters();
        if (ogData.organisationGroupId != null) {
            this.userRepository.get(this._id, ogData.organisationGroupId).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        } else {
            resolve(null);
        }
    }

    private processUserData(data: UserContract.IUserSelfServiceGetResponse) {
        if (data) {
            this.processUser(data.user);
            this.processUserAccesses(data.userAccesses);
            this.processUserApplicationRoles(data.userApplicationRoles)
            this.userHasAccess();
            this.userHasPartnerAccess();
        } else {
            this.data = this.getEmptyDetailData();
        }
    }

    private processUser(data: UserContract.IUserSelfServiceDto) {
        this.data.user = <IExtUserSelfServiceDto>data;
    }

    private processUserAccesses(data: Array<UserContract.IUserAccessSelfServiceDto>) {
        this.data.userAccesses = <IExtUserAccessSelfServiceDto[]>data;
        // Custom sort because we have party object on userAccesses
        this.sortUserAccesses();
    }

    private processUserApplicationRoles(data: Array<UserContract.IUserApplicationRoleSelfServiceDto>) {
        this.data.userApplicationRoles = <IExtUserApplicationRoleSelfServiceDto[]>data;
        // Custom sort because we have party object on userAccesses
        this.sortUserApplicationRoles();
    }

    private sortUserAccesses() {
        this.data.userAccesses = this.data.userAccesses.sort((a: IExtUserAccessSelfServiceDto, b: IExtUserAccessSelfServiceDto) => {
            if (a.party.name < b.party.name) {
                return -1;
            } else if (a.party.name > b.party.name) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    private sortUserApplicationRoles() {
        this.data.userApplicationRoles = this.data.userApplicationRoles.sort((a: IExtUserApplicationRoleSelfServiceDto, b: IExtUserApplicationRoleSelfServiceDto) => {
            if (a.$$luApplicationRoleName < b.$$luApplicationRoleName) {
                return -1;
            } else if (a.$$luApplicationRoleName > b.$$luApplicationRoleName) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    private loadUserAccessCreateData(resolve: (data: UserContract.IUserAccessCreateDataGetResponse) => void, reject: (reason: any) => void) {
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();
        this.processParameters();

        if (ogData.organisationGroupId != null) {
            this.userRepository.getUserAccessCreateData(ogData.organisationGroupId).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        } else {
            resolve(null);
        }
    }

    private processUserAccessCreateData(data: UserContract.IUserAccessCreateDataGetResponse) {
        if (data && data.userAccessCreateData) {
            this._userAccessCreateData = data.userAccessCreateData;
            this.filterPartyDropdownData();
        }
    }

    private loadLuPartyTypeData(resolve: (data: Array<PartyContract.ILuPartyTypeTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getLuPartyTypeTrsInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processLuPartyTypeData(data: Array<PartyContract.ILuPartyTypeTrInfoDto>) {
        this._luPartyTypeDictionary = this.baseRouteDeps.lookupDataUtilities.convertToDictionary(data, 'id');
    }

    private loadLuLangugeData(resolve: (data: Array<FpCore.ILuLanguageTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getLuLanguageTrsInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processLuLangugeData(data: Array<FpCore.ILuLanguageTrInfoDto>) {
        var languages = this.baseRouteDeps.lookupDataUtilities.filterCommunicationLanguages(data);
        this.luLanguages = languages;
        this._luLanguagesDictionary = this.baseRouteDeps.lookupDataUtilities.convertToDictionary(languages, 'id');
    }

    private loadGenderData(resolve: (data: Array<FpCore.ILuGenderTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getGendersInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processGenderData(data: Array<FpCore.ILuGenderTrInfoDto>) {
        this.luGenders = data;
        this._luGendersDictionary = this.baseRouteDeps.lookupDataUtilities.convertToDictionary(data, 'id');
    }

    private loadItsmeUserData(resolve: (data: UserContract.IUserItsmeUsersSelfServiceSearchResponse) => void, reject: (reason: any) => void) {
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();
        if (ogData.organisationGroupId && this._id) {
            var request: UserContract.IUserItsmeUsersSelfServiceSearchRequest = {
                organisationGroupId: ogData.organisationGroupId,
                userId: this._id
            };

            this.userRepository.userItsmeUserSelfServiceSearch(request).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        }
        else {
            resolve(null);
        }
    }

    private processItsmeUserData(data: UserContract.IUserItsmeUsersSelfServiceSearchResponse) {
        if (data) {
            this.itsmeUsers = data.userItsmeUsers;
        }
    }

    private loadOrganisationGroupApplicationRolesData(resolve: (data: UserContract.IOrganisationGroupApplicationRolesInfoGetResponse) => void, reject: (reason: any) => void) {
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();
        if (ogData.organisationGroupId && this._id) {
            this.organisationGroupRepository.getOrganisationGroupsApplicationRolesInfo(ogData.organisationGroupId).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        }
        else {
            resolve(null);
        }
    }

    private processOrganisationGroupApplicationRolesData(data: UserContract.IOrganisationGroupApplicationRolesInfoGetResponse) {
        if (data) {
            this._organisationGroupApplicationRoles = data.organisationGroupApplicationRoles;
        }
    }

    private loadLuApplicationRoleData(resolve: (data: Array<UserContract.ILuApplicationRoleTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getLuApplicationRoleTrsInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processLuApplicationRoleData(data: Array<UserContract.ILuApplicationRoleTrInfoDto>) {
        this.luApplicationRoles = data;
        this._luApplicationRolesDictionary = this.baseRouteDeps.lookupDataUtilities.convertToDictionary(data, 'id');
    }
}
