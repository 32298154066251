<div [ngClass]="{'form-group control-wrapper': !readMode, 'readmode-wrapper': readMode, 'error': model && model.invalid && model.dirty}">
    <label *ngIf="readMode" class="control-normal-label">
        {{ value }}
    </label>
    <table *ngIf="!readMode" class="table-fixed">
        <tr>
            <td>
                <div>
                    <input type="text"
                           kendoTextBox
                           [id]="controlId"
                           [name]="controlId + '_input'"
                           [(ngModel)]="value"
                           [disabled]="disabled"
                           [attr.maxlength]="_maxlength"
                           placeholder="{{ placeholderText }}" />
                    <small *ngIf="helpTlk" [id]="helpId" class="text-muted" fp-translate>{{ helpTlk }}</small>
                </div>
            </td>
            <td width="15px">
                <div *ngIf="model && model.invalid && model.dirty" class="tooltip-validation">
                    <fa-icon icon="exclamation-circle"></fa-icon>
                </div>
            </td>
        </tr>
        <tr *ngIf="model && model.invalid && model.dirty">
            <td colspan="2">
                <div>
                    <fp-validation-error [for]="name" [errors]="model?.errors"></fp-validation-error>
                </div>
            </td>
        </tr>
    </table>
</div>
