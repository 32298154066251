import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, Observable, of, switchMap, take, tap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { ConfigurationService } from '../services/configuration.service';
import { LogService } from '../services/log.service';

@Injectable()
export class AddAuthorizationHeaderInterceptor implements HttpInterceptor {
    private authService?: AuthenticationService;

    constructor(
        private injector: Injector,
        private logService: LogService,
        private config: ConfigurationService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestToForward = req;

        if (this.authService === undefined) {
            this.authService = this.injector.get<AuthenticationService>(AuthenticationService);
        }

        if (this.authService === undefined) {
            this.logService.info('AuthenticationService undefined: NO auth header!');

            return next.handle(requestToForward);
        }

        if (!this.authService.isOidcAuthenticated) {
            this.logService.info('No authenticad user: NO auth header!');

            return next.handle(requestToForward);
        }

        if (!this.config.mustUseInterceptor(requestToForward.url)) {
            return next.handle(requestToForward);
        }

        return this.authService.getToken()
            .pipe(
                catchError((error) => {
                    this.logService.warn('AuthenticationService error getting token: Probably not configured yet!');
                    return of(null);
                }),
                tap((token: string | null) => {
                    this.logService.info('Oidc token received.');
                }),
                switchMap((token: string | null) => {
                    if (token && token !== '') {
                        const tokenValue = 'Bearer ' + token;
                        requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
                    }

                    return next.handle(requestToForward);
                })
            );
    }
}