<div [ngClass]="{'form-group control-wrapper': !readMode, 'readmode-wrapper': readMode, 'error': model && model.invalid && model.dirty}">
    <label *ngIf="readMode" class="control-normal-label">
        {{ getReadModeText() }}
    </label>
    <table *ngIf="!readMode" class="table-fixed">
        <tr>
            <td>
                <div>
                    <kendo-dropdownlist [id]="controlId"
                                        [defaultItem]="defaultItem"
                                        [data]="filteredData"
                                        [(ngModel)]="value"
                                        [valuePrimitive]="valuePrimitive"
                                        [textField]="textField"
                                        [valueField]="valueField"
                                        [filterable]="filterable"
                                        (filterChange)="onFilterChange($event)"
                                        [disabled]="disabled"
                                        (close)="closeEvent($event)">

                    </kendo-dropdownlist>
                </div>
            </td>
            <td width="15px">
                <div *ngIf="model && model.invalid && model.dirty" class="tooltip-validation">
                    <fa-icon icon="exclamation-circle"></fa-icon>
                </div>
            </td>
        </tr>
        <tr *ngIf="model && model.invalid && model.dirty">
            <td colspan="2">
                <div>
                    <fp-validation-error [for]="name" [errors]="model?.errors"></fp-validation-error>
                </div>
            </td>
        </tr>
    </table>
</div>
