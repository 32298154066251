<!--<div class="validation-errors">
    <ul *ngIf="messages && messages.length > 0">
        <li *ngFor="let message of messages">
            {{message}}
        </li>
    </ul>
</div>-->
<div class="validation-errors">
    <div *ngIf="messages && messages.length > 0">
        <div *ngFor="let message of messages">
            {{message}}
        </div>
    </div>
</div>


