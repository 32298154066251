import { Component, OnInit } from "@angular/core";
import { BaseRouteComponent } from "../base/baseRoute.component";
import { baseRouteDeps, BaseRouteDepenciesFactory, BaseRouteDependencies } from "../base/baseRouteDependencies.provider";


@Component({
    templateUrl: "./logoff.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class LogoffComponent extends BaseRouteComponent<any> implements OnInit {
    public isInError: boolean = false;

    constructor(baseRouteDeps: BaseRouteDependencies) {
        super("LogoffComponent", baseRouteDeps);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.baseRouteDeps.authenticationService.logoff();
    }

    protected configure() {
        this.baseRouteDeps.mainContextService.title = "";

        this.dataLoaded = () => {
        };
    }

    protected isDirty(): boolean {
        return false;
    }
}