import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { ConfigurationService } from "../services/configuration.service";

import { Observable } from 'rxjs';

@Injectable()
export class InjectHeadersInterceptor implements HttpInterceptor {

    constructor(private config: ConfigurationService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.config.mustUseInterceptor(req.url)) {
            let headers = req.headers.set('Content-Type', 'application/json');

            headers = headers.append("X-Requested-With", "XMLHttpRequest");

            if (this.config.getRequestLanguage()) {
                headers = headers.append("Accept-Language", this.config.getRequestLanguage());
            }

            // We set all requests to response type text (instead of json),
            // so the responseToPoJo.interceptor can parse the responses with the date reviver
            // => except when arraybuffer, we keep arraybuffer
            let respType: 'text' | 'json' | 'blob' | 'arraybuffer' = 'text';
            if (req.responseType === 'arraybuffer') {
                respType = 'arraybuffer';
            }

            req = req.clone({
                headers: headers,
                responseType: respType
            });
        }

        return next.handle(req);
    }
}