import { Injectable } from "@angular/core";
import { LogService } from "./log.service";

@Injectable()
export class BlockUiService
{
    constructor(private logService: LogService) {
        this.id = Math.floor(Math.random() * 1000000);

        this.overlay = document.createElement("div");
        this.overlay.className = "blockui-overlay";
        this.overlay.textContent = "";

        this.log('construcor BlockUiService id: %s', this.id);
    }

    // set to true to log debugging info
    //  ... but it produces big data :-)
    private doLog: boolean = true;
    private id: number;

    private element: HTMLElement;
    private overlay: HTMLDivElement;

    private blockCount: number = 0;
    private originalPosition: string = null;
    private isNonStatic: boolean = false;
    private overlayShowing: boolean = false;

    public set blockElement(e: HTMLElement) {

        if (e) {
            // Just in case the element changes
            // Stop blocking on the current element before ...
            this.stopBlocking();

            if (e.style) {
                this.originalPosition = e.style.position;
                this.isNonStatic = this.elementHasNonStaticPosition(e);
            }

            this.element = e;

            this.log('set BlockUiService element id: %s', this.id);
        }
    }

    public startBlocking() {
        if (this.element) {
            if (!this.overlayShowing) {
                // Add position = relative to blockUi container if needed
                // This allows us to position the overlay relative to this div.
                this.originalPosition = this.element.style.position;

                if (!this.isNonStatic) {
                    this.element.style.position = "relative";
                }

                // Add the overlay div            
                this.element.appendChild(this.overlay);
                this.overlayShowing = true;

                this.log('start blocking BlockUiService id: %s', this.id);
            }

            this.blockCount++;
        }
    }

    public stopBlocking() {
        if (this.element) {
            if (this.blockCount !== 0) {
                this.blockCount--;
            }

            if (this.overlayShowing) {
                // todo, some screens keep showing spinner
                if (true && this.blockCount === 0) {
                    // restore original position
                    if (!this.isNonStatic && this.originalPosition !== null) {
                        this.element.style.position = this.originalPosition;
                    }

                    // remove overlay div
                    this.element.removeChild(this.overlay);
                    this.overlayShowing = false;

                    this.log('stop blocking BlockUiService id: %s', this.id);
                }
            }
        }
    }

    public resetBlocking() {
        this.blockCount = 1;
        this.stopBlocking();
    }

    private elementHasNonStaticPosition(element: HTMLElement): boolean {
        if (element) {
            if (!(element.style.position === "") && !(element.style.position === "static")) {
                return true;
            }
        }

        return false;
    }

    private log(arg1: any, ...arg2: any[]) {
        if (this.doLog) {
            this.logService.log(arg1, ...arg2);
        }
    }

    public isBlocking() {
        return this.overlayShowing;
    }
}