import { Injectable, Inject } from '@angular/core';
import { HttpProvider } from '../services/http.provider';
import { ConfigurationService } from "../services/configuration.service";

import MyFost = FostPlus.MyFost.Web.Gui

@Injectable()
export class AppSettingsLoader {

    constructor(private http: HttpProvider,
        private config: ConfigurationService,
        @Inject('rootUrl') private rootUrl: string) {
    }

    /**
    * Loads the application settings.
    * It should be called via an APP_INITIALIZER in the providers of a module.
    *
    * @returns - The application settings.
    */
    public loadAppSettings(): Promise<any> {
        //if (!this.config.isAppSettingsLoaded()) {
            return this.http.get<MyFost.ISettings>(this.rootUrl + 'api/app/settings').then((data) => {
                this.config.setAppSettings(data);

                // TODO
                //if ((<any>window).appInsights) {
                //    (<any>window).appInsights.stopTrackEvent('initialLoad');
                //}
            });
        //} else {
        //    return new Promise((resolve) => {
        //        resolve();
        //    });
        //}
    }
}