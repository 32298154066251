import { Component, Input } from "@angular/core";

@Component({
    selector: "fp-alert",
    templateUrl: "./fpAlert.component.html",
    host: { 'class': 'fp-control' }
})

export class FpAlertComponent {
    @Input() label: string;
    /*
     Possible types: - info     (blue) => default
                     - success  (green)
                     - warning  (yellow)
                     - danger   (red)
     */
    @Input() type: string = "info";
    @Input() faIcon: string = "info-circle"; 
}