import { Component, OnInit } from '@angular/core';
import { BaseRouteComponent } from "../base/baseRoute.component";
import { baseRouteDeps, BaseRouteDepenciesFactory, BaseRouteDependencies } from "../base/baseRouteDependencies.provider";

@Component({
    templateUrl: './loggedOut.component.html',
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class LoggedOutComponent extends BaseRouteComponent<any> implements OnInit {

    constructor(baseRouteDeps: BaseRouteDependencies) {
        super('LoggedOutComponent', baseRouteDeps);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    public logon() {
        this.baseRouteDeps.authenticationService.checkAuth();
    }

    protected configure() {
    }
}