<div *ngIf="isDebug">
    <div class="row">
        <div class="col-md-12">
            <hr class="fost">
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <h4 class="panel-title hand" (click)="toggleDebugView()">
                Debug Info
                <fa-icon class="fost"
                         [icon]="!showDebug ? 'chevron-right' : 'chevron-down'"></fa-icon>
            </h4>
        </div>
    </div>
    <ng-content *ngIf="showDebug"></ng-content>
</div>