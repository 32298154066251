<form #myForm="ngForm" novalidate>
    <fp-error-display [errors]="errors"
                      [warnings]="warnings"></fp-error-display>
    <div class="row">
        <div class="col-lg-12">
            <h4 class="panel-title" fp-translate>
                MyApp.User
            </h4>
        </div>
    </div>

    <div *ngIf="!hide">
        <div class="row">
            <div class="col-md-2">
                <fp-label label="Users.User_WindowsLogon"></fp-label>
            </div>
            <div class="col-md-6">
                <fp-textbox name="Users.User_WindowsLogon"
                            [(ngModel)]="data.user.windowsLogon"
                            required="true"
                            maxlength="254"
                            [readMode]="true"></fp-textbox>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <fp-label label="Users.User_Email"></fp-label>
            </div>
            <div class="col-md-2">
                <fp-textbox name="Users.User_Email"
                            [(ngModel)]="data.user.email"
                            required="true"
                            maxlength="50"
                            [readMode]="!canEdit"></fp-textbox>
            </div>
            <div class="col-md-2">
                <fp-label label="Users.User_FirstName"></fp-label>
            </div>
            <div class="col-md-2">
                <fp-textbox name="Users.User_FirstName"
                            [(ngModel)]="data.user.firstName"
                            required="true"
                            maxlength="256"
                            [readMode]="!canEdit"></fp-textbox>
            </div>
            <div class="col-md-2">
                <fp-label label="Users.User_LastName"></fp-label>
            </div>
            <div class="col-md-2">
                <fp-textbox name="Users.User_LastName"
                            [(ngModel)]="data.user.lastName"
                            required="true"
                            maxlength="256"
                            [readMode]="!canEdit"></fp-textbox>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <fp-label label="Users.User_LuGenderId"></fp-label>
            </div>
            <div class="col-md-2">
                <fp-dropdown name="Users.User_LuGenderId"
                             [(ngModel)]="data.user.luGenderId"
                             [(data)]="luGenders"
                             valueField="id"
                             textField="salutationShortName"
                             required="true"
                             [showDefaultItem]="true"
                             [readMode]="!canEdit"></fp-dropdown>
            </div>
            <div class="col-md-2">
                <fp-label label="Users.User_LuLanguageId"></fp-label>
            </div>
            <div class="col-md-2">
                <fp-dropdown name="Users.User_LuLanguageId"
                             [(ngModel)]="data.user.luLanguageId"
                             [(data)]="luLanguages"
                             valueField="id"
                             textField="name"
                             required="true"
                             [showDefaultItem]="true"
                             [readMode]="!canEdit"></fp-dropdown>
            </div>
        </div>

        <div class="row">
            <div class="col-md-2">
                <fp-label label="Users.User_Active"></fp-label>
            </div>
            <div class="col-md-2 activeIcon">
                <span *ngIf="data.user.isActive"><fa-icon [icon]="['far', 'square-check']"></fa-icon></span>
                <span *ngIf="!data.user.isActive"><fa-icon [icon]="['far', 'square']"></fa-icon></span>
            </div>
            <div class="col-md-2">
                <fp-label label="Users.User_Archived"></fp-label>
            </div>
            <div class="col-md-2 activeIcon">
                <span *ngIf="data.user.isArchived"><fa-icon [icon]="['far', 'square-check']"></fa-icon></span>
                <span *ngIf="!data.user.isArchived"><fa-icon [icon]="['far', 'square']"></fa-icon></span>
            </div>
        </div>

        <div class="row" *ngIf="!data.user.isActive">
            <div class="col-lg-2">
                <fp-label label="MyApp.ActivationLink"></fp-label>
            </div>
            <div class="col-lg-10">
                <label class="control-normal-label">{{data.user.$$activationLink}}</label>
            </div>
        </div>
        <div class="row" *ngIf="!data.user.isActive">
            <div class="col-lg-2">
                <fp-label label="MyApp.ActivationLinkValidUntil"></fp-label>
            </div>
            <div class="col-lg-10">
                <label class="control-normal-label">{{data.user.$$activationKeyValidToDate | kendoDate: 'dd.MM.yyyy'}}</label>
            </div>
        </div>
        <div class="row" *ngIf="data.user.isActive">
            <div class="col-lg-2">
                <label class="control-label green" fp-translate>Users.User_ActivatedOnDateTime</label>
            </div>
            <div class="col-lg-10">
                <label class="control-normal-label green">{{data.user.activatedOnDateTime | kendoDate: 'dd.MM.yyyy HH:mm'}}</label>
            </div>
        </div>
        <div class="row" *ngIf="itsmeUsers && itsmeUsers.length > 0">
            <div class="col-md-2">
                <fp-label label="MyApp.Itsme"></fp-label>
            </div>
            <div class="col-md-4">
                <table class="table table-bordered table-super-condensed table-fixed">
                    <thead>
                        <tr>
                            <th>
                                <fp-label label="Users.User_ItsmeUser"></fp-label>
                            </th>
                            <th width="44px">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let itsmeUser of itsmeUsers;let even = even"
                            [class.k-alt]="even">
                            <td>
                                {{itsmeUser.firstName + ' ' + itsmeUser.lastName}}
                            </td>
                            <td>
                                <button class="btn btn-outline" (click)="deleteItsmeUser(itsmeUser.id)" [disabled]="isInternalUser">
                                    <fa-icon icon="trash-can"></fa-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row">
        <!-- Buttons -->
        <div class="col-md-12">
            <div class="btn-toolbar">
                <button class="btn btn-primary" type="submit" (click)="save()" *ngIf="!hide && canEdit">
                    <fa-icon icon="download"></fa-icon>
                    <span fp-translate>MyApp.Save</span>
                </button>
                <button class="btn btn-outline" type="button" (click)="historyBack()">
                    <fa-icon icon="circle-arrow-left" size="lg"></fa-icon>
                    <span fp-translate>MyApp.Back</span>
                </button>
                <button class="btn btn-outline" (click)="sendPasswordEmail()" *ngIf="(!data.user.isActive && hasUserRights)">
                    <fa-icon icon="paper-plane"></fa-icon>
                    <span fp-translate>MyApp.SendPasswordEmail</span>
                </button>
                <button class="btn btn-danger" type="submit" (click)="deactivate()" *ngIf="!hide && canDisable">
                    <fa-icon icon="times"></fa-icon>
                    <span fp-translate>MyApp.Deactivate</span>
                </button>
                <button class="btn btn-danger" type="submit" (click)="archive()" *ngIf="!hide && canArchive">
                    <fa-icon icon="box-archive"></fa-icon>
                    <span fp-translate>MyApp.Archive</span>
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            &nbsp;
        </div>
    </div>
    <!--passwordemail and useraccess-->
    <div *ngIf="!hide && showUserAccesses">
        <div class="row">
            <div class="col-lg-12">
                &nbsp;
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h4 class="panel-title" fp-translate>
                    MyApp.Approvals
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <fp-alert label="MyApp.SendUserPasswordEmail_Info"></fp-alert>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <table class="table table-bordered table-super-condensed table-fixed">
                    <thead>
                        <tr>
                            <th>
                                <fp-label label="Users.UserAccess_PartyId"></fp-label>
                            </th>
                            <th>
                                <fp-label label="Party.Party_LuPartyTypeId"></fp-label>
                            </th>
                            <th *ngIf="canEdit" class="grid-button-column">
                                <button class="btn btn-outline pull-right" type="button" (click)="addPartner()">
                                    <fa-icon icon="plus" class="grid-button"></fa-icon>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ua of data.userAccesses;let even = even"
                            [class.k-alt]="even">
                            <td>
                                <a href="#/userAccess/{{ua.id}}">
                                    {{ua.$$party.name}}
                                </a>
                            </td>
                            <td>
                                {{ua.$$party.$$luPartyTypeName}}
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="!hide">
        <div class="row">
            <div class="col-lg-12">
                &nbsp;
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h4 class="panel-title" fp-translate>
                    MyApp.User_Application_Roles
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <table class="table table-bordered table-super-condensed table-fixed">
                    <thead>
                        <tr>
                            <th>
                                <fp-label label="Users.LuApplicationRole_Name"></fp-label>
                            </th>
                            <th>
                                <fp-label label="Users.LuApplicationRole_Description"></fp-label>
                            </th>
                            <th *ngIf="canEdit" class="grid-button-column">
                                <button class="btn btn-outline pull-right" type="button" (click)="addUserApplicationRole()">
                                    <fa-icon icon="plus" class="grid-button"></fa-icon>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let uar of data.userApplicationRoles;let even = even"
                            [class.k-alt]="even">
                            <td class="vertical-align-middle">
                                {{uar.$$luApplicationRoleName}}
                            </td>
                            <td class="vertical-align-middle">
                                {{uar.$$luApplicationRoleDescription}}
                            </td>
                            <td *ngIf="canEdit && !uar.isDefaultRole">
                                <button class="btn btn-outline pull-right" (click)="deleteUserApplicationRole(uar)">
                                    <fa-icon icon="trash-can" class="grid-button"></fa-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!--api client-->
    <div *ngIf="hasPartnerAccess">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="panel-title" fp-translate>
                    MyApp.ApiClient
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <fp-alert label="Users.User_Label_ClientIdClientSecret_Info"></fp-alert>
            </div>
        </div>
        <div class="row" *ngIf="canEdit && !isDirty()">
            <div class="col-md-12">
                <div class="btn-toolbar">
                    <button class="btn btn-outline" type="button" *ngIf="!data.user.$$displayApiClientId" (click)="createApiClient()">
                        <fa-icon icon="plus"></fa-icon>
                        <span fp-translate>MyApp.ApiClient_Create</span>
                    </button>
                    <button class="btn btn-outline" type="button" *ngIf="data.user.$$displayApiClientId" (click)="updateClientSecret()">
                        <fa-icon icon="plus"></fa-icon>
                        <span fp-translate>MyApp.ApiClient_UpdateClientSecret</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="data.user.$$displayApiClientId">
            <div class="col-md-2">
                <label class="control-label green" fp-translate>Users.User_ApiClientId</label>
            </div>
            <div class="col-md-10">
                <label class="control-label font-weight-light">
                    {{data.user.$$displayApiClientId}}
                </label>
                &nbsp;
                <fa-icon [icon]="['far', 'copy']" (click)="copyApiClientIdToClipboard()" class="hand" title="{{ 'MyApp.CopyToClipboard' | translate }}"></fa-icon>
            </div>
        </div>
        <div class="row" *ngIf="secret">
            <div class="col-md-2">
                <label class="control-label green" fp-translate>MyApp.ApiClientSecret</label>
            </div>
            <div class="col-md-10">
                <label class="control-label font-weight-light">
                    {{secret}}
                </label>
                &nbsp;
                <fa-icon [icon]="['far', 'copy']" (click)="copySecretToClipboard()" class="hand"  title="{{ 'MyApp.CopyToClipboard' | translate }}"></fa-icon>
            </div>
            <div class="col-md-12">
                <fp-alert type="warning" label="Users.User_Label_ClientSecret_Warning"></fp-alert>
            </div>
        </div>
    </div>

    <kendo-dialog *ngIf="dialogIsVisible"
                  (close)="closeDialog()"
                  [width]="650">
        <kendo-dialog-titlebar>
            <b fp-translate>MyApp.AddPartnerOrMember</b>
        </kendo-dialog-titlebar>

        <div class="row" *ngIf="inError">
            <div class="col-md-12">
                <div class="alert alert-danger" role="alert">

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <fp-label label="Users.UserAccess_PartyId"></fp-label>
            </div>
            <div class="col-md-9">
                <fp-dropdown name="Users.UserAccess_PartyId"
                             [(ngModel)]="partyIdToAdd"
                             [(data)]="partyDropdownData"
                             valueField="id"
                             textField="$$displayName"
                             required="true"
                             [showDefaultItem]="true"></fp-dropdown>
            </div>
        </div>

        <kendo-dialog-actions>
            <button kendoButton primary="true" type="button" (click)="saveUserAccessCreate()" [disabled]="!partyIdToAdd">
                <span fp-translate>MyApp.Save</span>
            </button>
            <button kendoButton type="button" (click)="closeDialog()">
                <span fp-translate>MyApp.Cancel</span>
            </button>
        </kendo-dialog-actions>
    </kendo-dialog>

    <kendo-dialog *ngIf="dialogDeactivateIsVisible"
                  (close)="closeDialog()"
                  [width]="500">
        <kendo-dialog-titlebar>
            <b fp-translate>MyApp.Deactivate</b>
        </kendo-dialog-titlebar>

        <div class="row" *ngIf="inError">
            <div class="col-md-12">
                <div class="alert alert-danger" role="alert">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-10">
                <label class="control-label green" fp-translate>Users.User_Deactivate_Warning</label>
            </div>
        </div>
        <kendo-dialog-actions>
            <button kendoButton primary="true" type="button" (click)="deactivateUser()">
                <span fp-translate>MyApp.Yes</span>
            </button>
            <button kendoButton type="button" (click)="closeDialog()">
                <span fp-translate>MyApp.No</span>
            </button>
        </kendo-dialog-actions>
    </kendo-dialog>

    <kendo-dialog *ngIf="dialogArchiveIsVisible"
                  (close)="closeDialog()"
                  [width]="500">
        <kendo-dialog-titlebar>
            <b fp-translate>MyApp.Archive</b>
        </kendo-dialog-titlebar>

        <div class="row" *ngIf="inError">
            <div class="col-md-12">
                <div class="alert alert-danger" role="alert">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-10">
                <label class="control-label green" fp-translate>Users.User_Archive_Warning</label>
            </div>
        </div>
        <kendo-dialog-actions>
            <button kendoButton primary="true" type="button" (click)="archiveUser()">
                <span fp-translate>MyApp.Yes</span>
            </button>
            <button kendoButton type="button" (click)="closeDialog()">
                <span fp-translate>MyApp.No</span>
            </button>
        </kendo-dialog-actions>
    </kendo-dialog>


    <kendo-dialog *ngIf="dialogAddUserApplicationRoleIsVisible"
                  (close)="closeDialog()"
                  [width]="650">
        <kendo-dialog-titlebar>
            <b fp-translate>MyApp.User_Add_ApplicationRole</b>
        </kendo-dialog-titlebar>

        <div class="row" *ngIf="inError">
            <div class="col-md-12">
                <div class="alert alert-danger" role="alert">

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <fp-label label="Users.LuApplicationRole_Id"></fp-label>
            </div>
            <div class="col-md-9">
                <fp-dropdown name="Users.LuApplicationRole_Id"
                             [(ngModel)]="luApplicationRoleIdToAdd"
                             [(data)]="luApplicationRoleDropdownData"
                             valueField="id"
                             textField="$$displayName"
                             required="true"
                             [showDefaultItem]="true"></fp-dropdown>
            </div>
        </div>

        <kendo-dialog-actions>
            <button kendoButton primary="true" type="button" (click)="saveUserApplicationRoleCreate()" [disabled]="!luApplicationRoleIdToAdd">
                <span fp-translate>MyApp.Save</span>
            </button>
            <button kendoButton type="button" (click)="closeDialog()">
                <span fp-translate>MyApp.Cancel</span>
            </button>
        </kendo-dialog-actions>
    </kendo-dialog>

    <div class="row">
        <div class="col-md-12">
            &nbsp;
        </div>
    </div>
    <!--debug info-->
    <fpDebugDisplay [isDebug]="isDebug">
        <div class="row">
            <div class="col-md-12">
                <pre>{{data | json}}</pre>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <b>isInternalUser: </b>{{isInternalUser}}
            </div>
        </div>
    </fpDebugDisplay>
</form>