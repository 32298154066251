import { Injectable } from "@angular/core";
import { ConfigurationService } from "./configuration.service";

/**
 * Log messages to the browser console if available.
 */
@Injectable()
export class LogService {
    constructor(private configuration: ConfigurationService) {
        
    }

    private noop() { }

    /**
     * Write log message to the console.
     * @returns {} 
     */
    get log(): (arg1: any, ...arg2: any[]) => void {
        if (console && this.configuration.isDebug()) {
            return console.log.bind(window.console);
        } else {
            return this.noop;
        }
    }

    get isInfoEnabled(): boolean {
        return (console && this.configuration.isDebug());
    }

    /**
     * Write info message to the console.
     * @returns {} 
     */
    get info(): (arg1: any, ...arg2: any[]) => void {
        if (console && this.configuration.isDebug()) {
            return console.info.bind(window.console);
        } else {
            return this.noop;
        }
    }

    /**
     * Write warning message to the console.
     * @returns {} 
     */
    get warn(): (arg1: any, ...arg2: any[]) => void {
        if (console && this.configuration.isDebug()) {
            return console.warn.bind(window.console);
        } else {
            return this.noop;
        }
    }

    /**
     * Write error message to the console.
     * @returns {} 
     */
    get error(): (arg1: any, ...arg2: any[]) => void {
        if (console) {
            return console.error.bind(window.console);
        } else {
            return this.noop;
        }
    }
}