import { Component, Input, Output, EventEmitter, OnInit, Optional, Self } from "@angular/core";
import { ControlValueAccessor, NgModel } from "@angular/forms";

@Component({
    selector: "fp-checkbox",
    templateUrl: "./fpCheckbox.component.html",
    host: { 'class': 'fp-control' }
})

export class FpCheckboxComponent implements ControlValueAccessor, OnInit {

    @Input() name: string;
    @Input() localId: string;
    @Input() label: string;
    @Input() readMode: boolean = false;
    @Input() hideLabel: boolean = false;

    @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    private innerValue: boolean;
    private controlId: string = "";

    public model: NgModel;

    constructor(
        @Optional() @Self() ngModel: NgModel | null) {
        this.model = ngModel;

        // Set the valueAccessor manually.
        // Before this was done by providing the component as NG_VALUE_ACCESSOR,
        //    but this causes a circular import when injecting NgModel in the constructor.
        if (this.model) {
            this.model.valueAccessor = this;
        }
    }

    onChange(_): void { }

    onTouched(): void { }

    ngOnInit(): void {
        this.controlId = this.name.replace('.', '_');
        if (this.localId != undefined) {
            this.controlId = this.controlId + "|" + this.localId;
        }
    }

    //get accessor
    get value(): boolean {
        if (this.innerValue === null || this.innerValue === undefined) {
            return null;
        }

        return this.innerValue;
    };

    //set accessor including call the onchange callback
    set value(v: boolean) {
        if (v !== this.innerValue) {
            this.innerValue = v;

            if (this.onChange) {
                this.onChange(v);
            }

            if (this.toggle) {
                this.toggle.emit(v);
            }
        }
    }

    writeValue(value: boolean): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    onBlur() {
        if (this.onTouched) {
            this.onTouched();
        }
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}