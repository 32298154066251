import { OnInit, Component, ViewChild } from "@angular/core";
import { Params } from "@angular/router";
import { BaseRouteDependencies, BaseRouteDepenciesFactory, baseRouteDeps } from "../base/baseRouteDependencies.provider";
import { BaseRouteComponent } from "../base/baseRoute.component";
import { IOrganisationGroupData } from "../../services/mainContext.service";
import { DataTask } from "../../classes/dataTask"
import { DateUtils } from "../../utilities/dateUtils"

import { FpGridBindingDirective } from "../../modules/shared/directives/fpGridBinding.directive";
import { ActivateMenuOptions } from "../../../../ScriptsModels/MenuItem";
import { Utils } from "../../utilities/utils";
import { UserRepository } from "../../repositories/user.repository";

import FpCore = FostPlus.Olympus.CoreDomain.Clients.Api;
import UserContract = FostPlus.Olympus.UsersDomain.Clients.Api;
import PartyContract = FostPlus.Olympus.PartyDomain.Clients.Api;
import { ClipboardService } from "ngx-clipboard";
import { AuthenticationService } from "../../services/authentication.service";
import { FeedbackMessage, FeedbackMessageType } from "../../classes/errorHandling";
import { ItsMeRepository } from "../../repositories/itsMe.repository";

interface IExtUserSelfServiceDto extends UserContract.IUserSelfServiceDto {
    $$luLanguageName: string;
    $$luGenderName: string;

    $$activationLink: string;
    $$activationKeyValidToDate: Date;
}

interface IUserDetailData {
    user: IExtUserSelfServiceDto;
}

@Component({
    templateUrl: "./userprofileDetail.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})

export class UserprofileDetailComponent extends BaseRouteComponent<IUserDetailData> implements OnInit {
    @ViewChild("gridBinding", { static: true }) private gridBinding: FpGridBindingDirective;
    constructor(
        baseRouteDeps: BaseRouteDependencies,
        private authenticationService: AuthenticationService,
        private userRepository: UserRepository,
        private itsMeRepo: ItsMeRepository    ) {
        super("UserprofileDetailComponent", baseRouteDeps);

        //this.activateMenuOptions = new ActivateMenuOptions("mnuUsers");
    }
    public luLanguages: Array<FpCore.ILuLanguageTrInfoDto>;
    private _luLanguagesDictionary: { [id: number]: FpCore.ILuLanguageTrInfoDto };

    public luGenders: Array<FpCore.ILuGenderTrInfoDto>;
    public itsmeUsers: Array<UserContract.IItsmeUserDto>;

    public hide: boolean = false;

    public dialogIsVisible: boolean = false;
    public dialogDeactivateIsVisible: boolean = false;

    public isInternalUser: boolean;

    public returnUrl: string;
    public queryStringError: string;

    public itsmeChallengeUrl: string;

    protected configure() {
        this.dataLoaded = () => {
            this.clearFeedback();
            this.extendUserData();
            this.hide = false;

            // Show error passed in the queryString.
            // This happens when an error occurs during coupling with ItsMe
            if (this.queryStringError) {
                this.errors.push(new FeedbackMessage(this.queryParams['error'], FeedbackMessageType.Error));
                this.queryStringError = null;
            }
        };

        this.dataLoadFailed = (errors) => {
            this.hide = true;
        }

        //this.processParameters();

        this.itsmeChallengeUrl = this.baseRouteDeps.configuration.appSettings.itsmeChallengeUrl;

        this.data = this.getEmptyDetailData();

        // dataTasks
        this.addDataTask(new DataTask(this.loadUserData, this.processUserData, { reloadOnCultureChange: false, reload: true }));
        this.addDataTask(new DataTask(this.loadLuLanguageData, this.processLuLanguageData, { reloadOnCultureChange: true, reload: false }));
        this.addDataTask(new DataTask(this.loadGenderData, this.processGenderData, { reloadOnCultureChange: true, reload: false }));
        this.addDataTask(new DataTask(this.loadItsmeUserData, this.processItsmeUserData, { reloadOnCultureChange: true, reload: true }));
    }

    protected handleOrganisationGroupContextChanged(data: IOrganisationGroupData) {
        this.reload();
    }

    protected queryParamsUpdated(params: Params): void {
        this.processParameters();
    }

    private processParameters() {
        var url = this.queryParams['returnUrl'];
        if (url) {
            this.returnUrl = url;
        }

        if (this.queryParams['error']) {
            this.queryStringError = this.queryParams['error'];
        }
    }

    private extendUserData(): void {
        if (this.data.user.activationKey) {
            var baseUri = this.baseRouteDeps.configuration.appSettings.linkToUser;
            var langCode = this._luLanguagesDictionary[this.data.user.luLanguageId].parentLuLanguageCode.toLowerCase();
            this.data.user.$$activationLink = baseUri + '/#/activate?locale=' + langCode + '&key=' + this.data.user.activationKey;
            this.data.user.$$activationKeyValidToDate =
                DateUtils.NotValidOnOrAfterToValidUntil(this.data.user.activationKeyNotValidOnOrAfterDateTime);
        }

        this.updateUserRights();
    }

    public save() {
        this.clearFeedback();

        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (this.isFormValid() && ogData.organisationGroupId != null) {
            this.startBlocking();

            this.data.user.organisationGroupId = ogData.organisationGroupId;

            var request: UserContract.IUserprofileSelfServiceUpdateRequest = {
                user: this.data.user,
                organisationGroupId: ogData.organisationGroupId
            };

            this.userRepository.userprofileUpdate(request).then((result) => {
                this.refreshData(result.user, this.processUser, true);
                this.showSaveConfirmation();
                this.stopBlocking();
                this.scrollToTop();
            }).catch((error) => {
                this.handleFeedback(error, request);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    public deleteItsmeUser(itsmeUserId: string) {
        this.clearFeedback();

        var user = this.authenticationService.userIdentity;
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (ogData.organisationGroupId != null) {
            this.startBlocking();

            var request: UserContract.IUserItsmeUserSelfServiceDeleteRequest = {
                itsmeUserId: itsmeUserId,
                organisationGroupId: ogData.organisationGroupId,
                userId: user.id
            };

            this.userRepository.userItsmeUserSelfServiceDelete(request).then((result) => {
                this.reload();
                this.stopBlocking();
                this.scrollToTop();
            }).catch((error) => {
                this.handleFeedback(error, request);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    private getEmptyDetailData(): IUserDetailData {
        return {
            user: {
                id: null,
                windowsLogon: '',
                firstName: '',
                lastName: '',
                email: '',
                luGenderId: null,
                luLanguageId: null,
                organisationGroupId: null,
                isActive: false,
                isArchived: false,
                activationKey: '',
                activationKeyNotValidOnOrAfterDateTime: null,
                activatedOnDateTime: null,
                apiClientId: null,
                oktaApiClientId: null,
                links: [],
                rowVersion: [],
                $$activationLink: '',
                $$activationKeyValidToDate: null,
                $$luGenderName: '',
                $$luLanguageName: ''
            },
        }
    }

    public deactivate() {
        this.dialogDeactivateIsVisible = true;
    }

    public sendPasswordEmail(): void {
        this.clearFeedback();

        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (this.data && !this.data.user.isActive) {
            this.startBlocking();
            this.userRepository.createUserActivationEmail(this.data.user.id, ogData.organisationGroupId).then(
                result => {
                    this.showSaveConfirmation('MyApp.PasswordEmailSent', true);
                    this.stopBlocking();

                    this.reload();
                }).catch(error => {
                    this.handleFeedback(error);
                    this.stopBlocking();
                });
        }
    }

    public checkUsernameAlreadyExists(): void {
        this.clearFeedback();
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (this.data.user.windowsLogon && this.data.user.windowsLogon.length > 0 && ogData.organisationGroupId != null) {
            this.userRepository.checkUsernameAvailability(this.data.user.windowsLogon, ogData.organisationGroupId).then(result => {
                if (result.isAvailable) {
                    this.showWindowsLogonNotInUseSuccessToast();
                } else {
                    this.showWindowsLogonAlreadyExistsErrorToast();
                }
            }).catch(error => {
                this.handleFeedback(error);
            });
        }
    }

    public challengeItsMe() {
        
        this.itsMeRepo.prepareItsMeChallenge().then(() => {
            window.location.href = this.baseRouteDeps.configuration.appSettings.itsmeChallengeUrl;
        }).catch(error => {
            this.handleFeedback(error);
        });;
    }

    public historyBack() {
        if (this.returnUrl) {
            window.location.href = this.returnUrl;
        }
        else {
            super.historyBack();
        }
    }

    private showWindowsLogonAlreadyExistsErrorToast = () => {
        this.baseRouteDeps.translateService.get("Users.User_Validation_WindowsLogonNotUnique").subscribe((translation) => {
            this.baseRouteDeps.toastr.error(translation);
        });
    };

    private showWindowsLogonNotInUseSuccessToast = () => {
        this.baseRouteDeps.translateService.get("MyApp.WindowsLogonNotInUse").subscribe((translation) => {
            this.baseRouteDeps.toastr.success(translation);
        });
    };

    /* DataTask Funcs */
    private loadUserData(resolve: (data: UserContract.IUserprofileSelfServiceGetResponse) => void, reject: (reason: any) => void) {
        var user = this.authenticationService.userIdentity;
        this.isInternalUser = user.isInternalUser;
        //this.processParameters();
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();
        if (ogData.organisationGroupId) {

            this.userRepository.userprofileGet(user.id, ogData.organisationGroupId).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        }
        else {
            resolve(null);
        }
    }

    private processUserData(data: UserContract.IUserprofileSelfServiceGetResponse) {
        if (data) {
            this.processUser(data.user);
        } else {
            this.data = this.getEmptyDetailData();
        }
    }

    private processUser(data: UserContract.IUserSelfServiceDto) {
        this.data.user = <IExtUserSelfServiceDto>data;
    }

    private loadLuLanguageData(resolve: (data: Array<FpCore.ILuLanguageTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getLuLanguageTrsInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processLuLanguageData(data: Array<FpCore.ILuLanguageTrInfoDto>) {
        var languages = this.baseRouteDeps.lookupDataUtilities.filterCommunicationLanguages(data);
        this.luLanguages = languages;
        this._luLanguagesDictionary = this.baseRouteDeps.lookupDataUtilities.convertToDictionary(languages, 'id');
    }

    private loadGenderData(resolve: (data: Array<FpCore.ILuGenderTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getGendersInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processGenderData(data: Array<FpCore.ILuGenderTrInfoDto>) {
        this.luGenders = data;
    }

    private loadItsmeUserData(resolve: (data: UserContract.IUserItsmeUsersSelfServiceSearchResponse) => void, reject: (reason: any) => void) {
        var user = this.authenticationService.userIdentity;
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();
        if (ogData.organisationGroupId) {
            var request: UserContract.IUserItsmeUsersSelfServiceSearchRequest = {
                organisationGroupId: ogData.organisationGroupId,
                userId: user.id
            };

            this.userRepository.userItsmeUserSelfServiceSearch(request).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        }
        else {
            resolve(null);
        }
    }

    private processItsmeUserData(data: UserContract.IUserItsmeUsersSelfServiceSearchResponse) {
        if (data) {
            this.itsmeUsers = data.userItsmeUsers;
        }
    }
}
