import { Component, OnInit } from '@angular/core';
import { BaseRouteComponent } from "../base/baseRoute.component";
import { baseRouteDeps, BaseRouteDepenciesFactory, BaseRouteDependencies } from "../base/baseRouteDependencies.provider";

@Component({
    templateUrl: './oidc-logout.component.html',
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class OidcLogoutComponent extends BaseRouteComponent<any> implements OnInit {

    constructor(baseRouteDeps: BaseRouteDependencies) {
        super('OidcLogoutComponent', baseRouteDeps);
    }

    ngOnInit() {
        this.baseRouteDeps.logService.info('-- OidcLogoutComponent local logoff.');
        this.baseRouteDeps.authenticationService.localLogoff();
    }

}