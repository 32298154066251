import { Injectable } from '@angular/core';
import { Router, Route, CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { ConfigurationService } from "./configuration.service";
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import MyFost = FostPlus.MyFost.Web.Gui;

@Injectable()
export class DemoPagesGuardService implements CanActivate, CanLoad {
    constructor(private router: Router,
        private authenticationService: AuthenticationService,
        private configurationService: ConfigurationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.check();
    }

    canLoad(state: Route): Observable<boolean> {
        return this.check();
    }

    private check(): Observable<boolean> {
        return this.authenticationService.isAuthenticatedObservable
            .pipe(
                map(isAuthorized =>
                    isAuthorized && this.authenticationService.isInternalUser
                ),
                tap(isInternaluser => {
                    if (!isInternaluser) {
                        return false;
                    }

                    var appEnv = this.configurationService.getAppEnvironment();

                    if (appEnv == MyFost.Models.AppEnvironment.Dev || appEnv == MyFost.Models.AppEnvironment.Test) {
                        return true;
                    }

                    return false;
                }));
    }
}