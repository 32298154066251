import { Injectable, OnDestroy } from '@angular/core';
import { OidcSecurityService, AuthStateResult, PublicEventsService, EventTypes, ValidationResult, AuthenticatedResult, OidcClientNotification, LoginResponse } from 'angular-auth-oidc-client';
import { Observable, of, from, Subscription, BehaviorSubject, race } from 'rxjs';
import { tap, switchMap, filter, catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { UserRepository } from "../repositories/user.repository";
import { LogService } from './log.service';
import { Router } from '@angular/router';

import UserSecurityInfo = FostPlus.MyFost.Web.Gui.Models.IUserSecurityInfo;

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {

    private _userIdentity: UserSecurityInfo | null = null;

    private newAuthorizationResultSubject: BehaviorSubject<AuthStateResult | null> = new BehaviorSubject<AuthStateResult | null>(null);
    private oidcAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isAuthenticatedSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
    private isAuthorizedSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

    private authorizationResultSub: Subscription;
    private oidcAuthenticatedSub: Subscription;
    private isAuthenticatedSub: Subscription;

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private userRepository: UserRepository,
        private logService: LogService,
        private publicEventService: PublicEventsService,
        private router: Router
    ) {
        this.authorizationResultSub = this.publicEventService.registerForEvents()
            .pipe(
                tap((event: OidcClientNotification<any>) => this.logService.info('-- oidc event -- event.type: ' + event.type.toString() +
                    ', event value: ' + JSON.stringify(event.value))),
                // only interested in NewAuthorizationResult events
                tap((event: OidcClientNotification<any>) => {
                    if (event.type === EventTypes.NewAuthenticationResult) {
                        // emit the AuthorizationResult
                        this.newAuthorizationResultSubject.next(event.value as AuthStateResult ?? null);
                    } else if (event.type === EventTypes.CheckSessionReceived && event.value && event.value === 'changed') {
                        // checkSession returned 'changed'
                        this.logService.info('-- oidc CheckSessionReceived returned "changed".');
                        this.oidcSecurityService.logoffLocal();
                        this.oidcSecurityService.checkAuthIncludingServer();
                    }
                })
            )
            .subscribe();

        this.oidcAuthenticatedSub = this.oidcSecurityService.isAuthenticated$
            .pipe(
                distinctUntilChanged(),
                tap((result: AuthenticatedResult) => {
                    this.oidcAuthenticatedSubject.next(result.isAuthenticated)
                    this.logService.info('-- oidcSecurityService.isOidcAuthenticated changed. new value: ' + result.isAuthenticated);
                })
            )
            .subscribe();

        this.isAuthenticatedSub = race(
            // Wait until there is an AuthorizationResult
            // or until user is oidc authenticated
            this.newAuthorizationResultSubject.pipe(
                filter((result) => result !== null),
                tap((result) => this.logService.info('-- race -- newAuthorizationResultSubject emited: ' + JSON.stringify(result)))
            ),
            this.oidcAuthenticatedSubject.pipe(
                filter((isAuthenticated: boolean) => isAuthenticated === true),
                tap((result) => this.logService.info('-- race -- oidcAuthenticatedSubject emited: isAuthenticated = ' + result))
            )).pipe(
                // check if oidc authenticated
                switchMap(() => this.oidcAuthenticatedSubject),
                tap((result) => this.logService.info('-- oidcAuthenticatedSubject emited: isAuthenticated = ' + result)),
                switchMap((isAuthenticated: boolean) => {
                    if (isAuthenticated) {
                        return from(this.userRepository.getCurrentUser());
                    } else if (this._userIdentity) {
                        // User was authenticated but not anymore. (for example by logout in other tab, ...)
                        this.router.navigate(['/sessionExpired']);
                    }

                    return of(null);
                }),
                catchError((error) => {
                    this.logService.error('-- AuthenticationService error: ' + error);
                    return of(null);
                }),
                tap((result: UserSecurityInfo | null) => {
                    this.logService.info('-- new _userIdentity value: ' + JSON.stringify(result));
                    this._userIdentity = result;
                }),
                switchMap((userIdentity: UserSecurityInfo | null) => {
                    if (userIdentity != null /*&& userIdentity.hasAccess*/) {
                        this.authenticatedChanged(true, userIdentity);
                        return of(true);
                    }

                    this.authenticatedChanged(false, null);
                    return of(false);
                }),
                tap((result: boolean) => {
                    this.logService.info('-- AuthenticationService.isAuthenticatedSubject emited: ' + result);
                    this.isAuthenticatedSubject.next(result);
                    this.isAuthorizedSubject.next(result && this._userIdentity && this._userIdentity.hasAccess);

                })
            ).subscribe();
    }

    public checkAuth() {
        this.oidcSecurityService.checkAuth()
            .pipe(
                tap((result: LoginResponse) => this.logService.info('-- oidcSecurityService.checkAuth result: ' + result?.isAuthenticated)),
                filter((result: LoginResponse) => !result?.isAuthenticated),
                tap(() => {
                    if (window.location.hash.startsWith('#/oidc-logout')) {
                        this.logService.info('-- checkAuth: oidc-logout requested.');
                        this.newAuthorizationResultSubject.next({
                            isAuthenticated: false,
                            isRenewProcess: false,
                            validationResult: ValidationResult.NotSet
                        });
                    }
                    else {
                        this.logService.info('-- checkAuth: start authorize.');
                        this.oidcSecurityService.authorize();
                    }
                })
            )
            .subscribe();
    }

    public logoff() {
        if (this.isAuthenticated) {
            this._userIdentity = null;
            this.oidcSecurityService.logoff().subscribe();
        }
    }

    public localLogoff() {
        if (this.isAuthenticated) {
            this.oidcSecurityService.logoffLocal();
        }
    }

    /**
     * Get if the user is authenticated with the oidc provider.
     * 
     * @remarks This returns true one the user is authenticated in the oidc provider. 
     * This is before the user identity is know to the appication.
     * Should not be used in application logic!
     * 
     * @returns True when authenticated otherwise false.
     */
    public get isOidcAuthenticated(): boolean {
        return this.oidcAuthenticatedSubject.value ?? false;
    }

    /**
     * Get if the user is authenticated with the application
     * 
     * @remorks When true, the user identity should also be known to the application.
     * 
     * @returns True when authenticated otherwise false.
     */
    public get isAuthenticated(): boolean {
        return this.isAuthenticatedSubject.value ?? false;
    }

    public get isAuthenticatedObservable(): Observable<boolean> {
        return this.isAuthenticatedSubject
            .pipe(
                filter((isAuthenticated: boolean | null) => isAuthenticated !== null),
                map((isAuthenticated: boolean | null) => isAuthenticated as boolean)
            )
    }

    public get isAuthorizedObservable(): Observable<boolean> {
        return this.isAuthorizedSubject
            .pipe(
                filter((isAuthorized: boolean | null) => isAuthorized !== null),
                map((isAuthorized: boolean | null) => isAuthorized as boolean)
            )
    }

    public get userIdentity(): UserSecurityInfo | null {
        return this._userIdentity;
    }

    public get authorizationError(): string | null {
        const result = this.newAuthorizationResultSubject.value;

        if (result == null || result.isAuthenticated == true) {
            return null;
        }

        return 'Authorization validation Result: ' + result.validationResult;
    }


    public get isInternalUser(): boolean {
        let result = false;
        if (this._userIdentity
            && this._userIdentity.isInternalUser === true
            // Valipack is not a internal Fost Plus user. Should only have access to fostpack
            && this._userIdentity.subject.toLowerCase() !== 'valipac@fostplus.be@fostplus.be') {
            result = true;
        }
        return result;
    }

    public get isSubject(): string {
        let result = '';
        if (this._userIdentity) {
            result = this._userIdentity.subject;
        }
        return result;
    }

    public getToken(): Observable<string> {
        return this.oidcSecurityService.getAccessToken();
    }

    public get canTranslate(): boolean {
        return this._userIdentity ? this._userIdentity.canTranslate : false;
    }

    public get hasAccessToMultipleOrganisationGroups(): boolean {
        return this._userIdentity ? this._userIdentity.hasAccessToMultipleOrganisationGroups : false;
    }

    public get hasMemberServiceProviderOnboarding(): boolean {
        return this._userIdentity ? this._userIdentity.hasMemberServiceProviderOnboarding : false;
    }

    private authenticatedChanged(authenticated: boolean, userIdentity: null | UserSecurityInfo) {
        let userId = '';
        let orgGroupId = '';

        if (authenticated && userIdentity) {
            userId = userIdentity.id.toString();
            if (userIdentity.organisationGroupId) {
                orgGroupId = userIdentity.organisationGroupId.toString();
            }
        }
    }

    ngOnDestroy() {
        if (this.isAuthenticatedSub) {
            this.isAuthenticatedSub.unsubscribe();
        }

        if (this.oidcAuthenticatedSub) {
            this.oidcAuthenticatedSub.unsubscribe();
        }

        if (this.authorizationResultSub) {
            this.authorizationResultSub.unsubscribe();
        }
    }
}