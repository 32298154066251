<div class="container container-fluid container-full">
    <fp-nav></fp-nav>
</div>

<div id="page" class="container container-fluid container-full">
    <div id="main">
        <router-outlet></router-outlet>
    </div>
    <!--End main-->
    <div id="footer" *ngIf="!mainContextService.isFullScreen">
        <div class="colofon">
            Fost Plus - {{appName}}
        </div>
    </div>
</div>

<kendo-dialog *ngIf="isAuthenticationDialogOpen" [minWidth]="250" [width]="450">
    <p style="margin: 30px; text-align: center;">Authenticating...</p>
</kendo-dialog>

<fp-modal-dialog #leavePageDialog header="MyApp.LeavePageTitle"
                 content="MyApp.LeavePage"
                 (result)="leavePageDialogResult($event)">
</fp-modal-dialog>
