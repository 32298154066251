import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LeavePageService {

    constructor() { }

    private dialogOpenSubject = new Subject<any>();
    public dialogOpenObs = this.dialogOpenSubject.asObservable();

    public leavePageChoiceSubject = new Subject<boolean>();

    public callDialogOpen(): void {
        this.dialogOpenSubject.next({});
    }

    callLeavePageChoice(result: boolean): void {
        this.leavePageChoiceSubject.next(result);
    }
}
