<!-- User context -->
<div class="context-user">
    <!-- OrganisationGroup name -->
    <span *ngIf="isOrganisationGroupDataLoaded"
          title="{{ mainContextService.orgGroup?.name }}"
          class="control-ellipsis">{{ mainContextService.orgGroup?.name }}</span>
    <!-- Partner/Member dropdown -->
    <kendo-dropdownlist *ngIf="isOrganisationGroupDataLoaded"
                        [data]="mainContextService.orgGroup?.parties"
                        [(ngModel)]="mainContextService.selectedSiteId"
                        class="context-dropdown"
                        valuePrimitive="true"
                        textField="name"
                        valueField="id"></kendo-dropdownlist>
    <!-- Contract selector -->
    <span *ngIf="isContractSelectorAvailable">
        <fa-icon icon="exchange-alt" class="hand" routerLink="/init">
        </fa-icon>
    </span>
    <!-- Username -->
    <span *ngIf="isAuthenticatedObservable | async" class="hand username" (click)="navigateUserprofile()">
        <span class="vertical-align-middle">{{getUserName()}}</span>
        <fa-icon icon="user"></fa-icon>
    </span>
    <span *ngIf="isAuthenticatedObservable | async">|</span>
    <!-- Login/Logout -->
    <span class="hand" *ngIf="!(isAuthenticatedObservable | async)">
        <a (click)="login()"
           class="vertical-align-middle"
           tabindex="-1"
           fp-translate>Users.Login</a>
    </span>
    <span class="hand" *ngIf="isAuthenticatedObservable | async">
        <a (click)="logout()"
           class="vertical-align-middle"
           tabindex="-1"
           fp-translate>Users.Logout</a>
    </span>
    <!-- Fullscreen icon -->
    <div class="full-screen-icon" (click)="mainContextService.toggleFullScreen()">
        <fa-icon icon="expand" class="hand" *ngIf="!mainContextService.isFullScreen" size="1x"></fa-icon>
        <fa-icon icon="compress" class="hand" *ngIf="mainContextService.isFullScreen" size="1x"></fa-icon>
    </div>
</div>
<!-- Language selection context -->
<ul class="context-language">
    <li><span [ngClass]="isActive('nl')" (click)="changeLanguage('nl')">NL</span></li>
    <li><span class="" [ngClass]="isActive('fr')" (click)="changeLanguage('fr')">FR</span></li>
    <li><span class="" [ngClass]="isActive('en')" (click)="changeLanguage('en')">EN</span></li>
    <li><span *ngIf="canTranslate" class="noOutline" [ngClass]="translationKeysActive()" (click)="toggleTranslationKeys()" tabindex="-1">VERTAAL</span></li>
</ul>
