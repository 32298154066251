<div class="row">
    <div class="col-md-12">
        <h2 class="text-center" fp-translate>MyApp.SessionExpired_Title</h2>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <p class="text-center">
            <span fp-translate>MyApp.SessionExpired_InfoText</span>&nbsp;<a class="hand" (click)="logon()" fp-translate>MyApp.ClickHere</a>&nbsp;<span fp-translate>MyApp.SessionExpired_LoginText</span>&nbsp;
        </p>
    </div>
</div>
