import { Component, ViewChild } from "@angular/core";
import { BaseRouteComponent } from "../base/baseRoute.component";
import { baseRouteDeps, BaseRouteDepenciesFactory, BaseRouteDependencies } from "../base/baseRouteDependencies.provider";
import { IOrganisationGroupData, MainContextService } from "../../services/mainContext.service";
import { DataTask } from "../../classes/dataTask";
import Users = FostPlus.Olympus.UsersDomain.Clients.Api;
import CoreDomain = FostPlus.Olympus.CoreDomain.Clients.Api;
import { UserRepository } from "../../repositories/user.repository";
import { Params } from "@angular/router";
import { FpModalDialogComponent } from "../../modules/shared/components/forms/fpModalDialog.component";
import { Utils } from "../../utilities/utils";
import { ActivateMenuOptions } from "../../../../ScriptsModels/MenuItem";

import PartyContract = FostPlus.Olympus.PartyDomain.Clients.Api;
import { IExtUserAccessSelfServiceDto, IExtPartyInfoDto } from "./userDetail.component";
import { mnuItemsNames } from "../../services/menu.service";


interface IExtUserSelfServiceDto extends Users.IUserSelfServiceDto {
    $$languageName: string;
    $$genderName: string;
    $$genderSalutationShortName: string;
}

interface IExtUserAccessResourceActionDto extends Users.IUserAccessSelfServiceDtoUserAccessResourceActionDto {
    $$localId: number;
    $$actionName: string;
    $$resourceName: string;
}


interface IUserAccessDetailData {
    user: IExtUserSelfServiceDto;
    userAccess: IExtUserAccessSelfServiceDto;
}

@Component({
    templateUrl: "./userAccessDetail.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class UserAccessDetailComponent extends BaseRouteComponent<IUserAccessDetailData> {
    @ViewChild("removeDialog", { static: true }) private removeDialog: FpModalDialogComponent;

    constructor(baseRouteDeps: BaseRouteDependencies,
        private userRepository: UserRepository) {
        super("UserAccessDetailComponent", baseRouteDeps);

        this.activateMenuOptions = new ActivateMenuOptions(mnuItemsNames.users);
        
        this.addDataTask(new DataTask(this.loadLanguageData, this.processLanguageData, { reloadOnCultureChange: true, reload: false }));
        this.addDataTask(new DataTask(this.loadLuPartyTypeData, this.processLuPartyTypeData, { reloadOnCultureChange: true, reload: false }));
        this.addDataTask(new DataTask(this.loadUserAccessData, this.processUserAccessData, { reloadOnCultureChange: false, reload: true }));
        this.addDataTask(new DataTask(this.loadGenderData, this.processGenderData, { reloadOnCultureChange: true, reload: false }));
        this.addDataTask(new DataTask(this.loadActionData, this.processActionData, { reloadOnCultureChange: true, reload: false }));
        this.addDataTask(new DataTask(this.loadResourceData, this.processResourceData, { reloadOnCultureChange: true, reload: false }));
    }

    public allRowsSelected: boolean = false;
    public hasRowSelected: boolean = false;

    public canEdit: boolean = false;
    public canDelete: boolean = false;

    private _luLanguageDictionary: { [id: number]: CoreDomain.ILuLanguageTrInfoDto };
    private _luGenderDictionary: { [id: number]: CoreDomain.ILuGenderTrInfoDto };
    private _luPartyTypeDictionary: { [id: number]: PartyContract.ILuPartyTypeTrInfoDto };

    private _hasAccessDictionary: { [id: number]: Users.IUserAccessDto };
    private _luActionDictionary: { [id: number]: Users.ILuActionTrInfoDto };
    private _luResourceDictionary: { [id: number]: Users.ILuResourceTrInfoDto };


    private _gender: Array<CoreDomain.ILuGenderTrInfoDto> = [];
    private _languages: Array<CoreDomain.ILuLanguageTrInfoDto> = [];

    private _userAccessId: string = "";
   
    protected configure() {
        this.dataLoaded = () => {
            this.extendData();
        }

        this.data = {
            user: null,
            userAccess: null,
        }; 
    }

    protected handleOrganisationGroupContextChanged(data: IOrganisationGroupData) {
        this.clearFeedback();
        this.reload();
    }

    protected routeParamUpdated(params: Params): void {
        this.clearFeedback();
        this.reload();
    }

    protected updateUserRights() {
        this.canEdit = false;
        this.canDelete = false;

        if (this.data && this.data.userAccess) {
            this.canEdit = Utils.hasLink(this.data.userAccess, "Write");
            this.canDelete = Utils.hasLink(this.data.userAccess, "Delete");
        }
    }

    private extendData(): void {
        if (this.data.user != null) {
            var language = this._luLanguageDictionary[this.data.user.luLanguageId];
            var gender = this._luGenderDictionary[this.data.user.luGenderId];
            if (language) {
                this.data.user.$$languageName = language.name;
            }
            if (gender) {
                this.data.user.$$genderName = gender.name;
                this.data.user.$$genderSalutationShortName = gender.salutationShortName;
            }
        }

        if (this.data.userAccess != null) {
            this.data.userAccess.$$party = <IExtPartyInfoDto>this.data.userAccess.party;
            this.data.userAccess.$$party.$$luPartyTypeName = this._luPartyTypeDictionary[this.data.userAccess.party.luPartyTypeId].name;

            this.data.userAccess.userAccessResourceActions.forEach((uara, i) => {
                var extended: IExtUserAccessResourceActionDto = <IExtUserAccessResourceActionDto>uara;
                var action = this._luActionDictionary[uara.luActionId];
                var resource = this._luResourceDictionary[uara.luResourceId];
                if (action) {
                    extended.$$actionName = action.name;
                }
                if (resource) {
                    extended.$$resourceName = resource.name;
                }

                extended.$$localId = i + 1;
            });

            this.checkAllSelected(); 

            this.updateUserRights();
        }
    }

    private loadUserAccessData(resolve: (data: Users.IUserAccessSelfServiceGetResponse) => void, reject: (reason: any) => void) {
        this._userAccessId = this.params["id"];
        var ogd = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (ogd.organisationGroupId != null) {
            this.userRepository.userAccessSelfServiceGet(this._userAccessId, ogd.organisationGroupId).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        } else {
            resolve(null);
        }
        
    }

    public openDialog() {
        this.removeDialog.showDialog();
    }

    public deleteAuthorizations(dialogResult: boolean): void {
        this.clearFeedback();
        if (dialogResult && this.isFormValid() && !this.isBlocking()) {
            this.startBlocking();
            var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

            var request: Users.IUserAccessSelfServiceDeleteRequest = {
                organisationGroupId: ogData.organisationGroupId,
                userAccess: {
                    id: this.data.userAccess.id,
                    rowVersion: this.data.userAccess.rowVersion,
                    
                }                
            };
            this.userRepository.deleteUserAccessSelfService(request)
                .then((results) => {
                    this.showSaveConfirmation();
                    this.stopBlocking();
                    this.historyBack();
                })
            .catch ((result) => {
                this.handleFeedback(result);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    public saveChanges(): void {

        var ogd = this.baseRouteDeps.mainContextService.organisationGroupData();

        if (!this.isBlocking() && ogd.organisationGroupId != null) {
            this.startBlocking();

            var request: Users.IUserAccessSelfServiceUpdateRequest = {
                userAccess: this.data.userAccess,
                organisationGroupId: ogd.organisationGroupId
            }

            this.userRepository.UpdateUserAccessSelfService(request)
            .then((result) => {
                this.refreshData(result.userAccess, this.processUserAccess, true);
                this.showSaveConfirmation();
                this.stopBlocking();
                this.scrollToTop();
            }).catch ((errors) => {
                this.handleFeedback(errors, request);
                this.stopBlocking();
                this.scrollToTop();
            });
        }
    }

    public selectAllRows() {
        if (this.allRowsSelected) {
            this.data.userAccess.userAccessResourceActions.forEach(wt => {
                wt.hasAccess = true;
            });
        } else {
            this.data.userAccess.userAccessResourceActions.forEach(wt => {
                wt.hasAccess = false;
            });
        }

        this.checkAllSelected();
    }

    private checkAllSelected(): void {
        var count = this.getSelectedRowCount();
        this.hasRowSelected = count > 0;
        this.allRowsSelected = count > 0 && count === this.data.userAccess.userAccessResourceActions.length;
    }

    private getSelectedRowCount(): number {
        return this.data.userAccess.userAccessResourceActions.filter(d => d.hasAccess).length;
    }

    public hasAccessChange(uara: IExtUserAccessResourceActionDto) {
        // get the read uara from the same resource
        var readUara = this.data.userAccess.userAccessResourceActions
            .find(x => x.luResourceId == uara.luResourceId
                && x.luActionId == Users.LuActionId.Read);

        // get the write uara from the same resource
        var writeUara = this.data.userAccess.userAccessResourceActions
            .find(x => x.luResourceId == uara.luResourceId
                && x.luActionId == Users.LuActionId.Write);

        // get the validate uara from the same resource
        var validateUara = this.data.userAccess.userAccessResourceActions
            .find(x => x.luResourceId == uara.luResourceId
                && x.luActionId == Users.LuActionId.Validate);

        // If read is unchecked
        //  => make sure write & validate (same resource) are also unchecked
        if (uara.luActionId == Users.LuActionId.Read && uara.hasAccess == false) {
            if (writeUara) {
                writeUara.hasAccess = false;
            }
            if (validateUara) {
                validateUara.hasAccess = false;
            }
        }

        // If write or validate is checked
        //  => make sure read (same resource) is also checked
        if ((uara.luActionId == Users.LuActionId.Write || uara.luActionId == Users.LuActionId.Validate)
            && uara.hasAccess == true) {
            if (readUara) {
                readUara.hasAccess = true;
            }
        }

        this.checkAllSelected();
    }

    private processUserAccessData(data: Users.IUserAccessSelfServiceGetResponse) {        
        if (data) {
            this.processUserAccess(data.userAccess);
            this.data.user = <IExtUserSelfServiceDto>data.user;
        }
    }

    private processUserAccess(data: Users.IUserAccessSelfServiceDto) {
        this.data.userAccess = <IExtUserAccessSelfServiceDto>data;
    }

    private loadLuPartyTypeData(resolve: (data: Array<PartyContract.ILuPartyTypeTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getLuPartyTypeTrsInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processLuPartyTypeData(data: Array<PartyContract.ILuPartyTypeTrInfoDto>) {
        this._luPartyTypeDictionary = this.baseRouteDeps.lookupDataUtilities.convertToDictionary(data, 'id');
    }

    private loadLanguageData(resolve: (data: Array<CoreDomain.ILuLanguageTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getLuLanguageTrsInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processLanguageData(data: Array<CoreDomain.ILuLanguageTrInfoDto>) {
        this._luLanguageDictionary = this.baseRouteDeps.lookupDataUtilities.convertToDictionary(data, 'id');
        this._languages = data;
    }

    private loadGenderData(resolve: (data: Array<CoreDomain.ILuGenderTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getGendersInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processGenderData(data: Array<CoreDomain.ILuGenderTrInfoDto>) {
        this._luGenderDictionary = this.baseRouteDeps.lookupDataUtilities.convertToDictionary(data, 'id');
        this._gender = data;
    }   

    private loadActionData(resolve: (data: Array<Users.ILuActionTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getLuActionTrsInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processActionData(data: Array<Users.ILuActionTrInfoDto>) {
        this._luActionDictionary = this.baseRouteDeps.lookupDataUtilities.convertToDictionary(data, 'id');
    }
    private loadResourceData(resolve: (data: Array<Users.ILuResourceTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getLuResourceTrsInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processResourceData(data: Array<Users.ILuResourceTrInfoDto>) {
        this._luResourceDictionary = this.baseRouteDeps.lookupDataUtilities.convertToDictionary(data, 'id');
    }
}