<div class="row">
    <div class="col-xs-12">
        <header class="header-nav">
            <div class="row">
                <div class="col-xs-6 col-sm-3 col-md-2">
                    <!-- Logo -->
                    <h1 id="title" class="fp-logo" *ngIf="!mainContextService.isFullScreen">
                        <a href="#" tabindex="-1">{{ appName }}</a>
                    </h1>
                </div>

                <div class="col-xs-12 col-sm-9 col-md-10">
                    <div class="row">
                        <div class="col-xs-12">
                            <!-- App context -->
                            <fp-context></fp-context>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <!-- Navigation -->
                    <nav class="navigation">
                        <fp-menu></fp-menu>
                    </nav>
                </div>
            </div>
        </header>
    </div>
</div>
