import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { ConfigurationService } from "../services/configuration.service";

import { Observable } from 'rxjs';

import * as moment from "moment";

@Injectable()
export class RequestToJsonInterceptor implements HttpInterceptor {

    constructor(private config: ConfigurationService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.config.mustUseInterceptor(req.url)) {
            if (req.body != null) {
                var body = JSON.stringify(req.body, this.jsonReplacer);

                req = req.clone({
                    body: body,
                });
            }
        }

        return next.handle(req);
    }

    private jsonReplacer(name, value) {
        var that = this;

        if (name.substring(0, 2) === "$$") {
            return undefined;
        }

        if (value && that[name] instanceof Date) {
            let m = moment(that[name]);
            return m.format("YYYY-MM-DDTHH:mm:ss");
        }

        return value;
    }
}