import { TranslateDirective as BaseTranslateDirective, TranslateService } from "@ngx-translate/core";
import { Directive, ElementRef, Renderer2 , OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { LanguageService, IShowtranslationKeyChangeEvent } from '../../../services/language.service';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[fp-translate]',
})

export class FpTranslateDirective extends BaseTranslateDirective implements OnInit {
    private el: ElementRef;
    private originalTitle: string;

    private onShowTranslationChangeSub: Subscription;

    constructor(translateService: TranslateService,
        element: ElementRef,
        ref: ChangeDetectorRef,
        private render: Renderer2,
        private languageService: LanguageService) {
        super(translateService, element, ref);
        
        if (element.nativeElement && element.nativeElement.attributes) {
            this.el = element;

            if (element.nativeElement.attributes.getNamedItem("title")) {
                this.originalTitle = element.nativeElement.attributes.getNamedItem("title").value;
            }
        }
    }

    @Input("fp-translate")
    set fpTranslate(key: string) {
        this.translate = key;
    }

    //@Input() translateParams: any;

    ngOnInit() {
        // remove spacing from the text content
        var nodes = this.el.nativeElement.childNodes;
        if (nodes.length) {
            for (var i = 0; i < nodes.length; ++i) {
                var node = nodes[i];
                if (node.nodeType === 3) { // node type 3 is a text node
                    var content = node.textContent != undefined ? node.textContent : node.data;
                    content = content.trim();
                    if (node.textContent != undefined) {
                        node.textContent = content;
                    } else {
                        node.data = content;
                    }
                }
            }
        }


        this.onShowTranslationChangeSub = this.languageService.onShowTranslationChange.subscribe((event: IShowtranslationKeyChangeEvent) => {
            this.setTitle();
        });
    }

    ngAfterViewChecked() {
        super.ngAfterViewChecked();

        if (this.languageService.getShowTranslationKeys()) {
            this.setTitle();
        }
    }

    private setTitle() {
        if (this.languageService.getShowTranslationKeys()) {
            this.render.setAttribute(this.el.nativeElement, 'title', this.getKey());
        } else if (this.originalTitle) {
            this.render.setAttribute(this.el.nativeElement, 'title', this.originalTitle);
        } else {
            this.render.setAttribute(this.el.nativeElement, 'title', null);
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.onLangChangeSub) {
            this.onLangChangeSub.unsubscribe();
        }
    }

    private getKey(): string {
        if (this.key) {
            return this.key;
        } else {
            let nodes: NodeList = this.el.nativeElement.childNodes;

            for (let i = 0; i < nodes.length; ++i) {
                let node: any = nodes[i];
                if (node.nodeType === 3) { // node type 3 is a text node
                    
                    let content = node.textContent.trim();
                    if (content.length) {
                        if (content !== node.currentValue) {
                            return content;
                        } else if (node.originalContent) { // the content seems ok, but the lang has changed
                            // the current content is the translation, not the key, use the last real content as key
                            return node.originalContent.trim();
                        }
                    }
                }
            }
        }
    }
}