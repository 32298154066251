import { Component, Input, forwardRef, ViewChild, OnInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { MainContextService } from "../../../../services/mainContext.service";

@Component({
    selector: "fp-modal-dialog",
    templateUrl: "./fpModalDialog.component.html",
    host: { 'class': 'fp-control' }
})
export class FpModalDialogComponent {
    public isVisible: boolean = false;

    @Input() width: number | null = null;
    @Input() header: string = '';
    @Input() content: string = '';

    @Output() result: EventEmitter<boolean> = new EventEmitter();

    public showDialog() {
        this.isVisible = true;
    }

    public closeDialog() {
        if (this.result) {
            this.result.emit(false);
        }

        this.isVisible = false;
    }

    private confirm() {
        if (this.result) {
            this.result.emit(true);
        }

        this.isVisible = false;
    }
}