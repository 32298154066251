import { Injectable } from "@angular/core";
import { MenuItem, RouteMenuItem, UrlMenuItem, ActivateMenuOptions, MenuAudience } from "../../../ScriptsModels/MenuItem";
import { IOrganisationGroupData, MainContextService } from "./mainContext.service";
import { ConfigurationService } from "./configuration.service";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { SecurityService } from "./security.service";
import { LanguageService, ICultureChangedEvent } from "../../../ScriptsApp/app/services/language.service";
import { AuthenticationService } from "./authentication.service";
import { ActivatedRoute } from "@angular/router";

export interface ISubMenuVisibilityChangeEvent {
    subMenuVisisble: boolean;
}

export enum MenuType {
    myfost = "myfost",
    onboarding = "onboarding",
    partner = "partner",
    member = "member",
    labo = "labo"
}

export enum mnuItemsNames {
    home = "mnuHome",
    onboarding = "mnuOnboarding",
    users = "mnuUsers",
    help = "mnuHelp",
    demo = "mnuDemo",

    partnerHome = "mnuPartnerHome",
    partnerSettings = "mnuPartnerSettings",
    partnerHelp = "mnuPartnerHelp",

    memberHome = "mnuMemberHome",
    memberDeclarationPackagingSheet = "mnuMemberDeclarationPackagingSheet",
    memberDeclarationDeclaration = "mnuMemberDeclarationDeclaration",
    memberDeclarationMandate = "mnuMemberDeclarationMandate",
    memberDeclarationBaseData = "mnuMemberDeclarationBaseData",
    memberDeclarationMonitoring = "mnuMemberDeclarationMonitoring",
    memberDeclarationAdmin = "mnuMemberDeclarationAdmin",
    memberDeclarationAudit = "mnuMemberDeclarationAudit",
    memberServiceProvider = "mnuMemberServiceProvider",
    memberDeclarationSettings = "mnuMemberDeclarationSettings",
    memberTechnical = "mnuMemberTechnical",
    memberHelp = "mnuMemberHelp",

    laboHome = "mnuLaboHome",
    laboLab = "mnuLaboLab",
    laboBaseData = "mnuLaboBaseData",
    laboAudit = "mnuLaboAudit",
    laboSettings = "mnuLaboSettings",
    laboHelp = "mnuLaboHelp"
}

@Injectable()
export class MenuService {
    private authSubscription: Subscription;

    constructor(private mainContextService: MainContextService,
        private configurationService: ConfigurationService,
        private securityService: SecurityService,
        private languageService: LanguageService,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute
    ) {
        this.menuItemsCollection = new Array<MenuItem>();

        this.route.queryParams.subscribe(params => {
            // Defaults to 'myfost'
            this.menuType = MenuType.myfost;
            var mtParam = params['menuType'];
            if (mtParam) {
                var mt = MenuType[mtParam];
                // 'onboarding' is a special case, it can only be set by code (see refreshMenuItems)
                if (mt && mt !== MenuType.onboarding) {
                    this.menuType = mt;
                }
            }
        });

        this.createMenuItems();

        this.onCultureChangeSub = this.languageService.onCultureChange.subscribe(this.onCultureChanged);
        this.mainContextService.onOrganisationGroupContextChanged.subscribe(this.onOrganisationGroupContextChanged);

        this.activeMainMenu = null;

        this.securityService.onUserAccessRefresh.subscribe(this.onUserAccessRefreshed);
        this.updateUserRights();

        this.authSubscription = this.authenticationService.isAuthenticatedObservable.subscribe(
            (isAuthenticated: boolean) => {
                this.onAuthenticated(isAuthenticated);
            });
    }

    private menuType: MenuType = MenuType.myfost;

    private onCultureChangeSub: Subscription;

    private showSubMenus: boolean = false;

    private ogData: IOrganisationGroupData = null;

    private menuItemNamesMap: { [menuType: string]: Array<string> } = {
        [MenuType.myfost]: [
            mnuItemsNames.home,
            mnuItemsNames.users
        ],
        [MenuType.onboarding]: [
            mnuItemsNames.onboarding
        ],
        [MenuType.partner]: [
            mnuItemsNames.partnerHome,
            mnuItemsNames.partnerSettings,
            mnuItemsNames.users,
            mnuItemsNames.partnerHelp
        ],
        [MenuType.member]: [
            mnuItemsNames.memberHome,
            mnuItemsNames.memberDeclarationPackagingSheet,
            mnuItemsNames.memberDeclarationDeclaration,
            mnuItemsNames.memberDeclarationMandate,
            mnuItemsNames.memberDeclarationBaseData,
            mnuItemsNames.memberDeclarationMonitoring,
            mnuItemsNames.memberDeclarationAdmin,
            mnuItemsNames.memberDeclarationAudit,
            mnuItemsNames.memberServiceProvider,
            mnuItemsNames.memberDeclarationSettings,
            mnuItemsNames.users,
            mnuItemsNames.memberTechnical,
            mnuItemsNames.memberHelp
        ],
        [MenuType.labo]: [
            mnuItemsNames.laboHome,
            mnuItemsNames.laboLab,
            mnuItemsNames.laboBaseData,
            mnuItemsNames.laboAudit,
            mnuItemsNames.laboSettings,
            mnuItemsNames.users,
            mnuItemsNames.laboHelp
        ]
    };

    // MyFost menu items
    private mnuHome: RouteMenuItem;
    private mnuUsers: RouteMenuItem;
    private mnuDemo: RouteMenuItem;

    // MyFost Onboarding menu items
    private mnuOnboarding: RouteMenuItem;

    // Partner menu items
    private mnuPartnerHome: UrlMenuItem;
    private mnuPartnerSettings: UrlMenuItem;
    private mnuPartnerHelp: UrlMenuItem;

    // Member/MemberDeclaration menu items
    private mnuMemberHome: UrlMenuItem;
    private mnuMemberDeclarationPackagingSheet: UrlMenuItem;
    private mnuMemberDeclarationDeclaration: UrlMenuItem;
    private mnuMemberDeclarationMandate: UrlMenuItem;
    private mnuMemberDeclarationBaseData: UrlMenuItem;
    private mnuMemberDeclarationMonitoring: UrlMenuItem;
    private mnuMemberDeclarationAdmin: UrlMenuItem;
    private mnuMemberDeclarationAudit: UrlMenuItem;
    private mnuMemberServiceProvider: UrlMenuItem;
    private mnuMemberDeclarationSettings: UrlMenuItem;
    private mnuMemberTechnical: UrlMenuItem;
    private mnuMemberHelp: UrlMenuItem;

    // Labo menu items
    private mnuLaboHome: UrlMenuItem;
    private mnuLaboLab: UrlMenuItem;
    private mnuLaboBaseData: UrlMenuItem;
    private mnuLaboAudit: UrlMenuItem;
    private mnuLaboSettings: UrlMenuItem;
    private mnuLaboHelp: UrlMenuItem;

    private readonly menuItemsCollection: Array<MenuItem>;

    private activeMainMenu: MenuItem;

    private menuItemsSubject = new BehaviorSubject<Array<MenuItem>>([]);
    private adminMenuItemsSubject = new BehaviorSubject<Array<MenuItem>>([]);
    private subMenuItemsSubject = new BehaviorSubject<Array<MenuItem>>([]);
    private subMenuVisibleSubject = new BehaviorSubject<boolean>(false);

    get menuItems(): Observable<Array<MenuItem>> {
        return this.menuItemsSubject.asObservable();
    }

    get adminMenuItems(): Observable<Array<MenuItem>> {
        return this.adminMenuItemsSubject.asObservable();
    }

    get subMenuItems(): Observable<Array<MenuItem>> {
        return this.subMenuItemsSubject.asObservable();
    }

    get isSubMenuVisible(): Observable<boolean> {
        return this.subMenuVisibleSubject.asObservable();
    }

    public activate(options: ActivateMenuOptions) {
        this.activateMenuItem(options.name, options.showSubMenu);
    }


    public hideMenus(hide: boolean) {
        this.menuItemsCollection.forEach((item) => item.hide = hide);

        this.refresh();
    }

    public hideLinks(hide: boolean) {
        if (hide) {
            this.mnuHome.hide = true;
        }
    }

    private createMenuItems() {
        const links = {
            linkToPartner: this.configurationService.getLinkToPartner(),
            linkToFostPack: this.configurationService.getLinkToFostPack(),
            linkToMember: this.configurationService.getLinkToMember(),
            linkToLabo: this.configurationService.getLinkToLabo()
        };

        // Route '/init' for dashboard => see refreshMenuItems
        this.mnuHome = new RouteMenuItem(mnuItemsNames.home, "MyApp.Home", "/init");
        this.menuItemsCollection.push(this.mnuHome);

        this.mnuOnboarding = new RouteMenuItem(mnuItemsNames.onboarding, "MyApp.Home", "/registration");
        this.menuItemsCollection.push(this.mnuOnboarding);

        this.mnuUsers = new RouteMenuItem(mnuItemsNames.users, "MyApp.Users", "/user/search", [], true, MenuAudience.All, true);
        this.menuItemsCollection.push(this.mnuUsers);

        this.mnuDemo = new RouteMenuItem(mnuItemsNames.demo, "MyApp.Demo", "/demo/home", [], true, MenuAudience.OnlyForInternalUsers);
        this.menuItemsCollection.push(this.mnuDemo);

        // Partner menu items
        this.mnuPartnerHome = new UrlMenuItem(mnuItemsNames.partnerHome, "MyApp.Home", `${links.linkToPartner}/#/init?page=home`, false, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuPartnerHome);

        this.mnuPartnerSettings = new UrlMenuItem(mnuItemsNames.partnerSettings, "MyApp.Settings", `${links.linkToPartner}/#/init?page=settings`, true, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuPartnerSettings);

        this.mnuPartnerHelp = new UrlMenuItem(mnuItemsNames.partnerHelp, "MyApp.Help", "", true, MenuAudience.All, true);
        this.menuItemsCollection.push(this.mnuPartnerHelp);

        // Member/MemberDeclaration menu items
        this.mnuMemberHome = new UrlMenuItem(mnuItemsNames.memberHome, "MyApp.Home", `${links.linkToMember}/#/init?page=home`, false, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuMemberHome);

        this.mnuMemberDeclarationPackagingSheet = new UrlMenuItem(mnuItemsNames.memberDeclarationPackagingSheet, "MyApp.Menu_Packaging", `${links.linkToFostPack}/#/init?page=ps`, false, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuMemberDeclarationPackagingSheet);

        this.mnuMemberDeclarationDeclaration = new UrlMenuItem(mnuItemsNames.memberDeclarationDeclaration, "MyApp.Menu_Declarations", `${links.linkToFostPack}/#/init?page=decl`, false, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuMemberDeclarationDeclaration);

        this.mnuMemberDeclarationMandate = new UrlMenuItem(mnuItemsNames.memberDeclarationMandate, "MyApp.Menu_Mandates", `${links.linkToFostPack}/#/init?page=man`, false, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuMemberDeclarationMandate);

        this.mnuMemberDeclarationBaseData = new UrlMenuItem(mnuItemsNames.memberDeclarationBaseData, "MyApp.Menu_Rates", `${links.linkToFostPack}/#/init?page=baseData`, false, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuMemberDeclarationBaseData);

        this.mnuMemberDeclarationMonitoring = new UrlMenuItem(mnuItemsNames.memberDeclarationMonitoring, "MyApp.Menu_Monitoring", `${links.linkToFostPack}/#/init?page=monitoring`, true, MenuAudience.OnlyForInternalUsers, false, false, true);
        this.menuItemsCollection.push(this.mnuMemberDeclarationMonitoring);

        this.mnuMemberDeclarationAdmin = new UrlMenuItem(mnuItemsNames.memberDeclarationAdmin, "MyApp.Menu_Admin", `${links.linkToFostPack}/#/init?page=admin`, true, MenuAudience.OnlyForInternalUsers, false, false, true);
        this.menuItemsCollection.push(this.mnuMemberDeclarationAdmin);

        this.mnuMemberDeclarationAudit = new UrlMenuItem(mnuItemsNames.memberDeclarationAudit, "MyApp.Menu_Audit", `${links.linkToFostPack}/#/init?page=audit`, true, MenuAudience.OnlyForInternalUsers, false, false, true);
        this.menuItemsCollection.push(this.mnuMemberDeclarationAudit);

        this.mnuMemberServiceProvider = new UrlMenuItem(mnuItemsNames.memberServiceProvider, "MyApp.Menu_Members", `${links.linkToMember}/#/init?page=serviceProvider`, true, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuMemberServiceProvider);

        this.mnuMemberDeclarationSettings = new UrlMenuItem(mnuItemsNames.memberDeclarationSettings, "MyApp.Settings", `${links.linkToFostPack}/#/init?page=settings`, true, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuMemberDeclarationSettings);

        this.mnuMemberTechnical = new UrlMenuItem(mnuItemsNames.memberTechnical, "MyApp.Menu_Technical", `${links.linkToMember}/#/init?page=technical`, true, MenuAudience.OnlyForInternalUsers, false, true, true);
        this.menuItemsCollection.push(this.mnuMemberTechnical);

        this.mnuMemberHelp = new UrlMenuItem(mnuItemsNames.memberHelp, "MyApp.Help", "", true, MenuAudience.All, true);
        this.menuItemsCollection.push(this.mnuMemberHelp);

        // Labo menu items
        this.mnuLaboHome = new UrlMenuItem(mnuItemsNames.laboHome, "MyApp.Home", `${links.linkToLabo}/#/init?page=home`, false, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuLaboHome);

        this.mnuLaboLab = new UrlMenuItem(mnuItemsNames.laboLab, "MyApp.Menu_Lab", `${links.linkToLabo}/#/init?page=labAnalysisRequests`, false, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuLaboLab);

        this.mnuLaboBaseData = new UrlMenuItem(mnuItemsNames.laboBaseData, "MyApp.Menu_Rates", `${links.linkToLabo}/#/init?page=baseData`, false, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuLaboBaseData);

        this.mnuLaboAudit = new UrlMenuItem(mnuItemsNames.laboAudit, "MyApp.Menu_Audit", `${links.linkToFostPack}/#/init?page=audit`, true, MenuAudience.OnlyForInternalUsers, true, true);
        this.menuItemsCollection.push(this.mnuLaboAudit);

        this.mnuLaboSettings = new UrlMenuItem(mnuItemsNames.laboSettings, "MyApp.Settings", `${links.linkToLabo}/#/init?page=settings`, true, MenuAudience.All, false, true, true);
        this.menuItemsCollection.push(this.mnuLaboSettings);

        this.mnuLaboHelp = new UrlMenuItem(mnuItemsNames.laboHelp, "MyApp.Help", "", true, MenuAudience.All, true);
        this.menuItemsCollection.push(this.mnuLaboHelp);

        this.updateHelpMenusUrl();
    }

    public activateMenuItem(name: string, showSubMenu: boolean = false): void {
        // find menu to activate ( and de-activate other ones )
        var mainMenuItemToActivate: MenuItem = null;
        this.menuItemsCollection.forEach((mainMenuItem) => {
            var mustActivateMainMenu = false;
            if (mainMenuItem.name === name) {
                mustActivateMainMenu = true;
            } else {
                mainMenuItem.getSubMenuItems().forEach((subMenuItem) => {
                    if (subMenuItem.name === name) {
                        subMenuItem.isActive = true;
                        mustActivateMainMenu = true;
                    } else {
                        subMenuItem.isActive = false;
                    }
                });
            }

            if (mustActivateMainMenu) {
                mainMenuItemToActivate = mainMenuItem;
            } else {
                mainMenuItem.isActive = false;
            }
        });

        // found the menu to activate
        if (mainMenuItemToActivate) {
            mainMenuItemToActivate.isActive = true;

            this.activeMainMenu = mainMenuItemToActivate;
        } else {
            this.activeMainMenu = null;
        }

        this.mainContextService.refreshBodyOffset();

        this.refreshMenuItems(showSubMenu);
    }

    private onCultureChanged = (culture: ICultureChangedEvent) => {
        this.updateHelpMenusUrl();
        this.refreshMenuItems(this.showSubMenus);

        this.menuItemsCollection.forEach((menuItem) => {
            if (menuItem instanceof UrlMenuItem) {
                const urlMenuItem = menuItem as UrlMenuItem;
                if (urlMenuItem.isCultureAware) {
                    urlMenuItem.url = urlMenuItem.baseUrl;
                    if (culture.language) {
                        urlMenuItem.url = this.replaceLanguagePlaceholder(urlMenuItem.url, culture.language);
                    }
                }
            }
        });
    }

    private onOrganisationGroupContextChanged = (ogData: IOrganisationGroupData) => {
        this.menuItemsCollection.forEach((menuItem) => {
            if (menuItem instanceof UrlMenuItem) {
                const urlMenuItem = menuItem as UrlMenuItem;
                if (urlMenuItem.isOrgGroupContextAware) {
                    urlMenuItem.url = urlMenuItem.baseUrl;
                    if (ogData.organisationGroupId) {
                        urlMenuItem.url = urlMenuItem.url + '&orgGroupId=' + ogData.organisationGroupId;
                    }
                    if (ogData.selectedPartyId) {
                        urlMenuItem.url = urlMenuItem.url + '&partyId=' + ogData.selectedPartyId;
                    }
                }
            }
        });

        this.ogData = ogData;
        this.refreshMenuItems(this.showSubMenus);
    }

    private handleMenuUserVisibility(hide: boolean): void {
        const hasUserRead = this.securityService.hasUserRead();
        if (this.mnuUsers) {
            this.mnuUsers.hide = hide || !(hasUserRead || this.authenticationService.hasAccessToMultipleOrganisationGroups);
        }
    }

    private handleMenuServiceProviderVisibility(hide: boolean, orgGroupIsServiceProvider: boolean): void {
        if (this.mnuMemberServiceProvider) {
            this.mnuMemberServiceProvider.hide = hide || !orgGroupIsServiceProvider || !this.authenticationService.hasMemberServiceProviderOnboarding;
        }
    }

    private updateHelpMenusUrl() {
        var language = this.languageService.getLanguage();
        switch (language) {
            case "nl":
                this.mnuPartnerHelp.url = this.configurationService.getLinkToPartnerFAQ_Nl();
                this.mnuMemberHelp.url = this.configurationService.getLinkToMemberFAQ_Nl();
                this.mnuLaboHelp.url = this.configurationService.getLinkToLaboFAQ_Nl();
                break;
            case "fr":
                this.mnuPartnerHelp.url = this.configurationService.getLinkToPartnerFAQ_Fr();
                this.mnuMemberHelp.url = this.configurationService.getLinkToMemberFAQ_Fr();
                this.mnuLaboHelp.url = this.configurationService.getLinkToLaboFAQ_Fr();
                break;
            default:
                this.mnuPartnerHelp.url = this.configurationService.getLinkToPartnerFAQ_En();
                this.mnuMemberHelp.url = this.configurationService.getLinkToMemberFAQ_En();
                this.mnuLaboHelp.url = this.configurationService.getLinkToLaboFAQ_En();
                break;
        }
    }

    private replaceLanguagePlaceholder(value: string, language: string): string {
        return value.replace(":language", language);
    }

    private onAuthenticated(isAuthenticated: boolean) {
        this.refresh();
    }

    private refresh() {
        this.refreshMenuItems(this.showSubMenus);
    }

    private refreshMenuItems(showSubMenu: boolean) {
        var visibleItems: MenuItem[];
        var isForInternalUser = this.authenticationService.isInternalUser;

        var isOrgGroupSelected = this.ogData && this.ogData.organisationGroupId != null;
        var orgGroupIsServiceProvider = this.ogData && this.ogData.organisationGroupIsServiceProvider;

        // First hide all menu items
        this.menuItemsCollection.forEach((item) => item.hideMenu(true));

        // Check if menuType needs to become 'onboarding'
        if (this.menuType == MenuType.myfost && this.authenticationService.userIdentity) {
            if (false == this.authenticationService.userIdentity.isInternalUser
                && !this.authenticationService.userIdentity.organisationGroupId) {
                // its a user whom is going through the onboarding module
                this.menuType = MenuType.onboarding;
            }
        }

        // Get menu items to show per menu type (taking into account if an organisation group is selected or not)
        var menuItemNamesToShow = this.menuItemNamesMap[this.menuType];
        menuItemNamesToShow.forEach((menuItemName) => {
            var menuItem = this.menuItemsCollection.find((menuItem) => {
                return menuItem.name === menuItemName;
            });

            if (menuItem && (!menuItem.isOrgGroupRelated || isOrgGroupSelected)) {
                menuItem.hideMenu(false);
            }
        });

        this.handleMenuUserVisibility(!isOrgGroupSelected);
        this.handleMenuServiceProviderVisibility(!isOrgGroupSelected, orgGroupIsServiceProvider);

        visibleItems = this.menuItemsCollection.filter((menuItem) => {
            return menuItem.mustShow(isForInternalUser);
        });

        // Sort array based on the order of menuItemNamesToShow (=> menuItemNamesMap)
        visibleItems = visibleItems.sort((a, b) => menuItemNamesToShow.indexOf(a.name) - menuItemNamesToShow.indexOf(b.name));

        // Left menu
        var menuItems = visibleItems.filter((menuItems) => {
            return !menuItems.isAdminMenuItem;
        });

        this.menuItemsSubject.next(menuItems);

        // Right menu
        var adminItems = visibleItems.filter((item) => {
            return item.isAdminMenuItem;
        });

        this.adminMenuItemsSubject.next(adminItems);

        var subMenuItems: Array<MenuItem> = null;
        if (this.activeMainMenu && showSubMenu) {
            subMenuItems = this.activeMainMenu.getSubMenuItems().filter((menuItem) => {
                return menuItem.mustShow(isForInternalUser);
            });
        }

        if (subMenuItems && subMenuItems.length > 0) {
            this.subMenuItemsSubject.next(subMenuItems);
            this.subMenuVisibleSubject.next(true);
        } else {
            this.subMenuItemsSubject.next([]);
            this.subMenuVisibleSubject.next(false);
        }
    }

    private onUserAccessRefreshed = (event: boolean) => {
        var self = this;
        self.updateUserRights();
    }

    private updateUserRights() {
        var self = this;
        var t = "";

        this.refreshMenuItems(this.showSubMenus);
    }

    public resolveUrl(item: UrlMenuItem): string {
        var urlWithParam: string = item.url;

        if (urlWithParam && urlWithParam.length > 0) {
            // get all url parameters
            let urlParams = urlWithParam.split("/").filter((param: string) => {
                return param.startsWith(":");
            }).map((param: string) => {
                return param.substring(1);
            });

            if (urlParams.length > 0) {
                urlParams.forEach((param: string) => {
                    if (this.mainContextService.currentContext[param]) {
                        urlWithParam = urlWithParam
                            .replace(":" + param, this.mainContextService.currentContext[param]);
                    }
                });
            }
        }

        return urlWithParam;
    }

    public resolveRouteParams(item: RouteMenuItem): Object {
        var params: Object = {};

        item.params.forEach((param: string) => {
            params[param] = this.mainContextService.currentContext[param]
        });

        return params;
    }

    public resolveRoute(item: RouteMenuItem): Array<any> {
        var route: Array<any> = [];

        if (item.route && item.route.length > 0) {
            // get all url parameters
            let routeSegments = item.route.split("/");

            if (routeSegments.length > 0) {
                routeSegments.forEach((segment: string) => {
                    if (segment.startsWith(":") && this.mainContextService.currentContext[segment.substring(1)]) {
                        route.push(this.mainContextService.currentContext[segment.substring(1)]);
                    } else {
                        route.push(segment);
                    }
                });
            }
        }

        // following is needed when putting organisationGroup and selectedPartyId in url's
        //route.unshift(this.mainContextService.selectedPartyId.toString());
        //route.unshift(this.mainContextService.organisationGroupId.toString());
        return route;
    }
}
