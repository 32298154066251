import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";

export class FpMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        var parts;
        var translation = "";

        if (params.key) {
            if (params.key.indexOf("_") > -1) {
                parts = params.key.split("_");
            } else {
                parts = params.key.split(".");
            }
        }

        if (parts) {
            translation = "X_" + parts[parts.length - 1] + "_" + params.translateService.currentLang;
        }
        return translation;
    }
}