import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from "@angular/common/http";
import { HttpProvider } from '../services/http.provider';

import AmontContractDomain = FostPlus.Olympus.Amont.ContractDomain.Clients.Api;

export interface IContractRepository {
    forRegistrationStartDate(userPartyRegistrationId: string | null): Promise<AmontContractDomain.IMemberContractForRegistrationStartDateResponse>;
    forRegistrationStartDateUpdate(request: AmontContractDomain.IMemberContractForRegistrationStartDateUpdateRequest): Promise<AmontContractDomain.IMemberContractForRegistrationStartDateUpdateResponse>;
    forRegistrationActivity(userPartyRegistrationId: string | null): Promise<AmontContractDomain.IMemberContractForRegistrationActivityResponse>;
    forRegistrationActivityUpdate(request: AmontContractDomain.IMemberContractForRegistrationActivityUpdateRequest): Promise<AmontContractDomain.IMemberContractForRegistrationActivityUpdateResponse>;
    generateContractTemplate(language: string, isNaturalPerson: boolean, signatoryName: string, signatoryTitle: string, userPartyRegistrationId: string | null): Promise<AmontContractDomain.IContractForRegistrationTemplateGetResponse>;
    downloadContractTemplate(filename: string): Promise<HttpResponse<ArrayBuffer>>;
    downloadAttachment(attachmentKey: string, contractId: number, userPartyRegistrationId: string | null): Promise<HttpResponse<ArrayBuffer>>;
    downloadStartDateReasonAttachment(attachmentKey: string, memberContractId: number, userPartyRegistrationId: string | null): Promise<HttpResponse<ArrayBuffer>>;
}

@Injectable()
export class ContractRepository implements IContractRepository {
    static $inject = [
        '$resource',
        'rootUrl',
        '$window',
        '$q'
    ];

    constructor(private http: HttpProvider,
        @Inject('rootUrl') private rootUrl: string) {
    }

    forRegistrationStartDate(userPartyRegistrationId: string | null): Promise<AmontContractDomain.IMemberContractForRegistrationStartDateResponse> {
        const req: AmontContractDomain.IMemberContractForRegistrationStartDateRequest = {
            userPartyRegistrationId: userPartyRegistrationId
        };

        const url = "api/contracts/forRegistrationStartDate";
        return this.http.post<AmontContractDomain.IMemberContractForRegistrationStartDateResponse>(this.rootUrl + url, req);
    }

    forRegistrationStartDateUpdate(request: AmontContractDomain.IMemberContractForRegistrationStartDateUpdateRequest): Promise<AmontContractDomain.IMemberContractForRegistrationStartDateUpdateResponse> {
        const url = "api/contracts/forRegistrationStartDateUpdate";
        return this.http.post<AmontContractDomain.IMemberContractForRegistrationStartDateUpdateResponse>(this.rootUrl + url, request);
    }

    forRegistrationActivity(userPartyRegistrationId: string | null): Promise<AmontContractDomain.IMemberContractForRegistrationActivityResponse> {
        const req: AmontContractDomain.IMemberContractForRegistrationActivityRequest = {
            userPartyRegistrationId: userPartyRegistrationId
        };
        const url = "api/contracts/forRegistrationActivity";
        return this.http.post<AmontContractDomain.IMemberContractForRegistrationActivityResponse>(this.rootUrl + url, req);
    }

    forRegistrationActivityUpdate(request: AmontContractDomain.IMemberContractForRegistrationActivityUpdateRequest): Promise<AmontContractDomain.IMemberContractForRegistrationActivityUpdateResponse> {
        const url = "api/contracts/forRegistrationActivityUpdate";
        return this.http.post<AmontContractDomain.IMemberContractForRegistrationActivityUpdateResponse>(this.rootUrl + url, request);
    }

    forRegistrationComplete(request: AmontContractDomain.IMemberContractForRegistrationCompleteRequest): Promise<AmontContractDomain.IMemberContractForRegistrationCompleteResponse> {
        const url = "api/contracts/forRegistrationComplete";
        return this.http.post<AmontContractDomain.IMemberContractForRegistrationCompleteResponse>(this.rootUrl + url, request);
    }

    forRegistrationContract(userPartyRegistrationId: string | null): Promise<AmontContractDomain.IContractForRegistrationResponse> {
        const req: AmontContractDomain.IContractForRegistrationRequest = {
            userPartyRegistrationId: userPartyRegistrationId
        }
        const url = "api/contracts/forRegistrationContract";
        return this.http.post<AmontContractDomain.IContractForRegistrationResponse>(this.rootUrl + url, req);
    }

    forRegistrationContractUpdate(request: AmontContractDomain.IContractForRegistrationUpdateRequest): Promise<AmontContractDomain.IContractForRegistrationUpdateResponse> {
        const url = "api/contracts/forRegistrationContractUpdate";
        return this.http.post<AmontContractDomain.IContractForRegistrationUpdateResponse>(this.rootUrl + url, request);
    }

    generateContractTemplate(language: string, isNaturalPerson: boolean, signatoryName: string, signatoryTitle: string, userPartyRegistrationId: string | null): Promise<AmontContractDomain.IContractForRegistrationTemplateGetResponse> {
        var url = "api/contracts/template?language=" + language + "&isnaturalperson=" + isNaturalPerson + "&signatoryname=" + signatoryName + "&signatorytitle=" + signatoryTitle;

        if (userPartyRegistrationId) {
            url = url + "&userPartyRegistrationId=" + userPartyRegistrationId;
        }

        return this.http.get<AmontContractDomain.IContractForRegistrationTemplateGetResponse>(this.rootUrl + url);
    }

    downloadContractTemplate(filename: string): Promise<HttpResponse<ArrayBuffer>> {
        var url = "api/contracts/template/" + encodeURIComponent(filename);
        return this.http.getFile(this.rootUrl + url);
    }

    downloadAttachment(attachmentKey: string, contractId: number, userPartyRegistrationId: string | null): Promise<HttpResponse<ArrayBuffer>> {
        var url = "api/contracts/downloadContract/" + attachmentKey + "/" + contractId;

        if (userPartyRegistrationId) {
            url = url + "?userPartyRegistrationId=" + userPartyRegistrationId;
        }

        return this.http.getFile(this.rootUrl + url);
    }

    downloadStartDateReasonAttachment(attachmentKey: string, memberContractId: number, userPartyRegistrationId: string | null): Promise<HttpResponse<ArrayBuffer>> {
        var url = "api/contracts/downloadStartDateReasonAttachment/" + attachmentKey + "/" + memberContractId;

        if (userPartyRegistrationId) {
            url = url + "?userPartyRegistrationId=" + userPartyRegistrationId;
        }

        return this.http.getFile(this.rootUrl + url);
    }
}