import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Params } from "@angular/router";
import { BaseRouteComponent } from "./base/baseRoute.component";
import { GridDataResult, SelectionEvent } from "@progress/kendo-angular-grid";
import { State } from "@progress/kendo-data-query";
import { GridSorting } from "../utilities/gridSorting";
import { baseRouteDeps, BaseRouteDepenciesFactory, BaseRouteDependencies } from "./base/baseRouteDependencies.provider";
import { MainContextService, IOrganisationGroupData } from "../services/mainContext.service";
import { OrganisationGroupRepository } from "../repositories/organisationGroup.repository";
import { FpGridBindingDirective } from "../modules/shared/directives/fpGridBinding.directive";
import { FilterCacheService } from "../services/filterCache.service";

import { NumberUtils } from "../utilities/numberUtils";

import UsersContract = FostPlus.Olympus.UsersDomain.Clients.Api;

import { ConfigurationService } from "../services/configuration.service";
import { MenuService } from "../services/menu.service";
import { FeatureGates } from "../utilities/featureGates";

interface IInitData {
    partyNumber: string;
    vatNumber: string;
    userFirstName: string;
    userLastName: string;
    organisationName: string;
    organisationGroupName: string;
}

interface ILandingPageData {
    requestedPage: string;

    redirectPath: string;
    orgGroupId: number;
    partnerId: number;
    partyId: number;

    canDetermineOrganisationGroup: boolean;
}

// this component works closely together with the mainContext.service !!
@Component({
    templateUrl: "./init.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class InitComponent extends BaseRouteComponent<IInitData> implements OnInit {
    @ViewChild("gridBinding", { static: false }) private gridBinding: FpGridBindingDirective;

    private appSettings: FostPlus.MyFost.Web.Gui.ISettings;
    private mcs: MainContextService;
    private canNavigate: boolean = false;
    public isInError: boolean = false;
    private landingPageData: ILandingPageData;
    private isAuthorizing = false;

    public organisationGroups: Array<FostPlus.Olympus.UsersDomain.Clients.Api.IOrganisationGroupInfoDto>;

    constructor(baseRouteDeps: BaseRouteDependencies,
        private filterCache: FilterCacheService,
        private organisationGroupRepository: OrganisationGroupRepository,
        configurationService: ConfigurationService,
        private menuService: MenuService) {
        super("InitComponent", baseRouteDeps);

        this.mcs = this.baseRouteDeps.mainContextService;

        this.appSettings = configurationService.appSettings;

        this.landingPageData = {
            requestedPage: '',
            redirectPath: '',
            partnerId: null,
            orgGroupId: null,
            partyId: null,

            canDetermineOrganisationGroup: false
        };
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.baseRouteDeps.mainContextService.orgGroup != null) {
            this.navigate();
        }
    }

    protected configure() {
        this.data = this.getEmptyData();

        this.baseRouteDeps.mainContextService.title = "";

        this.dataLoaded = () => {
            // depending on what we get in the queryString 
            //  we can determine the organisationGroup 
            //  or we have to do a search for it
            // todo ???
            if (!this.landingPageData.canDetermineOrganisationGroup) {
                if (this.gridBinding) {
                    this.gridBinding.rebind();
                }
            }
        };
    }

    protected isDirty(): boolean {
        return false;
    }

    private getEmptyData(): IInitData {
        return {
            partyNumber: '',
            vatNumber: '',
            userFirstName: '',
            userLastName: '',
            organisationName: '',
            organisationGroupName: ''
        };
    }

    protected queryParamsUpdated(params: Params): void {
        // fill the landingPageData
        this.landingPageData = {
            requestedPage: params["page"],

            //TODO Okta
            redirectPath: this.determinePage(params["page"], params['origin'], params['error'], params['menuType']),
            partnerId: NumberUtils.parseIntWithFallBackToNull(params["partnerId"]),
            orgGroupId: NumberUtils.parseIntWithFallBackToNull(params["orgGroupId"]),
            partyId: NumberUtils.parseIntWithFallBackToNull(params["partyId"]),

            canDetermineOrganisationGroup: false
        };

        this.doRedirects();
    }

    private doRedirects() {
        var isRedirected: boolean = false;

        if (this.landingPageData.redirectPath === "") {
            isRedirected = this.redirectToOneAndOnlyApp();
        }

        if (!isRedirected) {
            this.menuService.hideMenus(true);

            // verify whether we can navigate further
            if (this.landingPageData.orgGroupId !== null) {

                // partnerId and partyId mean the same Id but keeping partnerId as fallback, not known where it's used atm.
                var partyId = this.landingPageData.partyId || this.landingPageData.partnerId;
                // mainContextService will raise an event which we handle in handleOrganisationGroupContextChanged
                this.mcs.loadOrganisationGroup(this.landingPageData.orgGroupId, partyId);
                this.canNavigate = true;
            } else {
                if (this.landingPageData.partnerId !== null
                    || this.landingPageData.orgGroupId !== null) {
                    this.landingPageData.canDetermineOrganisationGroup = true;
                }

                if ((this.isInternalUser && this.landingPageData.canDetermineOrganisationGroup)
                    || (!this.isInternalOrHasMultipleOrganisationGroups)) {
                    isRedirected = this.followLandingPage();
                }
            }
        }

        if (false == isRedirected) {
            if (this.isInternalOrHasMultipleOrganisationGroups
                && !this.landingPageData.canDetermineOrganisationGroup) {
                if (this.gridBinding) {
                    this.gridBinding.rebind();
                }
            }
        }

        return isRedirected;
    }

    protected handleOrganisationGroupContextChanged(data: IOrganisationGroupData) {
        if (data.isInError) {
            this.stopBlocking();
            this.isInError = true;
        } else {
            this.navigate();
        }
    }

    // loadGridData is used only for internal users who have to search for organisationGroups
    public loadGridData = (state: State, resolve: (value: GridDataResult) => void): void => {
        this.clearFeedback();

        var o: FostPlus.Olympus.UsersDomain.Clients.Api.IOrderByClause = {
            fieldName: 'name',
            direction: FostPlus.Olympus.UsersDomain.Clients.Api.OrderByDirection.Asc
        };

        var request: UsersContract.IOrganisationGroupSearchRequest = {
            luIAMStatusId: null,
            onlyNonEmpty: true,
            luPartyTypeId: null,
            partyId: this.landingPageData.partyId || this.landingPageData.partnerId,
            partyNumber: this.data.partyNumber,
            organisationName: this.data.organisationName,
            name: this.data.organisationGroupName,
            firstName: this.data.userFirstName,
            lastName: this.data.userLastName,
            vatNumber: this.data.vatNumber,
            orderByClauses: state.sort && state.sort.length > 0 ? GridSorting.createOrderBy(state.sort) as any : [o],
            skip: state.skip,
            take: state.take,
            includeActive: true,
            includeInactive: false,
            includeWithoutUserAccessRights: false
        };

        this.organisationGroupRepository.searchOrganisationGroups(request)
            .then((data) => {
                this.organisationGroups = data.organisationGroups;
                // when the partyId is determined by the landingPage mechanism
                //  => we should find only 1 organisationGroup
                if (this.landingPageData.partnerId) {
                    if (this.organisationGroups && data.totalRecordCount === 1) {
                        this.selectOrganisationGroup(this.organisationGroups[0].id);
                    }
                }

                resolve({
                    data: this.organisationGroups,
                    total: data.totalRecordCount
                });

                // when we arrive her through the landingPage mechanism and we cannot find any organisationGroup
                //  => clear the partnerId and rebind to 'search' again
                if (this.landingPageData.partnerId && data.totalRecordCount == 0) {
                    this.landingPageData.partnerId = null;
                    this.gridBinding.rebind();
                }

            }).catch((error) => {
                this.baseRouteDeps.logService.error(error);
                this.handleFeedback(error);
            });
    }

    public get isInternalUser(): boolean {
        return this.baseRouteDeps.authenticationService.isInternalUser;
    }

    public get hasAccessToMultipleOrganisationGroups(): boolean {
        return this.baseRouteDeps.authenticationService.hasAccessToMultipleOrganisationGroups;
    }

    public get isInternalOrHasMultipleOrganisationGroups(): boolean {
        return this.isInternalUser || this.hasAccessToMultipleOrganisationGroups;
    }

    public gridSelectionChange(e: SelectionEvent): void {
        if (e.selectedRows && e.selectedRows.length == 1) {
            var row = e.selectedRows[0];
            this.selectOrganisationGroup(row.dataItem.id);
        }
    }

    public selectOrganisationGroup(id: number) {
        // ask the mainContextService to load the organisationGroup
        //  => we will be notified when it is loaded in the handleOrganisationGroupContextChanged(...) method
        //      => set canNavigate to true so when the organisationGroup is loaded the actual navigation occurs ...
        this.filterCache.clearAll();
        this.mcs.loadOrganisationGroup(id);
        this.canNavigate = true;
    }

    private navigate() {
        var internalOrMultipleOrgGroups = this.baseRouteDeps.authenticationService.isInternalUser
            || this.baseRouteDeps.authenticationService.hasAccessToMultipleOrganisationGroups;
        // internal user has to make a choice => which orgGroup do I want to work with ...
        //  => when this.canNavigate we know the orgGroup...
        if (internalOrMultipleOrgGroups && this.canNavigate) {
            if (this.landingPageData.redirectPath != "") {
                this.menuService.hideMenus(false);
                this.menuService.hideLinks(true);
            } else {
                this.menuService.hideMenus(false);
                this.menuService.hideLinks(false);

                this.redirectInternalUserToOneAndOnlyApp();
            }

            this.mcs.navigate(this.landingPageData.redirectPath);
            this.canNavigate = false;
            this.isInError = false;
        }

        // external user
        if (!internalOrMultipleOrgGroups) {
            if (this.landingPageData.redirectPath != "") {
                this.menuService.hideMenus(false);
                this.menuService.hideLinks(true);
            } else {
                this.menuService.hideMenus(false);
                this.menuService.hideLinks(false);
            }

            this.mcs.navigate(this.landingPageData.redirectPath);
            this.isInError = false;
        }
    }

    private determinePage(page: string, returnUrl: string, error: string, menuType: string): string {
        var result: string = "";

        switch (page) {
            case "userSearch":
                var url = "user/search";
                if (menuType) {
                    url += `?menuType=${menuType}`;
                }
                result = url;
                break;

            case "userIAMRegistration":
                result = "userIAMRegistration";
                break;

            //TODO Okta
            case "userprofile":
                result = "userprofile";
                var query = new URLSearchParams();

                if (returnUrl || error) {
                    if (returnUrl) {
                        query.append('returnUrl', returnUrl);
                        //queryString += "returnUrl=" + encodeURIComponent(returnUrl);
                    }

                    if (error) {
                        query.append('error', error);
                    }

                    result += '?' + query.toString();
                }
                break;
        }

        return result;
    }


    public search(): void {
        if (this.gridBinding) {
            this.gridBinding.resetPaging();
            this.gridBinding.rebind();
        }
    }

    public reset(): void {
        this.data = this.getEmptyData();
        this.search();
    }

    private followLandingPage() {
        var isRedirected = false;
        // todo -- this has to be optimized ( we now always do a search for organisationGroup )

        // for now we can get the partnerId or projectId
        // when we have a partnerId, we know the organisationGroup
        if (this.landingPageData.partnerId != null) {
            this.gridBinding.rebind();
        }

        return isRedirected;
    }

    // Redirect based on user identity 
    //  => external users will be redirected to specific app when they only have access to one app
    //  => internal users won't be redirected here, because they have access to multiple apps (based on their user identity),
    //     => internal users could be redirected to specific app, when they have chosen an orgGroup
    //        (see navigate() and redirectInternalUserToOneAndOnlyApp())
    private redirectToOneAndOnlyApp(): boolean {
        var isRedirected: boolean = false;

        if (this.baseRouteDeps.authenticationService.isAuthenticated) {
            var userInfo = this.baseRouteDeps.authenticationService.userIdentity;

            // We redirect external users in Prod env always to Partner for now (even if they also have access to member for ex)
            var blockPortalChoice = FeatureGates.blockPortalChoice(this.baseRouteDeps.authenticationService, this.baseRouteDeps.configuration);
            if (blockPortalChoice && userInfo.hasPartner) {
                window.location.replace(this.appSettings.linkToPartner);
                return true;
            }

            // When nothing blocked:
            //  - we redirect when access to one and only app
            //  - we do nothing when access to multiple apps, so user gets the dashboard.comp with the portal choice
            if (!userInfo.hasAccessToMultipleOrganisationGroups) {
                if (userInfo.hasLabo
                    && !userInfo.hasMember
                    && !userInfo.hasPartner) {
                    isRedirected = true;
                    window.location.replace(this.appSettings.linkToLabo);
                } else if (!userInfo.hasLabo
                    && userInfo.hasMember
                    && !userInfo.hasPartner) {
                    isRedirected = true;
                    window.location.replace(this.appSettings.linkToMember);
                } else if (!userInfo.hasLabo
                    && !userInfo.hasMember
                    && userInfo.hasPartner) {
                    isRedirected = true;
                    window.location.replace(this.appSettings.linkToPartner);
                }
            }
        }

        return isRedirected;
    }

    // We can't redirect internal users or with MultipleOrganisationGroups based on their user identity, only when they have chosen an orgGroup
    //  => so we redirect to specific app when orgGroup only has one party type
    //  => we'll show the dashboard.component when orgGroup has multiple party types (where user can choose the portal/app)
    private redirectInternalUserToOneAndOnlyApp(): void {
        var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();
        var mcs = this.baseRouteDeps.mainContextService;

        if (this.isInternalOrHasMultipleOrganisationGroups && ogData.organisationGroupId != null) {
            var urlSuffix = "/#/init?orgGroupId=" + ogData.organisationGroupId;

            if (mcs.hasLabo
                && !mcs.hasMember
                && !mcs.hasPartner) {
                window.location.replace(this.appSettings.linkToLabo + urlSuffix);
            } else if (!mcs.hasLabo
                && mcs.hasMember
                && !mcs.hasPartner) {
                window.location.replace(this.appSettings.linkToMember + urlSuffix);
            } else if (!mcs.hasLabo
                && !mcs.hasMember
                && mcs.hasPartner) {
                window.location.replace(this.appSettings.linkToPartner + urlSuffix);
            }
        }
    }
}