import { Component, Input, Output, OnInit, EventEmitter, ChangeDetectorRef, Optional, Self } from "@angular/core";
import { ControlValueAccessor, NgModel } from "@angular/forms";

@Component({
    selector: "fp-dropdown",
    templateUrl: "./fpDropdown.component.html",
    host: { 'class': 'fp-control' }
})
export class FpDropdownComponent implements ControlValueAccessor, OnInit {

    public model: NgModel;

    private _data: Array<any> = [];

    @Input() set data(value: Array<any>) {
        this._data = value;
        this.filteredData = value;
        this.mustUpdateReadModeText = true;
    }

    @Input() name: string;
    @Input() showDefaultItem: boolean = false;
    @Input() valuePrimitive: boolean = true;
    @Input() textField: string = 'displayName';
    @Input() valueField: string = 'id';
    @Input() filterable: boolean = false;
    @Input() disabled: boolean = false;
    @Input() readMode: boolean = false;
    @Input() localId: string;

    @Input() filterFunction: (data: Array<any>, filterValue: string) => Array<any> = null;
    @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() close: EventEmitter<Event> = new EventEmitter<Event>();

    private innerValue: number;
    private controlId: string = "";
    private filteredData: Array<any> = [];
    private defaultItem: any;
    private mustUpdateReadModeText: boolean = false;
    private readModeText: string = "";

    constructor(
        @Optional() @Self() ngModel: NgModel | null,
        private changeDetectorRef: ChangeDetectorRef) {
        this.model = ngModel;

        // Set the valueAccessor manually.
        // Before this was done by providing the component as NG_VALUE_ACCESSOR,
        //    but this causes a circular import when injecting NgModel in the constructor.
        if (this.model) {
            this.model.valueAccessor = this;
        }
    }

    onChange(_): void { }

    onTouched(): void { }

    ngOnInit(): void {
        this.controlId = this.name.replace('.', '_');

        if (this.localId != undefined) {
            this.controlId = this.controlId + "|" + this.localId;
        }

        this.setDefaultItem();
    }

    private setDefaultItem() {
        if (!this.showDefaultItem) {
            this.defaultItem = null;
        } else {
            var defaultItem: { [k: string]: any } = {};

            defaultItem[this.textField] = '';
            defaultItem[this.valueField] = null;

            this.defaultItem = defaultItem;
        }
    }

    //get accessor
    get value(): any {
        if (!this.innerValue) {
            return null;
        }

        return this.innerValue;
    };

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.mustUpdateReadModeText = true;

            if (this.onChange) {
                this.onChange(v);
            }

            this.selectionChange.emit(v);
        }
    }

    writeValue(value: any): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
            this.mustUpdateReadModeText = true;
            this.changeDetectorRef.detectChanges();
        }
    }

    onBlur() {
        if (this.onTouched) {
            this.onTouched();
        }
    }

    closeEvent(event: Event) {
        this.close.emit(event);
    }

    private onFilterChange(value: string): void {
        if (this.filterFunction) {
            this.filterFunction(this._data, value);
        } else {
            this.filteredData = this._data.filter((item) => {
                return item[this.textField] == null || item[this.textField].toLowerCase().indexOf(value.toLowerCase()) > -1;
            });
        }
    }

    private getReadModeText(): string {
        if (this.mustUpdateReadModeText) {
            this.mustUpdateReadModeText = false;
            this.readModeText = "";
            if (this._data && this._data.length && this.innerValue) {
                var items = this._data.filter((item) => {
                    return item[this.valueField] == this.innerValue;
                });

                if (items.length > 0) {
                    this.readModeText = items[0][this.textField];
                }
            }
        }

        return this.readModeText;
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}