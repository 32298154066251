import { Component } from "@angular/core";
import { BaseRouteComponent } from "./base/baseRoute.component";
import { baseRouteDeps, BaseRouteDepenciesFactory, BaseRouteDependencies } from "./base/baseRouteDependencies.provider";
import { ActivateMenuOptions } from "../../../ScriptsModels/MenuItem";
import { IOrganisationGroupData } from "../services/mainContext.service";
import { mnuItemsNames } from "../services/menu.service";
import { ItsMeRepository } from "../repositories/itsMe.repository";

interface IDashboardDetailData {
}

@Component({
    templateUrl: "./dashboard.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class DashboardComponent extends BaseRouteComponent<IDashboardDetailData> {
    constructor(
        baseRouteDeps: BaseRouteDependencies,
        private itsMeRepo: ItsMeRepository
    ) {
        super("DashboardComponent", baseRouteDeps);

        this.activateMenuOptions = new ActivateMenuOptions(mnuItemsNames.home);

        this.appSettings = this.baseRouteDeps.configuration.appSettings;
    }

    public appSettings: FostPlus.MyFost.Web.Gui.ISettings;

    public linkToPartner: string;
    public linkToMember: string;
    public linkToLabo: string;

    protected configure() {
        this.dataLoaded = () => {
            this.buildLinks();
        }

        this.data = {};
        this.baseRouteDeps.mainContextService.title = "";
    }

    protected handleOrganisationGroupContextChanged(data: IOrganisationGroupData) {
        this.buildLinks();
    }

    private buildLinks() {
        this.linkToPartner = this.appSettings.linkToPartner;
        this.linkToMember = this.appSettings.linkToMember;
        this.linkToLabo = this.appSettings.linkToLabo;

        // when internal user, pass along the OrganisationGroupId
        if (this.baseRouteDeps.authenticationService.isInternalUser) {
            var ogData = this.baseRouteDeps.mainContextService.organisationGroupData();

            if (ogData.organisationGroupId != null) {
                this.linkToPartner = this.linkToPartner + "/#/init?orgGroupId=" + ogData.organisationGroupId;
                this.linkToMember = this.linkToMember + "/#/init?orgGroupId=" + ogData.organisationGroupId;
                this.linkToLabo = this.linkToLabo + "/#/init?orgGroupId=" + ogData.organisationGroupId;
            }
        }
    }

    public get hasPartner(): boolean {
        if (this.baseRouteDeps.authenticationService.isAuthenticated && this.baseRouteDeps.authenticationService.userIdentity) {
            return this.baseRouteDeps.authenticationService.userIdentity.hasPartner
                && this.baseRouteDeps.mainContextService.hasPartner;
        }

        return false;
    }

    public get hasMember(): boolean {
        if (this.baseRouteDeps.authenticationService.isAuthenticated && this.baseRouteDeps.authenticationService.userIdentity) {
            return this.baseRouteDeps.authenticationService.userIdentity.hasMember
                && this.baseRouteDeps.mainContextService.hasMember;
        }

        return false;
    }

    public get hasLabo(): boolean {
        if (this.baseRouteDeps.authenticationService.isAuthenticated && this.baseRouteDeps.authenticationService.userIdentity) {
            return this.baseRouteDeps.authenticationService.userIdentity.hasLabo
                && this.baseRouteDeps.mainContextService.hasLabo;
        }

        return false;
    }
}