import { Component, Inject, ChangeDetectionStrategy, OnInit} from "@angular/core"
import { MainContextService } from "../services/mainContext.service";
import { MainContext } from "../../../ScriptsModels/MainContext";

@Component({
    //moduleId: module.id,
    selector: "fp-nav",
    templateUrl: "./nav.component.html"
})

export class NavComponent {
    //public swaggerLink: string = '/Swagger/index.html'

    constructor( @Inject("appName") private appName: string,
        public mainContextService: MainContextService) {
        this.mainContext = mainContextService.currentContext;
    }

    public mainContext: MainContext;
}