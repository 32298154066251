import { Injectable, Inject } from '@angular/core';
import { HttpProvider } from "../services/http.provider";

import PartyDomain = FostPlus.Olympus.PartyDomain.Clients.Api;

export interface IPartyRepository {
    forRegistrationGet(userPartyRegistrationId: string | null): Promise<PartyDomain.IPartyForRegistrationGetResponse>;
    forRegistrationUpdate(request: PartyDomain.IPartyForRegistrationUpdateRequest): Promise<PartyDomain.IPartyForRegistrationUpdateResponse>;
    forRegistrationProfile(userPartyRegistrationId: string | null): Promise<PartyDomain.IPartyForRegistrationProfileGetResponse>;
    forRegistrationProfileUpdate(request: PartyDomain.IPartyForRegistrationProfileUpdateRequest): Promise<PartyDomain.IPartyForRegistrationProfileUpdateResponse>;
}

@Injectable()
export class PartyRepository implements IPartyRepository {
    
    static $inject = [
        '$resource',
        'rootUrl',
        '$window',
        '$q'
    ];

    constructor(private http: HttpProvider,
        @Inject('rootUrl') private rootUrl: string) {
    }

    forRegistrationGet(userPartyRegistrationId: string | null): Promise<PartyDomain.IPartyForRegistrationGetResponse> {
        const req: PartyDomain.IPartyForRegistrationGetRequest = {
            userPartyRegistrationId: userPartyRegistrationId
        };

        const url = "api/parties/forRegistration";
        return this.http.post<PartyDomain.IPartyForRegistrationGetResponse>(this.rootUrl + url, req);
    }

    forRegistrationUpdate(request: PartyDomain.IPartyForRegistrationUpdateRequest): Promise<PartyDomain.IPartyForRegistrationUpdateResponse> {
        const url = "api/parties/forRegistrationUpdate";
        return this.http.post<PartyDomain.IPartyForRegistrationUpdateResponse>(this.rootUrl + url, request);
    }

    forRegistrationProfile(userPartyRegistrationId: string | null): Promise<PartyDomain.IPartyForRegistrationProfileGetResponse> {
        const req: PartyDomain.IPartyForRegistrationProfileGetRequest = {
            userPartyRegistrationId: userPartyRegistrationId
        };
        const url = "api/parties/forRegistrationProfile";
        return this.http.post<PartyDomain.IPartyForRegistrationProfileGetResponse>(this.rootUrl + url, req);
    }
    forRegistrationProfileUpdate(request: PartyDomain.IPartyForRegistrationProfileUpdateRequest): Promise<PartyDomain.IPartyForRegistrationProfileUpdateResponse> {
        const url = "api/parties/forRegistrationProfileUpdate";
        return this.http.post<PartyDomain.IPartyForRegistrationProfileUpdateResponse>(this.rootUrl + url, request);
    }    
}

