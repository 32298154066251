<div class="row" *ngIf="errors && errors.length > 0">
    <div class="col-md-12">
        <div class="alert alert-danger" role="alert">
            <span *ngIf="errors.length == 1">
                <fa-icon icon="exclamation-circle"></fa-icon>
                {{errors[0].message}}
            </span>
            <ul *ngIf="errors.length > 1">
                <li *ngFor="let error of errors">
                    <fa-icon icon="exclamation-circle"></fa-icon>
                    {{error.message}}
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="row" *ngIf="warnings && warnings.length > 0">
    <div class="col-md-12">
        <div class="alert alert-warning" role="warning">
            <span *ngIf="warnings.length == 1">
                <fa-icon icon="exclamation-circle"></fa-icon>
                {{warnings[0].message}}
            </span>
            <ul *ngIf="warnings.length > 1">
                <li *ngFor="let warning of warnings">
                    <fa-icon icon="exclamation-circle"></fa-icon>
                    {{warning.message}}
                </li>
            </ul>
        </div>
    </div>
</div>