import { Injectable, ErrorHandler, Injector } from "@angular/core";
import { LogService } from "../services/log.service";

/**
 * Wraps a result from an http call.
 */
export class WrappedResult<T> {
    /**
     * Creates a WrappedResult
     * @param result The result from an http call
     * @param error The error response form an http call
     * @param displayError The text to show to the user
     */
    constructor(public result: T[], public error: any, private feedbackMessage: FeedbackMessage) {
    }
    /**
     * Indicates whether the WrappedResult is an error result
     */
    isError = () => {
        return this.error !== undefined && this.error !== null;
    };
    /**
     * Gets the error text to show to the user
     */
    getDisplayError = () => {
        return this.feedbackMessage.message;
    };
}

/**
 * Determines the different feedback message types
 */
export enum FeedbackMessageType {
    Error,
    Warning,
}

/**
 * FeedbackMessage is used to provide feedback to the user.
 */
export class FeedbackMessage {
    constructor(public message: string, public feedbackMessageType: FeedbackMessageType) {
    }
}

declare var appInsights: any;

@Injectable()
export class MyFostErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }

    handleError(error): void {
        if (typeof appInsights !== "undefined"
            && appInsights) {
            appInsights.trackException(error);
        }

        const logService = this.injector.get(LogService);

        logService.error(error);
    }
}