<div *ngIf="isAuthenticatedObservable | async" class="nav-main">
    <!-- MenuItems -->
    <ul class="navbar-left">
        <li *ngFor=" let item of menuService.menuItems | async">
            <a *ngIf="isRouteMenuItem(item)" name="{{item.name}}" [routerLink]="resolveRoute(item)"
               [queryParams]="resolveRouteParams(item)" [ngClass]="{'active' : item.isActive}">
                <span fp-translate>{{ item.translationKey }}</span>
            </a>
            <a *ngIf="!isRouteMenuItem(item)" name="{{item.name}}" href="{{resolveUrl(item)}}"
               [ngClass]="{'active' : item.isActive}" target="{{item.openNewTab ? '_blank' : ''}}">
                <span fp-translate>{{ item.translationKey }}</span>
            </a>
        </li>
    </ul>
    <!-- Admin MenuItems -->
    <ul class="navbar-right">
        <li *ngFor=" let item of menuService.adminMenuItems | async">
            <a *ngIf="isRouteMenuItem(item)" name="{{item.name}}" [routerLink]="resolveRoute(item)"
               [queryParams]="resolveRouteParams(item)" [ngClass]="{'active' : item.isActive}">
                <span fp-translate>{{ item.translationKey }}</span>
            </a>
            <a *ngIf="!isRouteMenuItem(item)" name="{{item.name}}" href="{{resolveUrl(item)}}"
               [ngClass]="{'active' : item.isActive}" target="{{item.openNewTab ? '_blank' : ''}}">
                <span fp-translate>{{ item.translationKey }}</span>
            </a>
        </li>
    </ul>
</div>
<div *ngIf="isAuthenticatedObservable | async" class="subnav">
    <ul *ngIf="menuService.isSubMenuVisible | async">
        <li *ngFor="let item of menuService.subMenuItems | async">
            <a *ngIf="isRouteMenuItem(item)" name="{{item.name}}" [routerLink]="resolveRoute(item)"
               [queryParams]="resolveRouteParams(item)" tabindex="-1" [ngClass]="{'active' : item.isActive}">
                <span fp-translate>{{item.translationKey}}</span>
            </a>
            <a *ngIf="!isRouteMenuItem(item)" name="{{item.name}}" href="{{resolveUrl(item)}}" tabindex="-1"
               [ngClass]="{'active' : item.isActive}">
                <span fp-translate>{{item.translationKey}}</span>
            </a>
        </li>
    </ul>
</div>