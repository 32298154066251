import { Directive, ElementRef, OnInit } from '@angular/core';
import { BlockUiService } from "../../../services/blockUi.service";

@Directive({
    selector: "[fpBlockUiContainer]",
    providers: [
        BlockUiService
    ]
})
export class FpBlockUiContainerDirective implements OnInit {
    constructor(private element: ElementRef,
        private blockUiService: BlockUiService) {
    }

    ngOnInit() {
        var e = <HTMLElement>this.element.nativeElement;

        if (e) {
            this.blockUiService.blockElement = e;
        }
    }
}