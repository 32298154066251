import { Injectable } from '@angular/core';

//import MyFost = FostPlus.MyFost.Web.Gui;
//import Identity = FostPlus.Core.Identity;

@Injectable()
export class ConfigurationService {
    public appSettings: FostPlus.MyFost.Web.Gui.ISettings;
    private isRootLoaded: boolean;
    private requestLanguage: string;
    private cacheValidity: number = 7200;

    public setAppSettings(settings: FostPlus.MyFost.Web.Gui.ISettings): void {
        this.appSettings = settings;        
    }

    public mustUseInterceptor(url: string): boolean {
        // only intercept request to the aplication WebApi (/Partner/...)
        // ignore request starting with "http://" or "https://"
        var httpRegex = /^https?:\/\//i;
        return !httpRegex.test(url);
    }

    public isAppSettingsLoaded(): boolean {
        if (this.appSettings) {
            return true;
        }

        return false;
    }

    public isDebug(): boolean {
        if (this.appSettings) {
            return this.appSettings.isDebug;
        }

        return false;
    }

    //TODO Okta
    //public getIdentityProvider(): Identity.IdentityProviderEnum {
    //    if (this.appSettings) {
    //        return this.appSettings.identityProvider;
    //    }

    //    return Identity.IdentityProviderEnum.IdentityServer;
    //}

    public getAppEnvironment(): FostPlus.MyFost.Web.Gui.Models.AppEnvironment {
        if (this.appSettings) {
            return this.appSettings.appEnvironment;
        }

        // Set default to Prod !
        //  => AppEnv setting will usually be used to BLOCK things in Prod or/and Acc
        //  => if config for some reason doesn't contain this setting, 
        //     we want Prod mode on and not Local or Dev (with all the features)
        return FostPlus.MyFost.Web.Gui.Models.AppEnvironment.Prod;
    }

    public getLinkToPartner(): string {
        if (this.appSettings) {
            return this.appSettings.linkToPartner;
        }

        return null;
    }

    public getLinkToPartnerFAQ_Nl(): string {
        if (this.appSettings) {
            return this.appSettings.linkToPartnerFAQ_Nl;
        }

        return null;
    }

    public getLinkToPartnerFAQ_Fr(): string {
        if (this.appSettings) {
            return this.appSettings.linkToPartnerFAQ_Fr;
        }

        return null;
    }

    public getLinkToPartnerFAQ_En(): string {
        if (this.appSettings) {
            return this.appSettings.linkToPartnerFAQ_En;
        }

        return null;
    }

    public getLinkToFostPack(): string {
        if (this.appSettings) {
            return this.appSettings.linkToFostPack;
        }

        return null;
    }

    public getLinkToMember(): string {
        if (this.appSettings) {
            return this.appSettings.linkToMember;
        }

        return null;
    }

    public getLinkToMemberFAQ_Nl(): string {
        if (this.appSettings) {
            return this.appSettings.linkToMemberFAQ_Nl;
        }

        return null;
    }

    public getLinkToMemberFAQ_Fr(): string {
        if (this.appSettings) {
            return this.appSettings.linkToMemberFAQ_Fr;
        }

        return null;
    }

    public getLinkToMemberFAQ_En(): string {
        if (this.appSettings) {
            return this.appSettings.linkToMemberFAQ_En;
        }

        return null;
    }

    public getLinkToLabo(): string {
        if (this.appSettings) {
            return this.appSettings.linkToLabo;
        }

        return null;
    }

    public getLinkToLaboFAQ_Nl(): string {
        if (this.appSettings) {
            return this.appSettings.linkToLaboFAQ_Nl;
        }

        return null;
    }

    public getLinkToLaboFAQ_Fr(): string {
        if (this.appSettings) {
            return this.appSettings.linkToLaboFAQ_Fr;
        }

        return null;
    }

    public getLinkToLaboFAQ_En(): string {
        if (this.appSettings) {
            return this.appSettings.linkToLaboFAQ_En;
        }

        return null;
    }

    public getLinkToUser(): string {
        if (this.appSettings) {
            return this.appSettings.linkToUser;
        }

        return null;
    }

    public setRequestLanguage(language: string): void {
        this.requestLanguage = language;
    }

    public getRequestLanguage(): string {
        if (this.requestLanguage) {
            return this.requestLanguage;
        }

        return null;
    }

    // Cache validity in seconds
    public getCacheValidity(): number {
        return this.cacheValidity;
    }
}