<div *ngIf="!readMode" style="display: inline-block">
    <input type="checkbox"
           [id]="controlId"
           [name]="name"
           [(ngModel)]="value" />
    <fp-label *ngIf="!hideLabel && !label" [label]="name" [for]="controlId"></fp-label>
    <label *ngIf="!hideLabel && label" [for]="controlId" class="control-label">{{label}}</label>
</div>

<div *ngIf="readMode" class="d-inline-block">
    <fa-icon *ngIf="value" [icon]="['far', 'square-check']"></fa-icon>
    <fa-icon *ngIf="!value" [icon]="['far', 'square']"></fa-icon>
    <fp-label *ngIf="!hideLabel && !label" [label]="name" [for]="controlId"></fp-label>
    <label *ngIf="!hideLabel && label" [for]="controlId" class="control-label">{{label}}</label>
</div>
