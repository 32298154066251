import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { HttpProvider } from '../../services/http.provider';

export class WebApiTranslateLoader implements TranslateLoader {
    constructor(private http: HttpProvider,
        private apiPath: string) {
        var a = http;
    }

    getTranslation(lang: string): Observable<any> {
        var url = this.apiPath.replace('{lang}', lang).replace('{part}', 'All');

        return this.http.getAsObservable<any>(url);
    }
}