import { Directive, OnInit, OnDestroy, Input, ElementRef, HostBinding } from "@angular/core"
import { DataBindingDirective, GridComponent, GridDataResult, DataStateChangeEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { State, SortDescriptor } from '@progress/kendo-data-query';
import { BlockUiService } from "../../../services/blockUi.service";
import { FpCacheServiceFactory, FpCacheService, FpCacheStoragesEnum } from "../../../services/fpCacheService.service";
import { LogService } from "../../../services/log.service";


interface IGridOptionsData {
    pageSize: number;
}

interface ITempGridOptionsData {
    itemKey: string;
    skip: number;
    sort: Array<SortDescriptor>;
}

@Directive({
    selector: "[fpGridBinding]",
    exportAs: "fpGridBinding",
    providers: [
        BlockUiService
    ]
})
export class FpGridBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {
    constructor(gridComponent: GridComponent,
        private blockUiService: BlockUiService,
        private fpCacheServiceFactory: FpCacheServiceFactory,
        private logService: LogService,
        private element: ElementRef) {

        super(gridComponent);
        this.localStorageCache = fpCacheServiceFactory.getOrCreate(FpCacheStoragesEnum.LOCAL_STORAGE, "grid");
        this.memoryStorageCache = fpCacheServiceFactory.getOrCreate(FpCacheStoragesEnum.MEMORY, "grid");
    }

    private localStorageCache: FpCacheService;
    private memoryStorageCache: FpCacheService;

    @Input() uniqueId: string;
    @Input() itemKey: string;
    @Input() rebinder: (state: State, resolve: (value: GridDataResult) => void) => GridDataResult;
    // automatically bind data in OnInit. 
    // automatic binding can be disabled when the controller needs to load Lu- list before filling the grid. (For translations, ...)
    @Input() autoBindOnInit: boolean;
    @Input() defaultPageSize: number = 10;

    @HostBinding('class.selectable') selectable: boolean;

    ngOnInit(): void {
        var e = <HTMLElement>this.element.nativeElement;

        if (e) {
            this.blockUiService.blockElement = e;
        }

        super.ngOnInit();

        this.grid.data = { data: [], total: 0 };

        this.grid.dataStateChange.subscribe((e) => {
            var dsc: DataStateChangeEvent = <DataStateChangeEvent>e;

            this.storePersistentGridOptions(dsc);
            this.storeTempGridOptions(dsc);
        });

        this.restorePersistentGridOptions();
        this.restoreTempGridOptions();

        if (this.autoBindOnInit) {
            this.rebind();
        }

        this.selectable = this.checkGridRowsAreSelectable();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public rebind(): void {
        //start blocking
        this.blockUiService.startBlocking();

        this.filter = this.state.filter;

        try {
            this.rebinder(this.state, this.resolveData);
        } catch (e) {
            // stop blocking
            this.blockUiService.stopBlocking();
        }
    }

    public resetPaging(): void {
        this.skip = 0;
        this.state.skip = 0;
    }

    private resolveData = (gridData: GridDataResult) => {
        // when we are passed the last page ...
        //  => in comment for now
        //if (this.state.skip != 0) {
        //    if (!gridData.data || gridData.data.length == 0) {
        //        var p =  (gridData.total / this.state.take) | 0;
        //        var skip = p * this.grid.pageSize;
        //        if (skip == gridData.total) {
        //            skip = skip - this.grid.pageSize;
        //        }
        //        this.skip = skip;
        //        this.state.skip = skip;
        //        this.blockUiService.stopBlocking();
        //        this.rebind();
        //        return;
        //    }
        //}

        this.grid.data = gridData;

        // stop blocking
        this.blockUiService.stopBlocking();
    }

    private storePersistentGridOptions(dsc: DataStateChangeEvent) {
        if (dsc && this.uniqueId) {
            var god: IGridOptionsData = {
                pageSize: dsc.take
            };
            this.localStorageCache.set("-" + this.uniqueId, god);
        }
    }

    private restorePersistentGridOptions() {
        var god: IGridOptionsData = null;
        if (this.uniqueId) {
            god = this.localStorageCache.get("-" + this.uniqueId);
        }
        if (god && god.pageSize) {
            this.pageSize = god.pageSize;
        } else {
            this.pageSize = this.defaultPageSize;
        }
    }

    private storeTempGridOptions(dsc: DataStateChangeEvent) {
        if (dsc && this.uniqueId) {
            var itemKey: string = this.itemKey || "";
            var god: ITempGridOptionsData = {
                itemKey: itemKey,
                skip: dsc.skip,
                sort: dsc.sort
            };
            this.memoryStorageCache.set("-temp" + this.uniqueId, god);
        }
    }

    private restoreTempGridOptions() {
        if (this.uniqueId) {
            var itemKey: string = this.itemKey || "";
            var god: ITempGridOptionsData = this.memoryStorageCache.get("-temp" + this.uniqueId);

            if (god) {
                if (god.itemKey == itemKey) {
                    this.logService.log("restore temp grid options %s", this.uniqueId);

                    this.skip = god.skip || 0;
                    this.sort = god.sort || [];
                } else {
                    this.logService.log("not restore temp grid options %s, oldKey: %s, newKey: %s", this.uniqueId, god.itemKey, this.itemKey, );
                }
            }
        }
    }

    private checkGridRowsAreSelectable(): boolean {
        var self = this;

        var gridSelectable = self.grid.selectable;

        if (!gridSelectable) {
            return false;
        }

        if (gridSelectable === true) {
            return true;
        }

        if (gridSelectable instanceof Object) {
            var selectableSettings = <SelectableSettings>gridSelectable;

            return selectableSettings.enabled == null || selectableSettings.enabled;
        }
    }
}