import { Injectable, Inject, EventEmitter, OnInit, } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { FpCacheServiceFactory, FpCacheService, FpCacheStoragesEnum } from "./fpCacheService.service";
import { MainContext } from "../../../ScriptsModels/MainContext";
import { OrganisationGroupRepository } from "../repositories/organisationGroup.repository";
import { ConfigurationService } from "./configuration.service";
import { LogService } from "./log.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { LanguageService, ICultureChangedEvent } from "./language.service";
import { AuthenticationService } from "./authentication.service";
import { AnalyticsService } from "./analytics.service";
import { SecurityService } from "./security.service";
import { LookupDataUtilitiesService } from "./lookupDataUtilities.service";

import UsersContract = FostPlus.Olympus.UsersDomain.Clients.Api;
import AvalContract = FostPlus.Olympus.Aval.ContractDomain.Clients.Api;

export interface IExtOrganisationGroupPartnerCapabilityDto extends AvalContract.IOrganisationGroupPartnerCapabilityDto {
    // The 'links' arrays contain the booleans (shows) of each specific block/panel  

    // Block with multiple panels: Planning - WeighingTickets - Deliveries - Other (glassBinWeighings + materialFlows)
    $$showDeliveryBlock: boolean;

    $$showPlanningPanel: boolean;
    $$planningLinks: boolean[];

    $$showWeighingTicketPanel: boolean;
    $$weighingTicketLinks: boolean[];

    $$showDeliveryPanel: boolean;
    $$deliveryLinks: boolean[];

    $$showDeliveryOtherPanel: boolean;
    $$deliveryOtherLinks: boolean[];

    $$showEnrichmentPanel: boolean;
    $$enrichmentLinks: boolean[];

    $$showTariffsAndPricesBlock: boolean;
    $$tariffsAndPricesLinks: boolean[];

    $$showBillingBlock: boolean;
    $$billingLinks: boolean[];

    $$showStockAndProductionBlock: boolean;
    $$stockAndProductionLinks: boolean[];

    $$showGeneralSettingsBlock: boolean;
    $$generalSettingsLinks: boolean[];
}

export interface IOrganisationGroupData {
    isInError: boolean;
    organisationGroupId: number;
    organisationGroupIsServiceProvider: boolean;
    selectedPartyId: number;
}

@Injectable()
export class MainContextService {
    constructor(@Inject("appName") private appName: string,
        private titleService: Title,
        private fpCacheServiceFactory: FpCacheServiceFactory,
        private authenticationService: AuthenticationService,
        private organisationGroupRepository: OrganisationGroupRepository,
        private securityService: SecurityService,
        private configurationService: ConfigurationService,
        private logService: LogService,
        private router: Router,
        private langService: LanguageService,
        private analyticsService: AnalyticsService,
        private lookupDataUtilitiesService: LookupDataUtilitiesService,
        public route: ActivatedRoute) {

        this.currentContext = { isFullScreen: false, language: langService.getLanguage() };
        this.titleService.setTitle(this.appName);

        this.onCultureChangeSub = this.langService.onCultureChange.subscribe(this.cultureChanged);
        this._sessionStorageCache = fpCacheServiceFactory.getOrCreate(FpCacheStoragesEnum.SESSION_STORAGE, "mainContextService");
        this._localStorageCache = fpCacheServiceFactory.getOrCreate(FpCacheStoragesEnum.LOCAL_STORAGE, "mainContextService");

        var fullScreen: boolean = this._localStorageCache.get(this.fullScreenCacheKey);

        if (fullScreen) {
            this.currentContext.isFullScreen = fullScreen;
        }
    }

    private onCultureChangeSub: Subscription;
    private fullScreenCacheKey: string = "myFost_fullScreen";
    private _titleText: string;
    private _selectedSiteId: number = 0;
    private _isOrganisationGroupError: boolean = false;
    private _isOrganisationGroupDataLoaded: boolean = false;
    private _sessionStorageCache: FpCacheService;
    private _localStorageCache: FpCacheService;

    public ogCaps: IExtOrganisationGroupPartnerCapabilityDto;

    public currentContext: MainContext;
    public orgGroup?: UsersContract.IOrganisationGroupPartyInfoDto;
    public onOrganisationGroupContextChanged: EventEmitter<IOrganisationGroupData> = new EventEmitter<IOrganisationGroupData>();

    // isOrganisationGroupDataLoaded
    public get isOrganisationGroupDataLoaded(): boolean {
        return this._isOrganisationGroupDataLoaded;
    }

    // isContractSelectorAvailable
    public get isContractSelectorAvailable(): boolean {
        return this.authenticationService.isInternalUser;
    }

    // organisationGroupId
    public get organisationGroupId(): number {
        if (this.orgGroup) {
            return this.orgGroup.id;
        } else {
            return null;
        }
    }

    // hasMember
    public get hasMember(): boolean {
        if (this.orgGroup) {
            return this.orgGroup.hasMember;
        }

        return false;
    }

    // hasPartner
    public get hasPartner(): boolean {
        if (this.orgGroup) {
            return this.orgGroup.hasPartner;
        }

        return false;
    }

    // hasLabo
    public get hasLabo(): boolean {
        if (this.orgGroup) {
            return this.orgGroup.hasLabo;
        }

        return false;
    }

    // selectedPartyId
    public get selectedSiteId(): number {
        return this._selectedSiteId;
    }

    public set selectedSiteId(id: number) {
        if (this._selectedSiteId !== id) {
            this._selectedSiteId = id;

            this._sessionStorageCache.set("oldSelectedSiteId", id);
            this.emitChangedEvent();
        }
    }

    public isPartyInGroup(partyId: number): boolean {
        var result: boolean = false;

        if (partyId && this.orgGroup !== null) {
            if (this.orgGroup.parties !== null) {
                var match = this.orgGroup.parties.find((value) => {
                    return value.id === partyId;
                });
                if (match) {
                    result = true;
                }
            }
        }

        return result;
    }

    // title
    public set title(title: string) {

        if (this.authenticationService.isInternalUser) {
            if (this.orgGroup) {
                this.titleService.setTitle(this.appName + " - " + this.orgGroup.name);
            } else {
                this.titleService.setTitle(this.appName);
            }
        } else {
            this._titleText = title;

            if (this._titleText && this._titleText.length > 0) {
                this.titleService.setTitle(this.appName + " - " + this._titleText);
            } else {
                this.titleService.setTitle(this.appName);
            }
        }
    }

    // headerTitle
    public get headerTitle() {
        if (this._titleText) {
            return this._titleText;
        } else {
            return "";
        }
    }

    // isFullScreen
    public get isFullScreen(): boolean {
        return this.currentContext.isFullScreen;
    }

    public toggleFullScreen(): void {
        this.currentContext.isFullScreen = !this.currentContext.isFullScreen;
        this._localStorageCache.set(this.fullScreenCacheKey, this.currentContext.isFullScreen);
        this.refreshBodyOffset();
    }

    private cultureChanged = (eventData: ICultureChangedEvent) => {
        this.currentContext.language = eventData.language;
    }

    public organisationGroupData(): IOrganisationGroupData {
        var data: IOrganisationGroupData = {
            isInError: this._isOrganisationGroupError,
            organisationGroupId: this.organisationGroupId,
            organisationGroupIsServiceProvider: this.orgGroup ? this.orgGroup.isServiceProvider : false,
            selectedPartyId: this.selectedSiteId == 0 ? null : this.selectedSiteId
        };

        return data;
    }

    public navigate(path: string) {
        //var userTypeId = this.configurationService.getOlympusUserType();
        if (path === null || path == "") {
            path = "home";
        }

        //// only if we have an external users
        ////  => internal users must pass by the 'contractSelector'
        //if (userTypeId === 1) {

        //}

        //if (userTypeId !== 1) {

        //}

        this.router.navigateByUrl(path, { replaceUrl: true });
    }

    public refreshBodyOffset() {
        // set padding-top for body ( to take into account the subMenu )
        setTimeout(() => {
            const body = document.getElementsByTagName('body')[0];
            if (!this.isFullScreen) {
                body.classList.remove('fullScreen');
            } else {
                body.classList.add('fullScreen');
            }
        });
    }

    public loadOrganisationGroup(organisationGroupId: number | null = null, partyId: number | null = null): boolean {
        if (false === this.authenticationService.isAuthenticated) {
            return false;
        }

        var isInternalUser = this.authenticationService.isInternalUser;
        var hasAccessToMultipleOrganisationGroups = this.authenticationService.hasAccessToMultipleOrganisationGroups;
        var isInternalOrHasMultipleOrgGroups = isInternalUser || hasAccessToMultipleOrganisationGroups;

        if (isInternalOrHasMultipleOrgGroups && organisationGroupId === null) {
            organisationGroupId = this._sessionStorageCache.get("organisationGroupId");
        } else {
            this._sessionStorageCache.set("organisationGroupId", organisationGroupId);
        }

        if (!isInternalOrHasMultipleOrgGroups || (isInternalOrHasMultipleOrgGroups && organisationGroupId !== null)) {
            this.loadOrganisationGroupData(organisationGroupId, partyId);

            return true;
        }

        return false;
    }

    public loadOrganisationGroupData(organisationGroupId: number | null = null, partyId: number | null = null) {
        var isInternalUser = this.authenticationService.isInternalUser;

        this.organisationGroupRepository.getOrganisationGroupsPartyInfo(organisationGroupId).then((data) => {
            var allSites: UsersContract.IOrganisationGroupPartyInfoDtoPartyDto;
            allSites = {
                id: 0,
                mustInclude: true,
                partyNumber: "",
                name: "All",
                isActive: true
            };

            if (data.organisationGroupPartyInfo != null) {
                this.processOrganisationGroupPartnerCapabilities(data.organisationGroupPartnerCapability);
            }

            var oldSelectedSiteId = this._sessionStorageCache.get("oldSelectedSiteId");

            if (partyId != null) {
                this._sessionStorageCache.set('oldSelectedSiteId', partyId);
                oldSelectedSiteId = partyId;
            }

            this.orgGroup = data.organisationGroupPartyInfo;

            // sort and then add 'All'            
            if (this.orgGroup.parties) {
                this.orgGroup.parties.sort(function (a, b) {
                    if (a.isActive === b.isActive) {
                        return b.partyNumber < a.partyNumber ? 1 : -1;
                    }
                    return a.isActive < b.isActive ? 1 : -1;
                });
            }

            this.orgGroup.parties.unshift(allSites);

            this.securityService.init(this.orgGroup.id, data.userAccesses);

            var found = this.orgGroup.parties.find((party) => party.id === oldSelectedSiteId);
            if (found) {
                this._selectedSiteId = oldSelectedSiteId;
            } else {
                this._selectedSiteId = 0;
            }

            // Add partyNumber to dropdown options
            this.orgGroup.parties.forEach(function (party) {
                if (party.partyNumber
                    && (party.partyNumber.charAt(0).toLowerCase() === party.partyNumber.charAt(0).toUpperCase())) {
                    // only add partynumber for members (they start with a numeric partyNumber)
                    party.name = party.name + " - " + party.partyNumber;
                }
            });

            this._isOrganisationGroupError = false;
            this._isOrganisationGroupDataLoaded = true;

            if (isInternalUser && organisationGroupId !== null) {
                var aiProps = {
                    orgGroupId: organisationGroupId
                };
                this.analyticsService.logEvent("orgGroupChanged", aiProps);
            }

            this.emitChangedEvent();

        }).catch((reason) => {
            var r = reason;
            this._isOrganisationGroupError = true;
            this._isOrganisationGroupDataLoaded = false;
            this.orgGroup = null;
            this.selectedSiteId = null;
            // Need to clear session storage too (could be NoAccess for orgGroup because of change in UserAccess)
            this._sessionStorageCache.set("organisationGroupId", null);
            this._sessionStorageCache.set("oldSelectedSiteId", null);

            this.emitChangedEvent();
        });
    }

    private emitChangedEvent() {
        this.logService.info("MainContextChanged " + this.organisationGroupId + ", " + this.selectedSiteId);

        if (this.authenticationService.isInternalUser) {
            this.title = '';
        }

        this.onOrganisationGroupContextChanged.emit(this.organisationGroupData());
    }

    private processOrganisationGroupPartnerCapabilities(ogpCapabilities: AvalContract.IOrganisationGroupPartnerCapabilityDto): void {
        this.ogCaps = <IExtOrganisationGroupPartnerCapabilityDto>ogpCapabilities;

        this.ogCaps.$$generalSettingsLinks = [
            this.ogCaps.showSettingsAlternativeAddress,
            this.ogCaps.showSettingsDeliveryValidation,
            this.ogCaps.showSettingsDeliveryValidationIC,
            this.ogCaps.showSettingsSortCentre,
            this.ogCaps.showSettingsTransporter
        ];

        this.ogCaps.$$tariffsAndPricesLinks = [
            this.ogCaps.showPricesPartner,
            this.ogCaps.showPricesProject
        ];

        this.ogCaps.$$billingLinks = [
            this.ogCaps.showPO,
            this.ogCaps.showSO
        ];

        this.ogCaps.$$stockAndProductionLinks = [
            this.ogCaps.showSortedStock,
            this.ogCaps.showUnsortedStock,
            this.ogCaps.showSortingProduction
        ];

        this.ogCaps.$$planningLinks = [
            this.ogCaps.showAddDelivery,
            this.ogCaps.showAddPickup,
            this.ogCaps.showBoard,
            this.ogCaps.showPlannedPickups,
            this.ogCaps.showPlannedDeliveries
        ];

        this.ogCaps.$$weighingTicketLinks = [
            this.ogCaps.showAddWeighingTicket,
            this.ogCaps.showWeighingTickets,
            this.ogCaps.showWeighingTicketsToSend,
            this.ogCaps.showWeighingTicketCorrectionsToHandle,
            this.ogCaps.showWeighingTicketCorrectionsSent
        ];

        this.ogCaps.$$deliveryLinks = [
            this.ogCaps.showWeighingTicketsToCouple,
            this.ogCaps.showBoard,
            this.ogCaps.showDeliveriesToValidate,
            this.ogCaps.showDeliveryCorrectionsToHandle,
            this.ogCaps.showDeliveryCorrectionsSent,
            this.ogCaps.showDeliveries
        ];

        this.ogCaps.$$deliveryOtherLinks = [
            this.ogCaps.showMaterialFlows
        ];

        this.ogCaps.$$enrichmentLinks = [
            this.ogCaps.showDeliveriesToEnrich,
            this.ogCaps.showGlassBinWeighings
        ];

        this.ogCaps.$$showGeneralSettingsBlock = this.ogCaps.$$generalSettingsLinks.some(x => x);
        this.ogCaps.$$showTariffsAndPricesBlock = this.ogCaps.$$tariffsAndPricesLinks.some(x => x);
        this.ogCaps.$$showBillingBlock = this.ogCaps.$$billingLinks.some(x => x);
        this.ogCaps.$$showStockAndProductionBlock = this.ogCaps.$$stockAndProductionLinks.some(x => x);

        this.ogCaps.$$showPlanningPanel = this.ogCaps.$$planningLinks.some(x => x);
        this.ogCaps.$$showWeighingTicketPanel = this.ogCaps.$$weighingTicketLinks.some(x => x);
        this.ogCaps.$$showDeliveryPanel = this.ogCaps.$$deliveryLinks.some(x => x);
        this.ogCaps.$$showDeliveryOtherPanel = this.ogCaps.$$deliveryOtherLinks.some(x => x);
        this.ogCaps.$$showEnrichmentPanel = this.ogCaps.$$enrichmentLinks.some(x => x);

        this.ogCaps.$$showDeliveryBlock = this.ogCaps.$$showDeliveryPanel
            || this.ogCaps.$$showPlanningPanel
            || this.ogCaps.$$showWeighingTicketPanel
            || this.ogCaps.$$showDeliveryOtherPanel
            || this.ogCaps.$$showEnrichmentPanel;
    }
}