import { Input, Directive, OnInit, TemplateRef, ViewContainerRef, EmbeddedViewRef } from '@angular/core';
import { BlockUiService } from "../../../services/blockUi.service";
import { LogService } from "../../../services/log.service";

@Directive({
    selector: "[fpBlockUi]"
})
export class FpBlockUiDirective implements OnInit {
    constructor(private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private logService: LogService) {
        
    }

    private blockUiService: BlockUiService;

    private isBlocked: boolean = false;

    @Input()
    public set fpBlockUi(isBlocked: boolean) {
        if (isBlocked !== this.isBlocked) {
            this.isBlocked = isBlocked;

            this.updateBlocker();
        }
    }

    private updateBlocker() {
        if (this.blockUiService) {
            if (this.isBlocked) {
                this.blockUiService.startBlocking();
            } else {
                this.blockUiService.stopBlocking();
            }
        }
    }
    
    ngOnInit() {
        // Create the blockUiService internally instead of providing it.
        // this shields the service from the services provided by fpBlockUiContainer directive
        // child components that inject the BlockUiService still get the instance created by the nearest parent fpBlockUiContainer
        this.blockUiService = new BlockUiService(this.logService);

        var viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);

        this.findOrcreateContainer(viewRef);
    }

    private findOrcreateContainer(viewRef: EmbeddedViewRef<any>) {
        
        if (viewRef.rootNodes.length === 1 || viewRef.rootNodes[0] instanceof HTMLElement) {
            var element: HTMLElement = <HTMLElement>viewRef.rootNodes[0];

            this.blockUiService.blockElement = element;

            this.updateBlocker();
        } else {
            var htmlElements = viewRef.rootNodes.filter((node) => {
                return node instanceof HTMLElement;
            });

            if (htmlElements.length === 1) {
                this.blockUiService.blockElement = htmlElements[0];

                this.updateBlocker();
            } else {
                this.logService.warn("Not blocking. None or Multiple HTML root nodes in template.");
                throw new SyntaxError("None or Multiple HTML root nodes in template.");
            }
        }
    }
}