export interface IDataTask<TData> {
    loader(resolve: (data: TData) => void, reject: (reason: any) => void): void;
    processor(data: TData): void;
    reloadOnCultureChange: boolean;
    reload: boolean;
}

export class DataTask<TData> implements IDataTask<TData> {
    constructor(
        public loader: (resolve: (data: TData) => void, reject: (reason: any) => void) => void,
        public processor: (data: TData) => void,
        public dataTaskOptions: DataTaskOptions = new DataTaskOptions()
    ) {
        this.reloadOnCultureChange = dataTaskOptions.reloadOnCultureChange;
        this.reload = dataTaskOptions.reload;
    }

    public reloadOnCultureChange: boolean;
    public reload: boolean;
}

export class DataTaskOptions {
    constructor(public reloadOnCultureChange: boolean = true, public reload: boolean = false) {

    }
}