export class ErrorClass implements Error {
    public name: string;
    constructor(public message: string) {
        this.message = message;
    }
}

export class NotImplementedError extends ErrorClass implements Error {
    public name = 'NotImplementedError';
    constructor(public message: string) {
        super(message);
    }
}