export class ObjectTraverser {
    private static MAX_DEPTH = 100;
    private static getKeys = Object.keys;
    /**
     * Returns "true" if any is object
     * @param {*} any
     * @returns {Boolean}
     */
    private static isObject = (any) => {
        return any instanceof Object;
    };
    /**
     * Walks object recursively
     * @param {Object} object
     * @param {Function} cb
     * @param {*} ctx
     * @param {Boolean} mode
     * @param {Boolean} ignore
     * @param {Number} max
     * @returns {Object}
     */
    private static walk = (object, cb, ctx, mode, ignore, max) => {
        var stack = [[], 0, ObjectTraverser.getKeys(object).sort(), object];
        var cache = [];

        do {
            var node = stack.pop();
            var keys = stack.pop();
            var depth = stack.pop();
            var path = stack.pop();

            cache.push(node);

            while (keys[0]) {
                var key = keys.shift();
                var value = node[key];
                var way = path.concat(key);
                if (key.indexOf('$$') !== 0) {
                    var strategy = cb.call(ctx, node, value, key, way, depth);

                    if (strategy === true) {
                        continue;
                    } else if (strategy === false) {
                        stack.length = 0;
                        break;
                    } else {
                        if (max <= depth || !ObjectTraverser.isObject(value)) {
                            continue;
                        }

                        if (cache.indexOf(value) !== -1) {
                            if (ignore) {
                                continue;
                            }
                            throw new Error('Circular reference');
                        }

                        if (mode) {
                            stack.unshift(way, depth + 1, ObjectTraverser.getKeys(value).sort(), value);
                        } else {
                            stack.push(path, depth, keys, node);
                            stack.push(way, depth + 1, ObjectTraverser.getKeys(value).sort(), value);
                            break;
                        }
                    }
                }
            }
        } while (stack[0]);

        return object;
    };
    /**
     * Walks object recursively
     * @param {Object} object
     * @param {Function} callback
     * @param {*} [context]
     * @param {Number} [mode=0]
     * @param {Boolean} [ignoreCircularReferences=false]
     * @param {Number} [maxDepth=100]
     * @returns {Object}
     */
    public static traverse = (object, callback, context?, mode?, ignoreCircularReferences?, maxDepth?) => {
        var cb = callback;
        var ctx = context;
        var _mode = mode === 1;
        var ignore = true;
        if (ignoreCircularReferences != undefined) {
            ignore = ignoreCircularReferences;
        }
        var max = typeof maxDepth === 'number' && !isNaN(maxDepth) ? maxDepth : ObjectTraverser.MAX_DEPTH;

        return ObjectTraverser.walk(object, cb, ctx, _mode, ignore, max);
    };

}