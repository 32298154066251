import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, Optional, Self } from "@angular/core";
import { ControlValueAccessor, NgModel } from "@angular/forms";
import { LanguageService, ICultureChangedEvent } from '../../../../services/language.service';
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';


@Component({
    selector: "fp-textbox",
    templateUrl: "./fpTextbox.component.html",
    host: { 'class': 'fp-control' }
})

export class FpTextboxComponent implements ControlValueAccessor, OnInit {

    public model: NgModel;

    @Input() name: string;
    // @Input() maxlength: number = 524288;

    private _maxlength: number = 524288;

    @Input() set maxlength(value: number) {
        this._maxlength = +value;
    }

    @Input() minlength: number = 0;

    @Input() disabled: boolean = false;
    @Input() readMode: boolean = false;
    @Input() localId: string;

    @Input() helpTlk: string | null = null;
    public helpId = '';

    @Input()
    set placeholder(key: string) {

        this._placeholderKey = key
    }
    get placeholder() {
        return this._placeholderKey
    }

    private _placeholderKey: string = '';
    public placeholderText: string;

    private onCultureChangeSub: Subscription;


    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

    private innerValue: string = null;
    private controlId: string = "";

    constructor(
        @Optional() @Self() ngModel: NgModel | null,
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private languageService: LanguageService) {
        this.model = ngModel;

        // Set the valueAccessor manually.
        // Before this was done by providing the component as NG_VALUE_ACCESSOR,
        //    but this causes a circular import when injecting NgModel in the constructor.
        if (this.model) {
            this.model.valueAccessor = this;
        }
    }

    onChange(_): void { }

    onTouched(): void { }

    ngOnInit(): void {
        this.controlId = this.name.replace('.', '_');

        if (this.localId != undefined) {
            this.controlId = this.controlId + "|" + this.localId;
        }

        if (this.helpTlk) {
            this.helpId = `help_${this.name.replace('.', '_')}`;
        }

        this.getTranslation();

        this.onCultureChangeSub = this.languageService.onCultureChange.subscribe((event: ICultureChangedEvent) => {
            this.getTranslation();
        });
    }

    getTranslation(): void {
        if (this._placeholderKey) {
            this.translateService.get(this._placeholderKey).subscribe((translation) => {
                this.placeholderText = translation;
            });
        }
    }
    //get accessor
    get value(): string {
        return this.innerValue;

        //if (this.innerValue && this.innerValue.length === 0) {
        //    return null;
        //}

        //return this.innerValue;
    };

    //set accessor including call the onchange callback
    set value(v: string) {
        if (v !== this.innerValue) {
            this.innerValue = v;

            if (this.onChange) {
                this.onChange(v);
            }

            if (this.valueChange) {
                this.valueChange.emit(v);
            }
        }
    }

    writeValue(value: string): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
            this.changeDetectorRef.detectChanges();
        }
    }

    onBlur() {
        this.onTouched();
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}