export enum MenuAudience {
    All = 0,
    OnlyForInternalUsers = 1
}

export abstract class MenuItem {
    constructor(public name: string, public translationKey: string,
        public isAdminMenuItem: boolean = false,
        public audience: MenuAudience = MenuAudience.All,
        isOrgGroupRelated: boolean = false) {
        this.isActive = false;
        this.hide = false;
        this.isOrgGroupRelated = isOrgGroupRelated;

        this.menuItems = [];
    }

    public isActive: boolean;
    public hide: boolean;
    public parent: MenuItem;
    private menuItems: Array<MenuItem>;
    // When an UrlMenuItem is cultureAware, the menuService will replace the
    // ':language' part of the baseUrl with the currently selected language
    public isCultureAware = false;

    /**
     * When a MenuItem is isOrgGroupRelated, the menuService will show or hide the menu item
     * depending on there is an organization group selected or not.
     */
    public isOrgGroupRelated = false;

    public addSubMenuItem(item: MenuItem): void {
        item.parent = this;
        this.menuItems.push(item);
    }

    public getSubMenuItems(): Array<MenuItem> {
        return this.menuItems;
    }

    public hideMenu(hide: boolean) {
        this.hide = hide;
    }

    public mustShow(isForInternalUser: boolean): boolean {
        return !this.hide
            && (
                this.audience == MenuAudience.All
                || (this.audience == MenuAudience.OnlyForInternalUsers && isForInternalUser)
            );
    }
}

export class UrlMenuItem extends MenuItem {
    public baseUrl = '';

    constructor(name: string, translationKey: string,
        public url: string,
        isAdminMenuItem: boolean = false,
        audience: MenuAudience = MenuAudience.All,
        public openNewTab: boolean = false,
        isOrgGroupRelated: boolean = false,
        isOrgGroupContextAware: boolean = false) {
        super(name, translationKey, isAdminMenuItem, audience, isOrgGroupRelated);
        this.baseUrl = url;
        this.isOrgGroupContextAware = isOrgGroupContextAware;

        // parameters will be resolved by the MenuService based on the mainContext
        //this.urlWithParameters = this.url;
    }

    /**
    * When an UrlMenuItem is orgGroupContextAware, the menuService will update the
    * queryParameters for organisationGroup and member
    */
    public isOrgGroupContextAware = false;

    //public urlWithParameters: string;
}

export class RouteMenuItem extends MenuItem {
    constructor(name: string, translationKey: string,
        public route: string,
        public params: Array<string> = [],
        isAdminMenuItem: boolean = false,
        audience: MenuAudience = MenuAudience.All,
        isOrgGroupRelated: boolean = false) {
        super(name, translationKey, isAdminMenuItem, audience, isOrgGroupRelated);
    }
}

export class ActivateMenuOptions {
    constructor(public name: string, showSubMenu?: boolean) {
        if (showSubMenu == null) {
            showSubMenu = false;
        }

        this.showSubMenu = showSubMenu;
    }

    public showSubMenu: boolean;
}