<kendo-dialog *ngIf="isVisible"
              (close)="closeDialog()"
              [width]="width">
    <kendo-dialog-titlebar>
        <b fp-translate>{{ header }}</b>
    </kendo-dialog-titlebar>

    <div class="row" *fpBlockUi="!isVisible">
        <div class="col-md-12" fp-translate>{{content}}</div>
    </div>

    <kendo-dialog-actions>
        <button kendoButton primary="true" type="button" (click)="confirm()">
            <span fp-translate>MyApp.Yes</span>
        </button>
        <button kendoButton (click)="closeDialog()" type="button" fp-translate>
            <span fp-translate>MyApp.No</span>
        </button>
    </kendo-dialog-actions>
</kendo-dialog>