import { Injectable, Inject } from '@angular/core';
import { HttpProvider } from "../services/http.provider";

import Users = FostPlus.Olympus.UsersDomain.Clients.Api;
import MyFost = FostPlus.MyFost.Web.Gui.Models;

export interface IUserRepository {
    getCurrentUser(): Promise<MyFost.IUserSecurityInfo>;
    search(request: Users.IUserSearchRequest): Promise<Users.IUserSearchResponse>;
    get(id: number, organisationGroupId: number): Promise<Users.IUserSelfServiceGetResponse>;
    create(request: Users.IUserSelfServiceCreateRequest): Promise<Users.IUserSelfServiceCreateResponse>;
    update(request: Users.IUserSelfServiceUpdateRequest): Promise<Users.IUserSelfServiceUpdateResponse>;

    deleteUserAccessSelfService(request: Users.IUserAccessSelfServiceDeleteRequest): Promise<Users.IUserAccessSelfServiceDeleteResponse>;
    userAccessGet(organisationGroupId: number): Promise<Users.IUserAccessDataGetResponse>;
    UpdateUserAccessSelfService(request: Users.IUserAccessSelfServiceUpdateRequest): Promise<Users.IUserAccessSelfServiceUpdateResponse>;

    deleteUserApplicationRoleSelfService(request: Users.IUserApplicationRoleSelfServiceDeleteRequest): Promise<Users.IUserApplicationRoleSelfServiceDeleteResponse>;
    createUserApplicationRoleSelfService(request: Users.IUserApplicationRoleSelfServiceCreateRequest): Promise<Users.IUserApplicationRoleSelfServiceCreateResponse>;
    bulkCreateUserApplicationRoles(request: Users.IUserApplicationRoleSelfServiceBulkCreateRequest): Promise<Users.IUserApplicationRoleSelfServiceBulkCreateResponse>;

    checkUsernameAvailability(windowsLogon: string, organisationGroupId: number): Promise<Users.IUserCheckUsernameAvailabilityResponse>;
    createUserActivationEmail(userId: number, organisationGroupId: number): Promise<Users.IUserActivationEmailCreateResponse>;

    getUserAccessCreateData(organisationGroupId: number): Promise<Users.IUserAccessCreateDataGetResponse>;
    createUserAccess(request: Users.IUserAccessSelfServiceCreateRequest): Promise<Users.IUserAccessSelfServiceCreateResponse>;

    createClientApi(request: Users.IUserSelfServiceCreateClientApiRequest): Promise<Users.IUserSelfServiceCreateClientApiResponse>;
    updateClientSecret(request: Users.IUserSelfServiceUpdateClientSecretRequest): Promise<Users.IUserSelfServiceUpdateClientSecretResponse>;

    userPartyRegistrationForFlow(userPartyRegistrationId: string | null): Promise<Users.IUserPartyRegistrationForFlowResponse>;

    userItsmeUserSelfServiceSearch(request: Users.IUserItsmeUsersSelfServiceSearchRequest): Promise<Users.IUserItsmeUsersSelfServiceSearchResponse>;
    userItsmeUserSelfServiceDelete(request: Users.IUserItsmeUserSelfServiceDeleteRequest): Promise<Users.IUserItsmeUserSelfServiceDeleteResponse>;
}

@Injectable()
export class UserRepository implements IUserRepository {
    
    static $inject = [
        '$resource',
        'rootUrl',
        '$window',
        '$q'
    ];

    constructor(private http: HttpProvider,
        @Inject('rootUrl') private rootUrl: string) {
    }

    getCurrentUser(): Promise<MyFost.IUserSecurityInfo> {
        var url = "api/users/currentUser";
        var a = this.http.get<MyFost.IUserSecurityInfo>(this.rootUrl + url);
        return a;
    }

    search = (request: Users.IUserSearchRequest): Promise<Users.IUserSearchResponse> => {
        var a = this.http.post<Users.IUserSearchResponse>(this.rootUrl + "api/users/search", request);
        return a;
    }

    get = (id: number, organisationGroupId: number): Promise<Users.IUserSelfServiceGetResponse> => {
        var url = "api/users/" + id + '?organisationGroupId=' + organisationGroupId;
        var a = this.http.get<Users.IUserSelfServiceGetResponse>(this.rootUrl + url);
        return a;
    }

    userprofileGet = (id: number, organisationGroupId: number): Promise<Users.IUserprofileSelfServiceGetResponse> => {
        var url = "api/users/userprofile/" + id + '?organisationGroupId=' + organisationGroupId;
        var a = this.http.get<Users.IUserprofileSelfServiceGetResponse>(this.rootUrl + url);
        return a;
    }

    create = (request: Users.IUserSelfServiceCreateRequest): Promise<Users.IUserSelfServiceCreateResponse> => {
        var a = this.http.post<Users.IUserSelfServiceCreateResponse>(this.rootUrl + "api/users/create", request);
        return a;
    }

    bulkCreate = (request: Users.IUserAccessSelfServiceBulkCreateRequest): Promise<Users.IUserAccessSelfServiceBulkCreateResponse> => {
        var a = this.http.post<Users.IUserAccessSelfServiceBulkCreateResponse>(this.rootUrl + "api/users/bulkCreate", request);
        return a;
    }

    bulkCreateUserApplicationRoles = (request: Users.IUserApplicationRoleSelfServiceBulkCreateRequest): Promise<Users.IUserApplicationRoleSelfServiceBulkCreateResponse> => {
        var a = this.http.post<Users.IUserApplicationRoleSelfServiceBulkCreateResponse>(this.rootUrl + "api/users/applicationRole/bulkCreate", request);
        return a;
    }

    update = (request: Users.IUserSelfServiceUpdateRequest): Promise<Users.IUserSelfServiceUpdateResponse> => {
        var a = this.http.post<Users.IUserSelfServiceUpdateResponse>(this.rootUrl + "api/users/update", request);
        return a;
    }

    userprofileUpdate = (request: Users.IUserprofileSelfServiceUpdateRequest): Promise<Users.IUserprofileSelfServiceUpdateResponse> => {
        var a = this.http.post<Users.IUserprofileSelfServiceUpdateResponse>(this.rootUrl + "api/users/userprofile/update", request);
        return a;
    }

    userAccessSelfServiceGet(userAccessId: string, organisationGroupId: number): Promise<Users.IUserAccessSelfServiceGetResponse> {
        var url = "api/users/access/" + userAccessId + '?organisationGroupId=' + organisationGroupId;
        return this.http.get<Users.IUserAccessSelfServiceGetResponse>(this.rootUrl + url);
    }

    deleteUserAccessSelfService(request: Users.IUserAccessSelfServiceDeleteRequest): Promise<Users.IUserAccessSelfServiceDeleteResponse> {
        var url = "api/users/access/delete/" + request.userAccess.id + '?organisationGroupId=' + request.organisationGroupId + '&rowVersion=' + request.userAccess.rowVersion;
        var a = this.http.delete<Users.IUserAccessSelfServiceDeleteResponse>(this.rootUrl + url);
        return a;
    }

    UpdateUserAccessSelfService(request: Users.IUserAccessSelfServiceUpdateRequest): Promise<Users.IUserAccessSelfServiceUpdateResponse> {
        var a = this.http.post<Users.IUserAccessSelfServiceUpdateResponse>(this.rootUrl + "api/users/access/update", request);
        return a;
    }

    userAccessGet(organisationGroupId: number): Promise<Users.IUserAccessDataGetResponse> {
        var url = "api/users/data/access/" + organisationGroupId;

        var a = this.http.get<Users.IUserAccessDataGetResponse>(this.rootUrl + url);
        return a;
    }

    deleteUserApplicationRoleSelfService(request: Users.IUserApplicationRoleSelfServiceDeleteRequest): Promise<Users.IUserApplicationRoleSelfServiceDeleteResponse> {
        var url = "api/users/applicationRole/delete/" + request.userApplicationRole.id + '?organisationGroupId=' + request.organisationGroupId + '&rowVersion=' + request.userApplicationRole.rowVersion;
        var a = this.http.delete<Users.IUserApplicationRoleSelfServiceDeleteResponse>(this.rootUrl + url);
        return a;
    }

    createUserApplicationRoleSelfService(request: Users.IUserApplicationRoleSelfServiceCreateRequest): Promise<Users.IUserApplicationRoleSelfServiceCreateResponse> {
        var a = this.http.post<Users.IUserApplicationRoleSelfServiceCreateResponse>(this.rootUrl + "api/users/applicationRole/create", request);
        return a;
    }

    checkUsernameAvailability(windowsLogon: string, organisationGroupId: number): Promise<Users.IUserCheckUsernameAvailabilityResponse> {
        var request: Users.IUserCheckUsernameAvailabilityRequest = {
            windowsLogon: windowsLogon,
            organisationGroupId: organisationGroupId
        };
        var url = this.rootUrl + 'api/users/checkUsernameAvailability';
        var a = this.http.post<Users.IUserCheckUsernameAvailabilityResponse>(url, request);
        return a;
    };

    createUserActivationEmail = (userId: number, organisationGroupId: number): Promise<Users.IUserActivationEmailCreateResponse> => {
        var request: Users.IUserActivationEmailCreateRequest = {
            userId: userId,
            organisationGroupId: organisationGroupId
        };
        var url = this.rootUrl + 'api/users/createActivationEmail';
        var a = this.http.post<Users.IUserActivationEmailCreateResponse>(url, request);
        return a;
    };

    getUserAccessCreateData = (organisationGroupId: number): Promise<Users.IUserAccessCreateDataGetResponse> => {
        var url = "api/userAccessCreateData/search?organisationGroupId=" + organisationGroupId;
        var a = this.http.get<Users.IUserAccessCreateDataGetResponse>(this.rootUrl + url);
        return a;
    }

    getUserCreateWizardData = (organisationGroupId: number): Promise<Users.IUserCreateWizardDataGetResponse> => {
        var url = "api/userCreateWizardData/search?organisationGroupId=" + organisationGroupId;
        var a = this.http.get<Users.IUserCreateWizardDataGetResponse>(this.rootUrl + url);
        return a;
    }

    disableUser = (request: Users.IUserDisableRequest): Promise<Users.IUserDisableResponse> => {
        var a = this.http.post<Users.IUserDisableResponse>(this.rootUrl + "api/users/disableUser", request);
        return a;
    }

    archiveUser = (request: Users.IUserArchiveRequest): Promise<Users.IUserArchiveResponse> => {
        var a = this.http.post<Users.IUserArchiveResponse>(this.rootUrl + "api/users/archiveUser", request);
        return a;
    }
    createUserAccess = (request: Users.IUserAccessSelfServiceCreateRequest): Promise<Users.IUserAccessSelfServiceCreateResponse> => {
        var a = this.http.post<Users.IUserAccessSelfServiceCreateResponse>(this.rootUrl + "api/users/access/create", request);
        return a;
    }

    createClientApi = (request: Users.IUserSelfServiceCreateClientApiRequest): Promise<Users.IUserSelfServiceCreateClientApiResponse> => {
        var a = this.http.post<Users.IUserSelfServiceCreateClientApiResponse>(this.rootUrl + "api/users/createClientApi", request);
        return a;
    }

    updateClientSecret = (request: Users.IUserSelfServiceUpdateClientSecretRequest): Promise<Users.IUserSelfServiceUpdateClientSecretResponse> => {
        var a = this.http.post<Users.IUserSelfServiceCreateClientApiResponse>(this.rootUrl + "api/users/updateClientSecret", request);
        return a;
    }

    userPartyRegistrationForFlow(userPartyRegistrationId: string | null): Promise<Users.IUserPartyRegistrationForFlowResponse> {
        const request: Users.IUserPartyRegistrationForFlowRequest = {
            userPartyRegistrationId: userPartyRegistrationId
        };
        var a = this.http.post<Users.IUserPartyRegistrationForFlowResponse>(this.rootUrl + "api/users/userPartyRegistrationForFlow", request);
        return a;
    }

    userItsmeUserSelfServiceSearch(request: Users.IUserItsmeUsersSelfServiceSearchRequest): Promise<Users.IUserItsmeUsersSelfServiceSearchResponse> {
        var url = "api/users/itsmeUsers/search/" + request.userId + '?organisationGroupId=' + request.organisationGroupId;
        return this.http.get<Users.IUserItsmeUsersSelfServiceSearchResponse>(this.rootUrl + url);
    }

    userItsmeUserSelfServiceDelete(request: Users.IUserItsmeUserSelfServiceDeleteRequest): Promise<Users.IUserItsmeUserSelfServiceDeleteResponse> {
        var url = "api/users/itsmeUsers/delete/" + request.userId + '?organisationGroupId=' + request.organisationGroupId + '&itsmeUserId=' + request.itsmeUserId;
        return this.http.delete<Users.IUserItsmeUserSelfServiceDeleteResponse>(this.rootUrl + url);
    }

    usersForOrganisationGroupGet = (organisationGroupId: number): Promise<Users.IUsersForOrganisationGroupGetResponse> => {
        var request: Users.IUsersForOrganisationGroupGetRequest = {
            includeIAM: false,
            organisationGroupId: organisationGroupId
        };
        var a = this.http.post<Users.IUsersForOrganisationGroupGetResponse>(this.rootUrl + "api/users/usersForOrganisationGroupGet", request);
        return a;
    }

    userIAMRegistrationCreateOrUpdate = (request: Users.IUserIAMRegistrationCreateOrUpdateRequest): Promise<Users.IUserIAMRegistrationCreateOrUpdateResponse> => {
        var a = this.http.post<Users.IUserIAMRegistrationCreateOrUpdateResponse>(this.rootUrl + "api/userIAMRegistrations/createOrUpdate", request);
        return a;
    }

    userIAMRegistrationGet = (request: Users.IUserIAMRegistrationGetToTreatRequest): Promise<Users.IUserIAMRegistrationGetToTreatResponse> => {
        var a = this.http.get<Users.IUserIAMRegistrationGetToTreatResponse>(this.rootUrl + "api/userIAMRegistrations/get?organisationGroupId=" + request.organisationGroupId);
        return a;
    }
}

