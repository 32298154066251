<form #myForm="ngForm" novalidate>
    <!--error-->
    <div class="row" *ngIf="isInError" style="margin-top: 18px;">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    An error has occured
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
    <!--loading external user data-->
    <div class="row" *ngIf="!isInError && !isInternalOrHasMultipleOrganisationGroups">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    We are getting things ready ...
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
    <!--contract selector-->
    <div *ngIf="!isInError && isInternalOrHasMultipleOrganisationGroups">
        <div class="row">
            <div class="col-md-12">
                <h4 class="panel-title" fp-translate>
                    Users.User_Search
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <fp-label label="Users.OrganisationGroup_Name"></fp-label>
                <fp-textbox name="Users.OrganisationGroup_Name" [(ngModel)]="data.organisationGroupName"></fp-textbox>
            </div>
            <div class="col-md-2">
                <fp-label label="Party.Organisation_Label_Name"></fp-label>
                <fp-textbox name="Party.Organisation_Label_Name" [(ngModel)]="data.organisationName"></fp-textbox>
            </div>
            <div class="col-md-2">
                <fp-label label="Party.Organisation_VatNumber"></fp-label>
                <fp-textbox name="Party.Organisation_VatNumber" [(ngModel)]="data.vatNumber"></fp-textbox>
            </div>
            <div class="col-md-2">
                <fp-label label="Party.Party_PartyNumber"></fp-label>
                <fp-textbox name="Party.Party_PartyNumber" [(ngModel)]="data.partyNumber"></fp-textbox>
            </div>
            <div class="col-md-2">
                <fp-label label="Users.User_FirstName"></fp-label>
                <fp-textbox name="Users.User_FirstName" [(ngModel)]="data.userFirstName"></fp-textbox>
            </div>
            <div class="col-md-2">
                <fp-label label="Users.User_LastName"></fp-label>
                <fp-textbox name="Users.User_LastName" [(ngModel)]="data.userLastName"></fp-textbox>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="btn-toolbar">
                    <button class="btn btn-primary" (click)="search()" fp-translate>
                        <fa-icon icon="search"></fa-icon>
                        MyApp.Search
                    </button>
                    <button class="btn btn-outline" type="button" (click)="reset()" fp-translate>
                        <fa-icon icon="arrow-rotate-right"></fa-icon>
                        MyApp.Reset
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <kendo-grid fpGridBinding
                            #gridBinding="fpGridBinding"
                            uniqueId="49F6C398-2DCA-4944-984E-C6362E7B0C36"
                            [rebinder]="loadGridData"
                            [autoBindOnInit]="false"
                            [pageable]="{
                                pageSizes: [10, 20, 50, 100]
                            }"
                            scrollable="none"
                            [selectable]="true"
                            (selectionChange)="gridSelectionChange($event)">
                    <kendo-grid-column field="name">
                        <ng-template kendoGridHeaderTemplate>
                            <span fp-translate>Users.OrganisationGroup_Name</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.name }}
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>

</form>
