export class NumberUtils {

    public static parseIntWithFallBackToNull(input: string) {
        var result = parseInt(input, 10);
        if (isNaN(result)) {
            result = null;
        }
        return result;
    }

}