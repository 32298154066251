import { Injectable } from '@angular/core';
import { FeedbackMessage, WrappedResult, FeedbackMessageType } from "../classes/errorHandling";
import { ObjectTraverser } from "../utilities/objectTraverser";
import { LogService } from "../services/log.service";
import { TranslateService } from "@ngx-translate/core";
import { Utils } from "../utilities/utils";
import { AnalyticsService } from './analytics.service';

/**
 * ErrorHandling provides some generic functionality to gracefyully handle http-request errors.
 */
@Injectable()
export class ErrorHandlingService {
    constructor(private logService: LogService,
        public analyticsService: AnalyticsService,
        private translateService: TranslateService) {
    }

    private createErrorFeedbackMessages = (error: any, request?: any): Array<FeedbackMessage> => {
        var messages: Array<FeedbackMessage> = [];

        if (error && error.error && !error.data) {
            try {
                error.data = JSON.parse(error.error);
            } catch (exception) {
                // something unexpected went wrong ... 
                let aiProps = {
                    body: error.eror
                };
                var fbm = this.createErrorMessageWhenException(exception, aiProps);
                messages.push(fbm);
            }
        }

        try {
            if (messages.length == 0) {
                if ((error.status === 400 || error.status === 422)
                    && error.data
                    && (error.data.validationErrors || error.data.errors || Array.isArray(error.data))) {
                    messages = this.getValidationFeedback(error.data, request);
                } else {
                    var feedbackMessage = new FeedbackMessage('', FeedbackMessageType.Error);
                    if (error) {
                        if (error.status === 400 && error.data.messageDetail) {
                            feedbackMessage.feedbackMessageType = FeedbackMessageType.Error;
                            feedbackMessage.message = error.data.messageDetail;

                        } else if (error.status === 404) {
                            // notFound
                            feedbackMessage.feedbackMessageType = FeedbackMessageType.Warning;
                            feedbackMessage.message = error.statusText;
                            this.translateService.get('MyApp.HttpNotFound').subscribe((translation) => {
                                feedbackMessage.message = translation;
                            });
                        } else if (error.status === 409) {
                            // conflict
                            feedbackMessage.feedbackMessageType = FeedbackMessageType.Error;
                            feedbackMessage.message = error.statusText;
                            this.translateService.get('MyApp.HttpConflict').subscribe((translation) => {
                                feedbackMessage.message = translation;
                            });
                        } else if (error.status === 500 && error.data && error.data.errorId) {
                            // internal Server Error
                            feedbackMessage.feedbackMessageType = FeedbackMessageType.Error;
                            this.translateService.get('MyApp.UnexpectedError', { errorId: error.data.errorId }).subscribe((translation) => {
                                feedbackMessage.message = translation;
                            });
                        } else if (error.status === 403) {
                            // Access Denied
                            feedbackMessage.feedbackMessageType = FeedbackMessageType.Error;
                            this.translateService.get('MyApp.HttpAccessDenied').subscribe((translation) => {
                                feedbackMessage.message = translation;
                            });
                        } else if (error.data && error.data.exceptionMessage) {
                            this.logService.error(error.data.exceptionMessage);
                            this.logService.error(error.data.stackTrace);
                            feedbackMessage.feedbackMessageType = FeedbackMessageType.Error;
                            feedbackMessage.message = error.data.exceptionMessage;
                        } else if (error.data && error.data.message) {
                            this.logService.error(error.data.message);
                            this.logService.error(error.data.stackTrace);
                            feedbackMessage.feedbackMessageType = FeedbackMessageType.Error;
                            feedbackMessage.message = error.data.message;
                        } else if (error.statusText) {
                            feedbackMessage.message = error.statusText;
                        } else {
                            // something unexpected ... 
                            let aiProps = {
                                error: error
                            };
                            this.analyticsService.logEvent('errorHandlerUnknownCase', aiProps);

                            this.translateService.get('MyApp.UnhandledError').subscribe((translation) => {
                                feedbackMessage.message = translation;
                            });
                        }
                    }
                    messages.push(feedbackMessage);
                }
            }
        } catch (exception) {
            // something unexpected went wrong ... 
            let aiProps = {
                body: error.eror
            };

            var fbm = this.createErrorMessageWhenException(exception, aiProps);
            messages.push(feedbackMessage);
        }

        return messages;
    };

    private createErrorMessageWhenException(exception: any, aiProps: any): FeedbackMessage {
        this.analyticsService.trackException(exception, aiProps);

        var feedbackMessage = new FeedbackMessage('', FeedbackMessageType.Error);
        this.translateService.get('MyApp.UnhandledError').subscribe((translation) => {
            feedbackMessage.message = translation;
        });

        return feedbackMessage;
    }

    /**
     * Created a FeedbackMessage for an http error response.
     */
    public getErrorFeedbackMessages = (error: any, request?: any): Array<FeedbackMessage> => {
        return this.createErrorFeedbackMessages(error, request);
    };

    public getValidationFeedback = (errorData: any, request?: any): any => {
        var messages = [];
        var validationErrors: Array<any> = errorData.validationErrors;
        var isArray = Array.isArray(errorData);
        if (isArray) {
            validationErrors = errorData;
        }

        if (validationErrors == undefined
            && errorData.errors) {

            var errors = errorData.errors;
            validationErrors = [];

            for (let key in errors) {
                var list: Array<any> = errors[key];
                list.forEach((item) => {
                    validationErrors.push({
                        errorMessage: item,
                        propertyName: key
                    });
                })
            }
        }

        if (request) {
            ObjectTraverser.traverse(request, (node, value, key, path, depth) => {
                if (node.$$validationErrors) {
                    node.$$validationErrors = [];
                }
            });
        }

        if (validationErrors && validationErrors.length > 0) {
            validationErrors.forEach((ve) => {
                this.mapValidationErrorToObject(ve, request);

                messages.push(new FeedbackMessage(ve.errorMessage || ve.message, FeedbackMessageType.Error));
            });
        }

        return messages;
    };

    /**
     * Wraps the result of a succeeded http-request.
     */
    public createOkWrappedResult = (okResult) => {
        return new WrappedResult(okResult, null, null);
    };

    /**
     * Wraps the result of a failed http-request.
     */
    public createFailureWrappedResult = (failureResult) => {
        return new WrappedResult(null, failureResult, this.getErrorFeedbackMessages(failureResult)[0]);
    };

    private mapValidationErrorToObject = (validationError, request) => {
        // pathParts will be something like
        //  - master
        //  - master.propertyName
        //  - master.childpropertyName
        //  - master.childpropertyName.propertyName
        //  - master.collection[x]
        //  - master.collection[x].propertyName
        var pathParts: Array<string> = validationError.propertyName.split('.');
        var isMapped: boolean = false;
        var currentProperty = request;

        if (request) {
            // walk the tree
            pathParts.forEach((pathPart) => {
                if (currentProperty && Utils.isObject(currentProperty)) {
                    var nextProperty;
                    var indexerParts = pathPart.split('[');
                    var propertyName = indexerParts[0];
                    var index = -1;

                    if (!currentProperty["$$validationErrors"]) {
                        currentProperty["$$validationErrors"] = [];
                    }
                    if (indexerParts.length > 1) {
                        indexerParts[1] = indexerParts[1].replace(']', '');
                        index = parseInt(indexerParts[1], 10);
                    }
                    nextProperty = currentProperty[propertyName];
                    if (nextProperty === undefined) {
                        // try again with lowercase 
                        var p = propertyName.charAt(0).toLocaleLowerCase() + propertyName.slice(1);
                        nextProperty = currentProperty[p];
                    }

                    // in case of array ... 
                    if (index !== -1 && Utils.isArray(nextProperty) && nextProperty.length > 0) {
                        nextProperty = nextProperty[index];
                    }

                    if (false === Utils.isObject(nextProperty)) {
                        var dtoValidationError = {
                            propertyName: propertyName,
                            errorMessage: validationError.errorMessage || validationError.message
                        };
                        //var dtoValidationError: FostPlus.Olympus.PartyDomain.Clients.Api.IValidationError = {
                        //    propertyName: propertyName,
                        //    errorMessage: validationError.errorMessage || validationError.message
                        //};

                        currentProperty["$$validationErrors"].push(dtoValidationError);
                        isMapped = true;
                    }

                    currentProperty = nextProperty;
                }
            });

            if (false === isMapped) {
                if (!currentProperty.$$validationErrors) {
                    currentProperty.$$validationErrors = [];
                }
                currentProperty.$$validationErrors.push(validationError);
            }
        }
    };
}