import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

@Component({
    selector: "fpDebugDisplay",
    templateUrl: "./debugInfo.component.html",
})

export class DebugDisplayComponent {
    @Input() isDebug: boolean;

    public showDebug: boolean = false;

    public toggleDebugView() {
        this.showDebug = !this.showDebug;
    }
}